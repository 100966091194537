<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-tools"></i> Tech details</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-3">
            <table class="table table-sm">
                <tr>
                    <td>Name</td>
                    <td>{{ tech.tech_name }}</td>
                </tr>
                <tr>
                    <td>Orders</td>
                    <td>{{ getObjectLength(tech._orders) }}</td>
                </tr>
                <tr>
                    <td>Points</td>
                    <td>{{ tech._points | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <td>Distance</td>
                    <td>{{ distance }} km</td>
                </tr>
                <tr>
                    <td>Duration</td>
                    <td>{{ duration }}h (only driving)</td>
                </tr>
            </table>
        </div>
        <div class="col-md-3">
            <div class="alert alert-danger" *ngIf="!tech._points">
                There are no orders assigned to this tech yet.
            </div>
            <ngb-accordion
                #acc="ngbAccordion"
                activeIds="ngb-panel-0"
                *ngIf="tech._points"
            >
                <ngb-panel *ngFor="let order of tech._orders; let i = index">
                    <ng-template ngbPanelHeader>
                        <div
                            class="
                                d-flex
                                align-items-center
                                justify-content-between
                            "
                        >
                            Order {{ i + 1 }}
                            <div>
                                <button
                                    ngbPanelToggle
                                    class="btn btn-sm btn-outline-primary ml-2"
                                >
                                    Details
                                </button>
                                <button
                                    class="btn btn-sm btn-outline-primary ml-2"
                                    (click)="removeOrder(order, i)"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <td>Area</td>
                                    <td>{{ order.area }}</td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{{ order.type }}</td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{{ order.order.ticket_name }}</td>
                                </tr>
                                <tr>
                                    <td>Timeslot</td>
                                    <td>{{ order.order.jfs_task_timeslot }}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {{ order.order.street }}
                                        {{ order.order.housenumber }} -
                                        {{ order.order.zip }}
                                        {{ order.order.city }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>

        <div class="col-md-6" *ngIf="tech._points">
            <app-dispatch-route></app-dispatch-route>
        </div>
    </div>

    <br />
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')"
        >
            Close
        </button>
    </div>
</div>
