<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-user-plus"></i> Add new user</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table table-bordered table-sm">
        <tbody>
            <tr>
                <td>Username</td>
                <td>
                    <input
                        type="text"
                        placeholder="Username"
                        class="form-control"
                        [(ngModel)]="username" />
                </td>
            </tr>
            <tr>
                <td>Main Branch</td>
                <td>
                    <ng-select
                        [(ngModel)]="branch"
                        [multiple]="false"
                        [items]="allBranches"
                        [closeOnSelect]="true"
                        [hideSelected]="true">
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td>Function</td>
                <td>
                    <input
                        type="text"
                        placeholder="Function"
                        class="form-control"
                        [(ngModel)]="function" />
                </td>
            </tr>
            <tr>
                <td>ERP ID</td>
                <td>
                    <input
                        type="number"
                        placeholder="Erp_ID"
                        class="form-control"
                        [(ngModel)]="erp_id" />
                </td>
            </tr>
            <tr>
                <td>Area's</td>
                <td>
                    <ng-select
                        [(ngModel)]="areas"
                        [multiple]="true"
                        [items]="allAreas"
                        [closeOnSelect]="false"
                        [hideSelected]="true">
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td>Rights</td>
                <td>
                    <ng-select
                        [(ngModel)]="rights"
                        [multiple]="true"
                        [items]="allRights"
                        [closeOnSelect]="false"
                        [searchable]="true"
                        [hideSelected]="true">
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="modal-footer">
        <button
            class="btn btn-success"
            (click)="saveUserF()"
            [disabled]="
                !username ||
                !erp_id ||
                !branch ||
                !areas ||
                !rights ||
                !function
            ">
            <i class="fas fa-save"></i>
        </button>
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')">
            Close
        </button>
    </div>
</div>
