import { FileService } from './../../_services/file.service';
import { compilePipeFromMetadata } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-automail',
    templateUrl: './automail.component.html',
    styleUrls: ['./automail.component.css'],
})
export class AutomailComponent implements OnInit {
    user: any;
    constructor(
        private authenticationService: AuthenticationService,
        private fileService: FileService
    ) {
        this.authenticationService.userDetails.subscribe((data) => {
            this.user = data;
        });
    }

    file: any = false;
    automail: any = false;
    loading: boolean = false;
    loadingHeaders: boolean = false;
    loadingMailCreate: boolean = false;
    loadingMailShow: boolean = false;

    errorHeaders: any = false;
    errorMailCreate: any = false;

    previewMails: any = false;

    ngOnInit(): void {}

    fileChangedHandler(event: any) {
        this.file = event;
    }
    automailChangedHandler(event: any) {
        this.automail = event;
    }

    restartHeaders() {
        this.file = false;
        this.automail = false;
        this.loading = false;
        this.loadingHeaders = false;
        this.errorHeaders = false;
        this.loadingMailCreate = false;
        this.loadingMailShow = false;
        this.errorMailCreate = false;
        this.previewMails = false;
    }

    submit() {
        this.loading = true;
        const formData = new FormData();

        formData.append('file', this.file);
        formData.append('automail', this.automail);

        this.fileService.upload(formData).subscribe((data) => {
            // Return is a number, lets continue with the header check
            this.loadingHeaders = true;

            this.fileService.automailHeaderCheck(data).subscribe((data1) => {
                if (data1.error) {
                    this.errorHeaders = data1.message;
                } else {
                    this.loadingMailCreate = true;
                    // Mail generation
                    this.fileService
                        .automailMailCreate(data)
                        .subscribe((data2) => {
                            if (data2.error) {
                                this.errorMailCreate = data2.message;
                            } else {
                                this.loadingMailShow = true;
                                this.previewMails = data2;
                            }
                        });
                }
            });
        });
    }
}
