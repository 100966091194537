<div class="row">
    <div class="col-md-5">
        <h2>Input</h2>
        <div class="scrolldiv">
            <app-kpiverslag-input
                (modelChangeEvent)="modelDateRecieve($event)"
            ></app-kpiverslag-input>
        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-6">
        <h2>Output</h2>
        <div class="maildiv">
            <app-kpiverslag-output [model]="model"></app-kpiverslag-output>
        </div>
    </div>
</div>
