import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { DispatchModalTechdetailsComponent } from './../../modal/dispatch-modal-techdetails/dispatch-modal-techdetails.component';
import { ActivatedRoute } from '@angular/router';
import {
    Component,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dispatch-sidebar-techs',
    templateUrl: './dispatch-sidebar-techs.component.html',
    styleUrls: ['./dispatch-sidebar-techs.component.css'],
})
export class DispatchSidebarTechsComponent implements OnInit {
    @Input() allTechs: any = {};
    @Input() activeTech: any = {};

    @Output() showTechMapEvent = new EventEmitter();
    @Output() makeOrderVisibleEvent = new EventEmitter();
    @Output() changeActiveTechEvent = new EventEmitter();

    techCounter: number = 0;

    constructor(
        private modalService: NgbModal,
        private storage: DispatchStorageService
    ) {}

    ngOnInit(): void {
        // Get the activeTech from localstorage
        this.activeTech = JSON.parse(
            localStorage.getItem('dispatch_activeTech') || '{}'
        );

        // Get the technicians from locoalstorage
        this.allTechs = JSON.parse(
            localStorage.getItem('dispatch_allTechs') || '[]'
        );

        this.countAvailableTechs();
    }

    countAvailableTechs() {
        for (var i = 0; i < this.allTechs; i++) {
            if (this.allTechs[i]._isAvailable) {
                this.increaseTechCounter();
            }
        }
    }

    increaseTechCounter() {
        this.techCounter++;
    }

    removeAll() {
        this.allTechs = {};
        this.activeTech = {};
    }

    changeActiveTech(tech: any) {
        this.activeTech = tech;
    }

    alterActiveTech(tech: any) {
        this.changeActiveTechEvent.emit(tech);
    }

    showTech(tech: any) {
        this.showTechMapEvent.emit(tech);
    }

    reloadTechs() {
        this.allTechs = this.storage.getTechs();
    }

    openModal(tech: any) {
        const modalRef = this.modalService.open(
            DispatchModalTechdetailsComponent,
            {
                size: 'xl',
                windowClass: 'zindex',
            }
        );
        modalRef.componentInstance.tech = tech;

        modalRef.componentInstance.makeOrderVisible.subscribe((data: any) => {
            this.makeOrderVisibleEvent.emit(data);
        });
    }
}
