import { TechService } from './../../../_services/tech.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-kpiverslag-output',
    templateUrl: './kpiverslag-output.component.html',
    styleUrls: ['./kpiverslag-output.component.css'],
})
export class KpiverslagOutputComponent implements OnInit {
    @Input() model: any = { changed: false };

    datum: string = '%datum%';
    user: string = '%user%';
    tech: any;

    allTechs: any;

    constructor(techservice: TechService) {
        console.log(this.model);
        techservice.getAll().subscribe((data) => {
            this.allTechs = data;
        });
    }

    ngOnInit(): void {}

    ngOnChanges(c: SimpleChanges) {
        var data = this.model;

        if (this.model) {
            // Datum
            if (data.date) {
                if (data.date.day) {
                    console.log(data.date);
                    this.datum =
                        data.date.day +
                        '/' +
                        data.date.month +
                        '/' +
                        data.date.year;
                } else {
                    this.datum = '%datum%';
                }
            }

            if (data.user) {
                // User
                if (data.user.nice_name) {
                    this.user = data.user.nice_name;
                } else {
                    this.user = '%user%';
                }
            }
            // Tech
            if (data.tech.length) {
                console.log('TET');
                this.tech = Object.values(this.allTechs).filter(
                    (item: any) => item.tech_name == data.tech
                );
            }
        }
        //this.doSomething(changes.categoryId.currentValue);
        // You can also use categoryId.previousValue and
        // categoryId.firstChange for comparing old and new values
    }
}
