import { UserRightsAddModalComponent } from './user-rights-add-modal/user-rights-add-modal.component';
import { UserService } from './../../_services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-user-rights',
    templateUrl: './user-rights.component.html',
    styleUrls: ['./user-rights.component.css'],
})
export class UserRightsComponent implements OnInit {
    allRights: any = [];
    allBranches: any = [];
    allUsers: any = [];

    loading: boolean = false;
    saving: boolean = false;

    errorMessage: any = false;

    constructor(
        private userService: UserService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.userService.getAll(true).subscribe((response) => {
            this.allUsers = response;
            console.log(response);
            this.userService.getAllRights().subscribe((response) => {
                this.allRights = response.rights;
                this.allBranches = response.branches;
                this.loading = false;
            });
        });
    }

    saveRight(user: any, right: string, userIndex: number) {
        this.saving = true;
        var data = { erp_id: user.erp_id, right: right };
        this.userService.updateRight(data).subscribe((response) => {
            this.allUsers[userIndex] = response.user;
            this.saving = false;
        });
    }

    saveBranch(user: any, userIndex: number) {
        this.saving = true;
        var data = { erp_id: user.erp_id, branch: user.branch };
        this.userService.updateRight(data).subscribe((response) => {
            this.allUsers[userIndex] = response.user;
            this.saving = false;
        });
    }

    deleteUser(user: any) {
        this.saving = true;
        if (confirm('Are you sure you want to delete ' + user.username)) {
            var data = { erp_id: user.erp_id, delete: 'confirmed' };
            this.userService.updateRight(data).subscribe((response) => {
                this.userService.getAll(true).subscribe((response) => {
                    this.allUsers = response;
                    this.saving = false;
                });
            });
        }
    }

    addUser() {
        this.errorMessage = false;
        const modalRef = this.modalService.open(UserRightsAddModalComponent, {
            size: 'lg',
            windowClass: 'zindex bigmodal',
        });
        modalRef.componentInstance.saveUser.subscribe((data: any) => {
            this.saving = true;
            this.userService.updateRight(data).subscribe((response) => {
                if (response.error) {
                    this.errorMessage = response.message;
                    this.saving = false;
                } else {
                    this.userService.getAll(true).subscribe((response) => {
                        this.allUsers = response;
                        this.saving = false;
                    });
                }
            });
        });
    }
}
