import { environment } from './../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
    selector: 'app-dispatch-route',
    templateUrl: './dispatch-route.component.html',
    styleUrls: ['./dispatch-route.component.css'],
})
export class DispatchRouteComponent implements OnInit {
    map: any;
    style = 'mapbox://styles/mapbox/streets-v11';
    public screenWidth: any;
    public screenHeight: any;
    constructor() {}

    allMarker: any = [];
    sources: any = [];
    layers: any = [];

    route: any;

    setRouteOnMap(data: any) {
        console.log(data);
        this.route = data;
        this.locateMarker(this.route.tech);
    }

    locateMarker(obj: any) {
        this.map.flyTo({ center: obj.geocode });
    }

    ngOnInit(): void {
        this.screenWidth = '100%';
        this.screenHeight = '400';

        this.map = new mapboxgl.Map({
            container: 'map-routes-dispatch',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 8,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
            this.loadRoutes(this.route);
        });
    }

    clearMap(object: any) {
        for (var i = 0; i < this.allMarker.length; i++) {
            this.allMarker[i].remove();
        }
        this.map.removeLayer('maine');
        this.map.removeSource('route');
    }

    loadRoutes(object: any) {
        if (object === undefined) {
            return true;
        }

        this.map.addSource('route', {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: object.driving_layer.trips[0].geometry,
            },
        });
        // Add a new layer to visualize the polygon.

        this.map.addLayer({
            id: 'maine',
            type: 'line',
            source: 'route', // reference the data source
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': 'red',
                'line-width': 5,
            },
        });

        // Add the home thingy
        this.addMarker(object, 'tech');

        for (var i = 0; i < object.tech._orders.length; i++) {
            this.addMarker(object.tech._orders[i], 'ldc');
        }

        return true;
    }

    addMarker(object: any, type: string, forcecolor: string = '') {
        var color = 'red';
        var geocode = [];
        var scale;

        if (type == 'tech') {
            color = 'blue';
            geocode = object.tech.geocode;
            scale = 0.5;
        } else {
            color = 'purple';
            geocode = object.geocode;
            scale = 0.5;
        }

        if (forcecolor) {
            color = forcecolor;
        }

        var popup = new mapboxgl.Popup({ offset: 25 }).setText(
            'Construction on the Washington Monument began in 1848.'
        );

        var markerElement: any = new mapboxgl.Marker({
            color: color,
            scale: scale,
        })

            .setLngLat(new mapboxgl.LngLat(geocode[0], geocode[1]))
            .setPopup()
            .addTo(this.map);

        this.allMarker.push(markerElement);
    }
}
