import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'areatechs',
})
export class AreatechsPipe implements PipeTransform {
    transform(items: any, filter: string): any {
        if (!items || !filter) {
            return items;
        }

        return Object.values(items).filter((item: any) => item.area == filter);
    }
}
