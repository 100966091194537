import { SubcoService } from './../../_services/subco.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-wiw-detail',
    templateUrl: './wiw-detail.component.html',
    styleUrls: ['./wiw-detail.component.css'],
})
export class WiwDetailComponent implements OnInit {
    @Input() tech: any;
    @Input() userLoginMethod: any = 'TECH';
    showTab: any = 'info';
    login_method = '';
    subco: any;
    constructor(private subcoService: SubcoService) {}

    skills: any = [
        {
            name: 'skill_pxm',
            text: 'Mass market',
            color: '#03B7EA',
            selected: false,
            border: false,
        },
        {
            name: 'skill_proff',
            text: 'Prof market',
            color: '#5A2C8F',
            selected: false,
            border: false,
        },
        {
            name: 'skill_fiber_fis',
            text: 'FIS',
            color: '#B1B8CB',
            selected: false,
            border: true,
        },
        {
            name: 'skill_sct_full',
            text: 'Scarlet',
            color: '#C60404',
            selected: false,
            border: false,
        },
        {
            name: 'skill_sct_light',
            text: 'Mixplan',
            color: '#04C73B',
            selected: false,
            border: true,
        },
        {
            name: 'skill_thermo',
            text: 'Coaching',
            color: '#FFD700',
            selected: false,
            border: false,
        },
        {
            name: 'skill_hp',
            text: 'Academy',
            color: '#FF69B4',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_cop_construction',
            text: 'Construction',
            color: '#66E6FF',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_cop_prov',
            text: 'Provisioning',
            color: '#7484D4',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_cop_repair',
            text: 'Repair',
            color: '#6CD2B8',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_fib_underground',
            text: 'Underground',
            color: '#C4D36E',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_fib_facade',
            text: 'Facade',
            color: '#D3A770',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_aide',
            text: 'Aide',
            color: '#D570C0',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_paving',
            text: 'Paving',
            color: '#7AD46F',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_machine',
            text: 'Machine',
            color: '#7574D3',
            selected: false,
            border: true,
        },
    ];

    ngOnInit(): void {
        console.log(this.tech.subco_id);
        this.getSubco(this.tech.subco_id);
        console.log(this.subco);
    }

    changeTab(data: any) {
        this.showTab = data;
    }

    ngOnChanges(changes: any) {
        this.getSubco(changes.tech.currentValue.subco_id);
    }

    getSubco(id: number) {
        return this.subcoService.getById(id).subscribe((response) => {
            if (response.error) {
                return false;
            } else {
                this.subco = response;
                return true;
            }
        });
    }
}
