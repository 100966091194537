import { DispatchStorageService } from './../../_services/dispatch-storage.service';
import { DispatchMapComponent } from './../dispatch-map/dispatch-map.component';
import {
    ShortcutInput,
    ShortcutEventOutput,
    KeyboardShortcutsComponent,
} from 'ng-keyboard-shortcuts';
import {
    Component,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    EventEmitter,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-dispatch-activetech',
    templateUrl: './dispatch-activetech.component.html',
    styleUrls: ['./dispatch-activetech.component.css'],
})
export class DispatchActivetechComponent implements OnInit {
    @Output() activeTechChangedEvent = new EventEmitter();
    @Output() locateTechEvent = new EventEmitter();
    @Output() hideTechEvent = new EventEmitter();

    // For the keyboard shortcut
    shortcuts: ShortcutInput[] = [];

    tech: any = {};
    set: boolean = false;
    error: boolean = false;

    constructor(public storage: DispatchStorageService) {}

    ngAfterViewInit(): void {
        this.shortcuts.push({
            key: 'e',
            command: (output: ShortcutEventOutput) => this.keyboardShortcut(),
            preventDefault: true,
        });
    }

    keyboardShortcut() {
        if (Object.keys(this.tech).length) {
            console.log(this.tech);
            this.hideTech(this.tech);
        }
    }

    ngOnInit(): void {}

    changeTech(tech: any) {
        // First check if an object is given
        this.set = Object.keys(tech).length ? true : false;

        if (this.set) {
            if (this.storage.activeTechCount()) {
                this.error = true;
            } else {
                this.tech = tech;
                this.storage.setActiveTech(tech);
                this.locateTechEvent.emit(tech);
            }
        } else {
            this.removeActiveStatus();
        }

        this.activeTechChangedEvent.emit(this.tech);

        return this.tech;
    }

    updateTech(tech: any) {
        this.tech = tech;
    }

    removeActiveStatus() {
        this.tech = {};
        this.set = false;
        this.error = false;
        this.storage.delActiveTech();
        this.activeTechChangedEvent.emit(this.tech);
    }

    locateTech(tech: any) {
        this.locateTechEvent.emit(tech);
    }

    hideTech(tech: any) {
        this.hideTechEvent.emit(tech);
    }
}
