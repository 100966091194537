import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TechService } from '../../../../_services/tech.service';

@Component({
  selector: 'app-admin-wiw-alltechs-details',
  templateUrl: './admin-wiw-alltechs-details.component.html',
  styleUrls: ['./admin-wiw-alltechs-details.component.css'],
})
export class AdminWiwAlltechsDetailsComponent implements OnInit {
  tech: any;
  constructor(
    private techService: TechService,
    private actRoute: ActivatedRoute
  ) {
    this.techService
      .getTechById(this.actRoute.snapshot.params.id)
      .subscribe((data) => {
        this.tech = data;
      });
  }

  ngOnInit(): void {}
}
