import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpEvent,
    HttpRequest,
} from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({ providedIn: 'root' })
export class FileService {
    private API_URL = environment.apiUrl;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) {}

    downloadTechTable(): any {
        return this.http.get(this.API_URL + 'tech/allGetExcel', {
            responseType: 'arraybuffer',
        });
    }

    downloadFiberklaarScopelist(id: any): any {
        return this.http.get(
            this.API_URL + 'fiberklaar/scopelist/excel/' + id,
            {
                responseType: 'arraybuffer',
            }
        );
    }

    //https://efficientcoder.net/angular-tutorial-example-upload-files-with-formdata-httpclient-rxjs-and-material-progressbar/
    upload(formData: any): Observable<any> {
        return this.http.post(this.API_URL + 'automail/upload', formData);
    }

    //https://bezkoder.com/angular-11-multiple-file-upload/
    uploadMeetingmap(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest(
            'POST',
            this.API_URL + 'meetingmap/upload',
            formData,
            {
                reportProgress: true,
                responseType: 'json',
            }
        );

        return this.http.request(req);
    }

    uploadDispatch(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest(
            'POST',
            this.API_URL + 'dispatch/upload',
            formData,
            {
                reportProgress: true,
                responseType: 'json',
            }
        );

        return this.http.request(req);
    }

    automailHeaderCheck(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'automail/headerCheck/' + id);
    }

    automailMailCreate(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'automail/mailCreation/' + id);
    }
}
