import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GeoService {
    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getAllNetworks(): Observable<any> {
        return this.http.get(this.API_URL + 'geo/networks', {
            responseType: 'json',
        });
    }

    getAllKVD(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'geo/allKVD/' + id, {
            responseType: 'json',
        });
    }

    getNetworkPolygon(): Observable<any> {
        return this.http.get(this.API_URL + 'geo/networkPolygon', {
            responseType: 'json',
        });
    }

    calculateRoute(data: any): any {
        return this.http
            .post(this.API_URL + 'geo/calculateroute', data)
            .toPromise()
            .then((r) => {
                return r;
            });
    }
}
