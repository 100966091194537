import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { DispatchRouteComponent } from './../../dispatch-route/dispatch-route.component';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { SubcoService } from './../../../_services/subco.service';
import { DispatchService } from './../../../_services/dispatch.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-dispatch-modal-techdetails',
    templateUrl: './dispatch-modal-techdetails.component.html',
    styleUrls: ['./dispatch-modal-techdetails.component.css'],
})
export class DispatchModalTechdetailsComponent implements OnInit {
    @Input() tech: any;
    @Output() makeOrderVisible = new EventEmitter();
    @ViewChild(DispatchRouteComponent)
    dispatchRouteChild: DispatchRouteComponent = new DispatchRouteComponent();
    mapData: any = {};
    distance: string = '';
    duration: string = '';

    allTechs: any;

    constructor(
        public activeModal: NgbActiveModal,
        private dispatchService: DispatchService,
        private storage: DispatchStorageService
    ) {}

    ngOnInit(): void {
        // Get the route of all the orders (if a tech has orders)
        if (this.tech._orders.length) {
            var body = {
                tech: this.tech.geocode,
                orders: this.tech._orders,
            };

            this.getRoute(body);
        }
    }

    // Get the order route from the API
    getRoute(body: any) {
        this.dispatchService.getRoute(body).subscribe((data) => {
            var tosend = { tech: this.tech, driving_layer: data.message };
            this.dispatchRouteChild.setRouteOnMap(tosend);
            this.duration = (data.message.trips[0].duration / 60 / 60).toFixed(
                2
            );
            this.distance = (data.message.trips[0].distance / 1000).toFixed(0);
        });
    }

    getObjectLength(obj: any) {
        return Object.keys(obj).length;
    }

    // Remove an order from the tech object and change the allOrders value
    removeOrder(order: any, key: number) {
        // Get all the orders
        var allOrders = this.storage.getOrders();

        // Change the status of the order
        allOrders[order._origKey]._isHidden = false;
        allOrders[order._origKey]._show = true;

        // Update the local storage
        this.storage.setOrders(allOrders);

        // Remove the order from the tech object

        // Get the tech
        this.allTechs = this.storage.getTechs();
        this.tech = this.allTechs[this.tech._origKey];

        // Remove the order from the tech
        this.tech._orders.splice(key, 1);

        // Change tech points
        this.tech._points =
            this.tech._points - allOrders[order._origKey].points;

        this.allTechs[this.tech._origKey] = this.tech;

        // Update the activetech
        if (this.storage.activeTechCount()) {
            var activeTech = this.storage.getActiveTech();
            if (activeTech.id == this.allTechs[this.tech._origKey].id) {
                this.storage.setActiveTech(this.allTechs[this.tech._origKey]);
            }
        }

        // Update the local storage
        this.storage.setTechs(this.allTechs);

        // Create a body to send to server
        var body = {
            tech: this.tech.geocode,
            orders: this.tech._orders,
        };

        // Fetch new route from API
        this.dispatchService.getRoute(body).subscribe((data) => {
            // Create body to send to routecomponent
            var tosend = { tech: this.tech, driving_layer: data.message };

            // Clear the map
            this.dispatchRouteChild.clearMap(body);

            // Send the body to routecomponent
            this.dispatchRouteChild.loadRoutes(tosend);

            // Alter distance + duration on this component
            this.duration = (data.message.trips[0].duration / 60 / 60).toFixed(
                2
            );
            this.distance = (data.message.trips[0].distance / 1000).toFixed(0);

            // Make the order visible again on the map
            this.makeOrderVisible.emit(allOrders[order._origKey]);
        });
    }
}
