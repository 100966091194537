import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { appInitializer } from './_helpers/app.initializer';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { Select2Module } from 'ng-select2-component';
import { AuthenticationService } from './_services/authentication.service';
import { WiwComponent } from './wiw/wiw.component';
import { WiwDetailComponent } from './wiw/wiw-detail/wiw-detail.component';
import { TechmapComponent } from './common/map/techmap/techmap.component';
import { WiwTechsComponent } from './wiw/wiw-techs/wiw-techs.component';
import { AlltechsComponent } from './common/map/alltechs/alltechs.component';
import { AdminUsersAllComponent } from './admin/users/admin-users-all/admin-users-all.component';
import { AdminTechsAllComponent } from './admin/techs/admin-techs-all/admin-techs-all.component';
import { AdminWiwAlltechsComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs.component';
import { AdminWiwAllsubcosComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubcos.component';
import { AdminWiwAlltechsAddComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-add/admin-wiw-alltechs-add.component';
import { AdminWiwAlltechsEditComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-edit/admin-wiw-alltechs-edit.component';
import { AdminWiwAlltechsDetailsComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-details/admin-wiw-alltechs-details.component';
import { ButtonDownloadTechtableComponent } from './common/button/button-download-techtable/button-download-techtable.component';
import { AdminWiwStatsComponent } from './admin/wiw/admin-wiw-stats/admin-wiw-stats.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AutomailComponent } from './admin/automail/automail.component';
import { AutomailFileuploadComponent } from './admin/automail/automail-fileupload/automail-fileupload.component';
import { AutomailTypeComponent } from './admin/automail/automail-type/automail-type.component';
import { AutomailPreviewerComponent } from './admin/automail/automail-previewer/automail-previewer.component';
import { MmStatsComponent } from './admin/meetingmap/mm-stats/mm-stats.component';
import { MmAdminComponent } from './admin/meetingmap/mm-admin/mm-admin.component';
import { MmAdminFileUploadComponent } from './admin/meetingmap/mm-admin/mm-admin-file-upload/mm-admin-file-upload.component';
import { MmAdminFileInfoComponent } from './admin/meetingmap/mm-admin/mm-admin-file-info/mm-admin-file-info.component';
import { MmAdminInsertComponent } from './admin/meetingmap/mm-admin/mm-admin-insert/mm-admin-insert.component';
import { MmSettingsComponent } from './admin/meetingmap/mm-settings/mm-settings.component';
import { MmSettingsMapComponent } from './admin/meetingmap/mm-settings/mm-settings-map/mm-settings-map.component';
import { SsoComponent } from './sso/sso.component';
import { AvailabilityComponent } from './coaching/availability/availability.component';
import { MmMapComponent } from './meetingmap/mm-map/mm-map.component';
import { KvdmapComponent } from './common/map/kvdmap/kvdmap.component';
import { TechroutesComponent } from './admin/geo/techroutes/techroutes.component';
import { TechroutesMapComponent } from './admin/geo/techroutes/techroutes-map/techroutes-map.component';
import { SmsGatewayComponent } from './admin/sms-gateway/sms-gateway.component';
import { AdminWiwAllsubcoDetailsComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubco-details/admin-wiw-allsubco-details.component';
import { AdminWiwAllsubcoEditComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubco-edit/admin-wiw-allsubco-edit.component';
import { AdminWiwAllsubcoAddComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubco-add/admin-wiw-allsubco-add.component';
import { AdminWiwSkillsComponent } from './admin/wiw/admin-wiw-skills/admin-wiw-skills.component';
import { AdminTechsGridComponent } from './admin/techs/admin-techs-grid/admin-techs-grid.component';
import { DispatchComponent } from './dispatch/dispatch.component';
import { DispatchMapComponent } from './dispatch/dispatch-map/dispatch-map.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DispatchSidebarComponent } from './dispatch/dispatch-sidebar/dispatch-sidebar.component';
import { DispatchModalTechniciansComponent } from './dispatch/modal/dispatch-modal-technicians/dispatch-modal-technicians.component';
import { ScttechsPipe } from './_pipes/scttechs.pipe';
import { SearchobjectPipe } from './_pipes/searchobject.pipe';
import { HiddentechsPipe } from './_pipes/hiddentechs.pipe';
import { DispatchModalToolsComponent } from './dispatch/modal/dispatch-modal-tools/dispatch-modal-tools.component';
import { DispatchActivetechComponent } from './dispatch/dispatch-activetech/dispatch-activetech.component';
import { DispatchSidebarTechsComponent } from './dispatch/dispatch-sidebar/dispatch-sidebar-techs/dispatch-sidebar-techs.component';
import { OrderModule } from 'ngx-order-pipe';
import { DispatchModalTechdetailsComponent } from './dispatch/modal/dispatch-modal-techdetails/dispatch-modal-techdetails.component';
import { DispatchRouteComponent } from './dispatch/dispatch-route/dispatch-route.component';
import { DispatchUploadComponent } from './admin/dispatch/dispatch-upload/dispatch-upload.component';
import { DispatchFileUploadComponent } from './admin/dispatch/dispatch-file-upload/dispatch-file-upload.component';
import { DispatchFileInfoComponent } from './admin/dispatch/dispatch-file-info/dispatch-file-info.component';
import { DispatchInsertComponent } from './admin/dispatch/dispatch-insert/dispatch-insert.component';
import { DispatchAdminMapComponent } from './admin/dispatch/dispatch-admin-map/dispatch-admin-map.component';
import { AreatechsPipe } from './_pipes/areatechs.pipe';
import { DispatchModalOrdersComponent } from './dispatch/modal/dispatch-modal-orders/dispatch-modal-orders.component';
import { AvailabletechsPipe } from './_pipes/availabletechs.pipe';
import { DispatchExportModalComponent } from './dispatch/modal/dispatch-export-modal/dispatch-export-modal.component';
import { AreaCountShowAreaPipe } from './_pipes/area-count-show-area.pipe';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { KpiverslagComponent } from './admin/kpiverslag/kpiverslag.component';
import { KpiverslagInputComponent } from './admin/kpiverslag/kpiverslag-input/kpiverslag-input.component';
import { KpiverslagOutputComponent } from './admin/kpiverslag/kpiverslag-output/kpiverslag-output.component';
import { KpiverslagPipe } from './_pipes/kpiverslag.pipe';
import { Go4fiberComponent } from './go4fiber/go4fiber.component';
import { G4fTechniciansComponent } from './go4fiber/g4f-technicians/g4f-technicians.component';
import { G4fDashboardComponent } from './go4fiber/g4f-dashboard/g4f-dashboard.component';
import { G4fSubcosComponent } from './go4fiber/g4f-subcos/g4f-subcos.component';
import { G4fToestellenComponent } from './go4fiber/g4f-toestellen/g4f-toestellen.component';
import { G4fOnderhoudComponent } from './go4fiber/g4f-onderhoud/g4f-onderhoud.component';
import { G4fFacturatieComponent } from './go4fiber/g4f-facturatie/g4f-facturatie.component';
import { G4fKitsComponent } from './go4fiber/g4f-kits/g4f-kits.component';
import { G4fTechTableComponent } from './go4fiber/g4f-technicians/g4f-tech-table/g4f-tech-table.component';
import { G4fTechAddComponent } from './go4fiber/g4f-technicians/g4f-tech-add/g4f-tech-add.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatumfilterPipe } from './_pipes/datumfilter.pipe';
import { KeysPipe } from './_pipes/keys.pipe';
import { TextPipe } from './_pipes/text.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserRightsComponent } from './admin/user-rights/user-rights.component';
import { UserRightsAddModalComponent } from './admin/user-rights/user-rights-add-modal/user-rights-add-modal.component';
import { SmsGatewayV2Component } from './admin/sms-gateway-v2/sms-gateway-v2.component';
import { SmsComponentComponent } from './admin/sms-gateway-v2/sms-component/sms-component.component';
import { SmsTechsComponent } from './admin/sms-gateway-v2/sms-techs/sms-techs.component';
@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        LoginComponent,
        HomeComponent,
        UserProfileComponent,
        WiwComponent,
        WiwDetailComponent,
        TechmapComponent,
        WiwTechsComponent,
        AlltechsComponent,
        AdminUsersAllComponent,
        AdminTechsAllComponent,
        AdminWiwAlltechsComponent,
        AdminWiwAllsubcosComponent,
        AdminWiwAlltechsAddComponent,
        AdminWiwAlltechsEditComponent,
        AdminWiwAlltechsDetailsComponent,
        ButtonDownloadTechtableComponent,
        AdminWiwStatsComponent,
        AutomailComponent,
        AutomailFileuploadComponent,
        AutomailTypeComponent,
        AutomailPreviewerComponent,
        MmStatsComponent,
        MmAdminComponent,
        MmAdminFileUploadComponent,
        MmAdminFileInfoComponent,
        MmAdminInsertComponent,
        MmSettingsComponent,
        MmSettingsMapComponent,
        SsoComponent,
        AvailabilityComponent,
        MmMapComponent,
        KvdmapComponent,
        TechroutesComponent,
        TechroutesMapComponent,
        SmsGatewayComponent,
        SmsGatewayV2Component,
        AdminWiwAllsubcoDetailsComponent,
        AdminWiwAllsubcoEditComponent,
        AdminWiwAllsubcoAddComponent,
        AdminWiwSkillsComponent,
        AdminTechsGridComponent,
        DispatchComponent,
        DispatchMapComponent,
        DispatchSidebarComponent,
        DispatchModalTechniciansComponent,
        ScttechsPipe,
        SearchobjectPipe,
        HiddentechsPipe,
        DispatchModalToolsComponent,
        DispatchActivetechComponent,
        DispatchSidebarTechsComponent,
        DispatchModalTechdetailsComponent,
        DispatchRouteComponent,
        DispatchUploadComponent,
        DispatchFileUploadComponent,
        DispatchFileInfoComponent,
        DispatchInsertComponent,
        DispatchAdminMapComponent,
        AreatechsPipe,
        DispatchModalOrdersComponent,
        AvailabletechsPipe,
        DispatchExportModalComponent,
        AreaCountShowAreaPipe,
        KpiverslagComponent,
        KpiverslagInputComponent,
        KpiverslagOutputComponent,
        KpiverslagPipe,
        Go4fiberComponent,
        G4fTechniciansComponent,
        G4fDashboardComponent,
        G4fSubcosComponent,
        G4fToestellenComponent,
        G4fOnderhoudComponent,
        G4fFacturatieComponent,
        G4fKitsComponent,
        G4fTechTableComponent,
        G4fTechAddComponent,
        DatumfilterPipe,
        KeysPipe,
        TextPipe,
        UserRightsComponent,
        UserRightsAddModalComponent,
        SmsComponentComponent,
        SmsTechsComponent,
        SmsTechsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        NgxPaginationModule,
        NgApexchartsModule,
        [ModalModule.forRoot()],
        ReactiveFormsModule,
        NgbModule,
        OrderModule,
        KeyboardShortcutsModule.forRoot(),
        BrowserAnimationsModule,
        DragDropModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        Select2Module,
        NgSelectModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthenticationService],
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
