import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
    selector: 'app-dispatch-admin-map',
    templateUrl: './dispatch-admin-map.component.html',
    styleUrls: ['./dispatch-admin-map.component.css'],
})
export class DispatchAdminMapComponent implements OnInit {
    map: any;
    style = 'mapbox://styles/mapbox/streets-v11';

    @Input() newOrder: any = {};

    allMarkers: any = [];

    public screenWidth: any;
    public screenHeight: any;

    constructor() {}
    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 450;

        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 7,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
        });

        this.map.addControl(new mapboxgl.NavigationControl());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.newOrder) {
            var newOrder = changes.newOrder;
            if (!newOrder.firstChange) {
                this.addMarker(newOrder.currentValue);
            }
        }
    }

    addMarker(element: any) {
        var markerElement: any = new mapboxgl.Marker({
            scale: 0,
        })

            .setLngLat(
                new mapboxgl.LngLat(element.geocode[0], element.geocode[1])
            )

            .addTo(this.map);

        return markerElement;
    }
}
