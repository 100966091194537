import { TechService } from './../../../_services/tech.service';
import { Component, OnInit } from '@angular/core';
declare var bootstrap: any;
@Component({
    selector: 'app-admin-wiw-skills',
    templateUrl: './admin-wiw-skills.component.html',
    styleUrls: ['./admin-wiw-skills.component.css'],
})
export class AdminWiwSkillsComponent implements OnInit {
    allTechs: any;

    skills: any = [
        {
            name: 'skill_pxm',
            text: 'Mass market',
            color: '#03B7EA',
            selected: false,
            border: false,
        },
        {
            name: 'skill_proff',
            text: 'Prof market',
            color: '#5A2C8F',
            selected: false,
            border: false,
        },
        {
            name: 'skill_fiber_fis',
            text: 'FIS',
            color: '#B1B8CB',
            selected: false,
            border: true,
        },
        {
            name: 'skill_sct_full',
            text: 'Scarlet',
            color: '#C60404',
            selected: false,
            border: false,
        },
        {
            name: 'skill_sct_light',
            text: 'Mixplan',
            color: '#04C73B',
            selected: false,
            border: true,
        },
        {
            name: 'skill_thermo',
            text: 'Coaching',
            color: '#FFD700',
            selected: false,
            border: false,
        },
        {
            name: 'skill_hp',
            text: 'Academy',
            color: '#FF69B4',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_cop_construction',
            text: 'Construction',
            color: '#66E6FF',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_cop_prov',
            text: 'Provisioning',
            color: '#7484D4',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_cop_repair',
            text: 'Repair',
            color: '#6CD2B8',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_fib_underground',
            text: 'Underground',
            color: '#C4D36E',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_fib_facade',
            text: 'Facade',
            color: '#D3A770',
            selected: false,
            border: true,
        },
        {
            name: 'skill_acn_aide',
            text: 'Aide',
            color: '#D570C0',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_paving',
            text: 'Paving',
            color: '#7AD46F',
            selected: false,
            border: false,
        },
        {
            name: 'skill_acn_machine',
            text: 'Machine',
            color: '#7574D3',
            selected: false,
            border: true,
        },
    ];

    loading: boolean = false;
    toastrMessage: string = '';

    constructor(private techService: TechService) {
        this.loading = true;
        this.techService.getAll().subscribe((data) => {
            this.allTechs = data;
            this.loading = false;
        });
    }

    ngOnInit(): void {}

    trackByIndex(index: number) {
        return index;
    }

    getValue(i: number, skill: string) {
        if (this.allTechs[i][skill] == 1) {
            return true;
        }
        return false;
    }

    updateTech(tech: any) {
        console.log(tech);
        delete tech['function'];
        delete tech['app'];
        delete tech['phone_private_clean'];
        delete tech['phone_work_clean'];
        this.techService.editTech(tech).subscribe((data) => {
            console.log(data);
            const toastEl = document.getElementById('liveToast');
            const toast = new bootstrap.Toast(toastEl);
            const dataDecode = JSON.parse(data);
            this.toastrMessage = dataDecode.message;
            toast.show();
        });
    }

    clearAll(iTech: number) {
        for (var i = 0; i < this.skills.length; i++) {
            this.allTechs[iTech][this.skills[i]] = 0;
        }
        this.updateTech(this.allTechs[iTech]);
    }

    changeOne(iTech: number, newVal: number, skill: string) {
        this.allTechs[iTech][skill] = newVal;
        this.updateTech(this.allTechs[iTech]);
    }
}
