import { DispatchStorageService } from './../../_services/dispatch-storage.service';
import { DispatchActivetechComponent } from './../dispatch-activetech/dispatch-activetech.component';
import { DispatchSidebarTechsComponent } from './dispatch-sidebar-techs/dispatch-sidebar-techs.component';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-dispatch-sidebar',
    templateUrl: './dispatch-sidebar.component.html',
    styleUrls: ['./dispatch-sidebar.component.css'],
})
export class DispatchSidebarComponent implements OnInit {
    // Run parent function to open a modal
    @Output() openModal = new EventEmitter();
    @Output() showTechOnMapEvent = new EventEmitter();
    @Output() addOrderBackToMapEvent = new EventEmitter();
    @Output() changeActiveTechGlobalEvent = new EventEmitter();
    @Input() activeTech: any;
    opened1: boolean = true;
    allTechs: any = {};

    @ViewChild(DispatchSidebarTechsComponent)
    sidebarTechChild!: DispatchSidebarTechsComponent;

    areaCount: any = [];

    totalOrders: number = 0;
    techsNeeded: number = 0;

    constructor(private storage: DispatchStorageService) {}

    ngOnInit(): void {
        this.updateAreaCount();
        this.localOrderCounter();
    }

    countNewOrder() {
        this.totalOrders++;
        this.techsNeeded = this.totalOrders / 7.5;
    }

    reloadTechs() {
        this.allTechs = this.storage.getTechs();
        this.sidebarTechChild.reloadTechs();
    }

    openTechModal() {
        this.openModal.emit('techmodal');
    }

    openToolsModal() {
        this.openModal.emit('toolsmodal');
    }

    openExportModal() {
        this.openModal.emit('exportmodal');
    }

    openOrdersModal() {
        this.openModal.emit('ordersmodal');
    }

    showTechMapChange(event: any) {
        this.showTechOnMapEvent.emit(event);
    }

    makeOrderVisibleChange(event: any) {
        this.reloadTechs();
        this.addOrderBackToMapEvent.emit(event);
    }

    countObject(obj: any) {
        return Object.keys(this.areaCount);
    }

    updateAreaCount() {
        this.areaCount = this.storage.getAreaCount();
    }

    changeActiveTechChange(event: any) {
        this.changeActiveTechGlobalEvent.emit(event);
    }

    removeAllTechs() {
        if (this.sidebarTechChild) {
            this.areaCount = [];
            this.sidebarTechChild.removeAll();
            this.totalOrders = 0;
            this.techsNeeded = 0;
        }
    }

    localOrderCounter() {
        for (var i = 0; i < this.areaCount.length; i++) {
            if (this.areaCount[i].addedToMap) {
                this.totalOrders += this.areaCount[i].total;
            }
        }
        this.techsNeeded = this.totalOrders / 7.5;
    }
}
