<div class="row">
    <div class="col-md-1">
        <a
            class="btn btn-secondary w-100"
            [routerLink]="['/admin/wiw/alltechs']"
            ><i class="far fa-hand-point-left"></i> Back</a
        >
    </div>
    <div class="col-md-10">
        <div class="alert alert-info">
            <i class="fas fa-search"></i> Details for
            {{ tech?.tech_name | titlecase }}
        </div>
        <table class="table table-sm table-bordered table-hover">
            <thead>
                <th>Variable</th>
                <th>Value</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of tech | keyvalue">
                    <td class="font-italic">{{ item.key }}</td>
                    <td [ngClass]="{ 'bg-warning': !item.value }">
                        {{ item.value }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
