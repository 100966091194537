import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dispatch-upload',
    templateUrl: './dispatch-upload.component.html',
    styleUrls: ['./dispatch-upload.component.css'],
})
export class DispatchUploadComponent implements OnInit {
    fileIDs: any;
    allDone: any;
    infoDone: any;
    constructor() {}

    ngOnInit(): void {}

    uploadedFileIDsChangedHandler(event: any) {
        this.fileIDs = event;
    }

    allDoneChangedHandler(event: any) {
        this.allDone = true;
    }

    infoDoneChangedHandler(event: any) {
        this.infoDone = event;
    }
}
