import { TechService } from './../../_services/tech.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-g4f-technicians',
    templateUrl: './g4f-technicians.component.html',
    styleUrls: ['./g4f-technicians.component.css'],
})
export class G4fTechniciansComponent implements OnInit {
    active = 1;

    constructor() {}

    ngOnInit(): void {}
}
