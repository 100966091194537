import { Component, Input, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-techroutes-map',
    templateUrl: './techroutes-map.component.html',
    styleUrls: ['./techroutes-map.component.css'],
})
export class TechroutesMapComponent implements OnInit {
    @Input() mapData: any;
    map: any;
    style = 'mapbox://styles/mapbox/streets-v11';
    public screenWidth: any;
    public screenHeight: any;
    constructor() {}

    ngOnDestroy() {
        this.map.remove();
        this.map = null;
    }

    ngOnInit(): void {
        this.screenWidth = '100%';
        this.screenHeight = window.innerHeight - 300;

        this.map = new mapboxgl.Map({
            container: 'mapTechRoutes',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 6,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
        });
    }

    loadRoutes(object: any, index: number) {
        console.log(object);
        if (object === undefined) {
            return true;
        }
        var popup = new mapboxgl.Popup({
            closeButton: false,
        });
        this.map.addSource('route_' + index, {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: object.driving_layer.trips[0].geometry,
            },
        });

        // Add a new layer to visualize the polygon.
        this.map.addLayer({
            id: 'maine_' + index,
            type: 'line',
            source: 'route_' + index, // reference the data source
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': object.tech.color,
                'line-width': 6,
            },
        });

        // Popup
        this.map.on('mousemove', 'maine_' + index, (e: any) => {
            this.map.getCanvas().style.cursor = 'pointer';

            // Populate the popup and set its coordinates based on the feature.
            var feature = e.features[0];
            popup
                .setLngLat(
                    new mapboxgl.LngLat(
                        feature.geometry.coordinates[0][0],
                        feature.geometry.coordinates[0][1]
                    )
                )
                .setHTML(
                    this.generatePopupContentKVD(
                        'Route of tech ' + object.tech.tech_name
                    )
                )
                .addTo(this.map);
        });
        this.map.on('mouseleave', 'maine_' + index, (e: any) => {
            this.map.getCanvas().style.cursor = '';

            // Populate the popup and set its coordinates based on the feature.
            popup.off();
        });

        // Add the home thingy
        this.addMarker(object, index, 'tech');

        for (var i = 0; i < object.all_ldc.length; i++) {
            this.addMarker(object.all_ldc[i], index, 'ldc', object.tech.color);
        }
        return true;
    }

    addMarker(
        object: any,
        index: number,
        type: string,
        forcecolor: string = ''
    ) {
        var color = 'red';
        var geocode = [];
        var scale;

        if (type == 'tech') {
            color = 'red';
            geocode = object.tech.geocode;
            scale = 0.5;
        } else {
            color = 'red';
            geocode = object.geocode;
            scale = 0.5;
        }

        if (forcecolor) {
            color = forcecolor;
        }

        var popup = new mapboxgl.Popup({ offset: 25 }).setText(
            'Construction on the Washington Monument began in 1848.'
        );

        var markerElement: any = new mapboxgl.Marker({
            color: color,
            scale: scale,
        })

            .setLngLat(new mapboxgl.LngLat(geocode[0], geocode[1]))
            .setPopup()
            .addTo(this.map);
    }

    generatePopupContentKVD(f: any) {
        var html = '<table>' + '<td>' + f + '</td></tr>' + '</table>';

        return html;
    }
}
