import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datumfilter',
})
export class DatumfilterPipe implements PipeTransform {
    transform(items: any, date: string, onlyToday: any = false): any {
        if (!items || !date) {
            return items;
        }

        if (!onlyToday) {
            return items;
        }
        var test = Object.values(items).filter((item: any) => {
            if (item.afspraakdatum == date) {
                console.log(item);
                return item;
            }
            console.log(item);
            console.log(date);
        });

        return test;
    }
}
