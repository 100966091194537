<h2>Grid</h2>
<table class="table table-borderd table-hover">
    <thead>
        <tr>
            <th>Name</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let tech of allTechs">
            <td>{{ tech.tech_name }}</td>
        </tr>
    </tbody>
</table>
