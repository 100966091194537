<h2>Tech skills</h2>
<style></style>

<div class="alert alert-info" *ngIf="loading">
    <i class="fa fa-spinner fa-spin"></i> Loading data
</div>
<table
    class="table table-sm table-hover table-bordered header-fixed"
    style="height: 500px; width: 2100px"
    *ngIf="!loading">
    <thead>
        <tr>
            <th
                colspan="3"
                style="width: 350px; border-right: 4px solid black"
                class="text-center">
                &nbsp;
            </th>
            <th
                colspan="3"
                style="width: 345px; border-right: 4px solid black"
                class="text-center">
                Proximus
            </th>
            <th
                colspan="2"
                style="width: 230px; border-right: 4px solid black"
                class="text-center">
                Olo
            </th>
            <th
                colspan="2"
                style="width: 230px; border-right: 4px solid black"
                class="text-center">
                Backoffice
            </th>
            <th
                colspan="3"
                style="width: 345px; border-right: 4px solid black"
                class="text-center">
                ACN - COP
            </th>
            <th
                colspan="2"
                style="width: 230px; border-right: 4px solid black"
                class="text-center">
                ACN - FIB
            </th>
            <th
                colspan="3"
                style="width: 345px; border-right: 4px solid black"
                class="text-center">
                ACN
            </th>
        </tr>
        <tr>
            <th style="width: 50px" class="text-center">&nbsp;</th>
            <th style="width: 50px" class="text-center">Area</th>
            <th
                style="width: 250px; border-right: 4px solid black"
                class="text-center">
                Tech
            </th>
            <ng-container *ngFor="let skill of skills; let i1 = index">
                <th
                    class="text-center"
                    [ngStyle]="{
                        'background-color': skill.color,
                        'border-right': skill.border ? '4px solid black' : ''
                    }"
                    style="width: 115px">
                    {{ skill.text }}
                </th></ng-container
            >
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let tech of allTechs; let i = index; trackBy: trackByIndex"
            style="border-bottom: 0px">
            <td style="width: 50px">
                <button class="btn btn-sm btn-danger" (click)="clearAll(i)">
                    <i class="fas fa-snowplow"></i>
                </button>
            </td>
            <td style="width: 50px">A{{ tech.area }}</td>
            <td style="width: 250px; border-right: 4px solid black">
                {{ tech.tech_name }}
            </td>
            <ng-container *ngFor="let skill of skills; let i1 = index">
                <td
                    style="width: 115px"
                    [ngStyle]="{
                        'border-right': skill.border ? '4px solid black' : ''
                    }">
                    <button
                        ngbTooltip="A{{ tech.area }} - {{
                            tech.tech_name
                        }} - Remove skill: {{ skill.name }}"
                        class="btn btn-danger btn-sm w-100"
                        *ngIf="getValue(i, skill.name)"
                        (click)="changeOne(i, 0, skill.name)">
                        <i class="fas fa-minus-circle"></i>
                    </button>
                    <button
                        ngbTooltip="A{{ tech.area }} - {{
                            tech.tech_name
                        }} - Add skill: {{ skill.text }}"
                        class="btn btn-success btn-sm w-100"
                        *ngIf="!getValue(i, skill.name)"
                        (click)="changeOne(i, 1, skill.name)">
                        <i class="fas fa-plus-circle"></i>
                    </button></td
            ></ng-container>
        </tr>
    </tbody>
</table>

<div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
    <div
        id="liveToast"
        class="toast bg-success"
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <div class="toast-body">{{ toastrMessage }}</div>
    </div>
</div>
