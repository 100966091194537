<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-tools"></i> Tools</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4">
            <button
                class="btn btn-outline-danger btn-sm"
                (click)="clearLocalStorage()"
            >
                Clear localstorage
            </button>
        </div>
    </div>

    <br />
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')"
        >
            Close
        </button>
    </div>
</div>
