import { PlanningService } from './../../../_services/planning.service';
import { TechService } from './../../../_services/tech.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-techs-grid',
    templateUrl: './admin-techs-grid.component.html',
    styleUrls: ['./admin-techs-grid.component.css'],
})
export class AdminTechsGridComponent implements OnInit {
    allTechs: any;
    monthNumber: number = 0;
    month: any;
    constructor(
        private techService: TechService,
        private planningService: PlanningService
    ) {
        this.techService.getAll().subscribe((data) => {
            this.allTechs = data;

            this.planningService.month(this.monthNumber).subscribe((data) => {
                this.month = data;
                console.log(this.month);
            });
        });
    }

    ngOnInit(): void {}
}
