<h1><i class="fab fa-mailchimp"></i> Automail</h1>
<hr />
<div class="row" *ngIf="!loading">
    <div class="col-md-6">
        <app-automail-fileupload
            (fileChanged)="fileChangedHandler($event)"
            [loading]="loading"
        ></app-automail-fileupload>
    </div>
    <div class="col-md-1 text-center">&nbsp;</div>
    <div class="col-md-5">
        <app-automail-type
            [user]="user"
            [loading]="loading"
            (automailChanged)="automailChangedHandler($event)"
        ></app-automail-type>
    </div>
</div>
<div class="row" *ngIf="loadingMailCreate">
    <div class="col-md-12">
        <div class="alert alert-warning">
            <button class="btn btn-warning btn-sm" (click)="restartHeaders()">
                <i class="fas fa-undo"></i> I've did something wrong and want to
                restart
            </button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <br />
        <button
            class="btn btn-sm btn-primary"
            *ngIf="file && automail && !loading"
            (click)="submit()"
        >
            <i class="fas fa-play-circle"></i> Upload & continue
        </button>
        <div class="alert alert-info" *ngIf="loading">
            <i class="fas fa-spinner fa-pulse" *ngIf="!loadingHeaders"></i>
            <i class="fas fa-upload" *ngIf="loadingHeaders"></i> File (<strong
                >{{ file.name }} </strong
            >) is being uploaded to the server
        </div>
        <div class="alert alert-success" *ngIf="loadingHeaders">
            <i class="fas fa-check-circle"></i> File upload successfull
        </div>
    </div>
</div>
<div class="row" *ngIf="loadingHeaders">
    <div class="col-md-12">
        <div class="alert alert-info">
            <i
                class="fas fa-spinner fa-pulse"
                *ngIf="!errorHeaders && !loadingMailCreate"
            ></i>
            <i
                class="fas fa-table"
                *ngIf="!errorHeaders && loadingMailCreate"
            ></i>
            <i
                class="fas fa-exclamation-circle text-danger"
                *ngIf="errorHeaders && !loadingMailCreate"
            ></i>
            Getting header information from server for script "<strong>{{
                automail
            }}</strong
            >"
        </div>
        <div class="alert alert-danger" *ngIf="errorHeaders">
            <span [innerHtml]="errorHeaders"></span>
            <button class="btn btn-sm btn-danger" (click)="restartHeaders()">
                <i class="fas fa-undo"></i> Restart
            </button>
        </div>
        <div class="alert alert-success" *ngIf="loadingMailCreate">
            <i class="fas fa-check-circle"></i> Headers correct
        </div>
    </div>
</div>
<div class="row" *ngIf="loadingMailCreate">
    <div class="col-md-12">
        <div class="alert alert-info" *ngIf="loadingMailCreate">
            <i
                class="fas fa-spinner fa-pulse"
                *ngIf="!errorMailCreate && !loadingMailShow"
            ></i>
            <i
                class="fas fa-industry"
                *ngIf="!errorMailCreate && loadingMailShow"
            ></i>
            <i
                class="fas fa-exclamation-circle text-danger"
                *ngIf="errorMailCreate && !loadingMailShow"
            ></i>
            Creating the mails <small>(this can take a while)</small>
        </div>
        <div class="alert alert-danger" *ngIf="errorMailCreate">
            {{ errorMailCreate }}
            <button class="btn btn-sm btn-danger" (click)="restartHeaders()">
                <i class="fas fa-undo"></i> Restart
            </button>
        </div>
        <div class="alert alert-success" *ngIf="loadingMailShow">
            <i class="fas fa-check-circle"></i> Mails created, preview is beeing
            generated
        </div>
    </div>
</div>
<app-automail-previewer
    [mails]="previewMails"
    *ngIf="loadingMailShow"
></app-automail-previewer>
