import { DispatchTechService } from './../../../_services/dispatch-tech.service';
import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { TechService } from './../../../_services/tech.service';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dispatch-modal-technicians',
    templateUrl: './dispatch-modal-technicians.component.html',
    styleUrls: ['./dispatch-modal-technicians.component.css'],
})
export class DispatchModalTechniciansComponent implements OnInit {
    showArea: string = '';
    search: string = '';
    allTechs: any;
    availableTechs: any;
    erpIDS: string = '';

    @Output() passTechs = new EventEmitter();

    @ViewChild('searchInput') inputEl: ElementRef | any;

    ngAfterViewInit() {
        setTimeout(() => this.inputEl.nativeElement.focus());
    }

    constructor(
        public activeModal: NgbActiveModal,
        private techService: TechService,
        private cTechs: DispatchTechService,
        private storage: DispatchStorageService
    ) {
        this.allTechs = this.cTechs.getTechs();

        // Only proceed when the list is empty
        if (!this.storage.techsCount()) {
            this.techService.getAllArea().subscribe((data) => {
                for (var i = 0; i < data.length; i++) {
                    this.cTechs.tech = data[i];
                    this.cTechs.addTech();
                }
                this.allTechs = this.cTechs.getTechs();
            });
        }
    }

    ngOnInit(): void {}

    // Change the view by area
    setArea(area: string) {
        this.showArea = area;
        setTimeout(() => this.inputEl.nativeElement.focus());
    }

    // Add a technician to the available list
    addTech(tech: any) {
        this.search = '';
        this.cTechs.tech = tech;
        this.allTechs = this.cTechs.setAvailable();
        this.passTechs.emit(this.allTechs[tech._origKey]);
        setTimeout(() => this.inputEl.nativeElement.focus());
    }

    // Remove a technician from the available list
    removeTech(tech: any) {
        this.cTechs.tech = tech;
        this.allTechs = this.cTechs.setUnavailable();
        setTimeout(() => this.inputEl.nativeElement.focus());
    }

    addByErp() {
        var res = this.erpIDS.split(' ');
        // Loop all the techs and check if the erp_id is inside the res
        for (var i = 0; i < this.allTechs.length; i++) {
            // Check if the erp id is filled
            if (this.allTechs[i].ERP_ID) {
                if (res.includes(this.allTechs[i].ERP_ID.toString())) {
                    this.addTech(this.allTechs[i]);
                }
            }
        }
        this.erpIDS = '';
    }
}
