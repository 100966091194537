import { DispatchStorageService } from './../../_services/dispatch-storage.service';
import { DispatchService } from './../../_services/dispatch.service';
import { environment } from './../../../environments/environment.prod';
import { TechService } from './../../_services/tech.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
    selector: 'app-dispatch-map',
    templateUrl: './dispatch-map.component.html',
    styleUrls: ['./dispatch-map.component.css'],
})
export class DispatchMapComponent implements OnInit {
    map: any;
    style = 'mapbox://styles/mapbox/streets-v11';

    type: string = '';
    name: string = '';
    info: string = '';

    @Output() setActiveTechEvent = new EventEmitter();
    @Output() setClickedOrder = new EventEmitter();
    @Output() allTechsUpdateEvent = new EventEmitter();

    allMarkers: any = [];
    allTechs: any;
    allOrders: any;

    public screenWidth: any;
    public screenHeight: any;

    constructor(
        private dispatchService: DispatchService,
        private storage: DispatchStorageService
    ) {}

    ngOnInit(): void {
        // Initiate the map
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 100;

        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 8,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        // Resize the map
        this.map.on('load', () => {
            this.map.resize();
            this.populateInitMap();
        });

        // Add controls to the map
        this.map.addControl(new mapboxgl.NavigationControl());
    }

    start() {
        this.getStorage();
    }

    end() {
        this.updateStorage();
    }

    addNewTech(tech: any) {
        this.getStorage();
        this.addTech(tech);
        this.updateStorage();
        this.allTechsUpdateEvent.emit(this.allTechs);
    }

    addNewOrder(order: any) {
        this.addOrder(order);
    }

    hideTech(tech: any) {
        this.getStorage();
        this.allMarkers[tech._markerKey].remove();
        this.allTechs[tech._origKey]._markerKey = -1;
        this.updateStorage();
        this.allTechsUpdateEvent.emit(this.allTechs);
    }

    removeOrderMarker(marker: any) {
        marker.remove();
    }

    clickedOrder(order: any, marker: any) {
        var data = { order: order, marker: marker };
        this.setClickedOrder.emit(data);
    }

    clickedTech(tech: any) {
        this.setActiveTechEvent.emit(tech);
    }

    locateMarker(obj: any) {
        this.map.flyTo({ center: obj.geocode });
    }

    removeAll() {
        for (var i = 0; i < this.allMarkers.length; i++) {
            this.allMarkers[i].remove();
        }
    }

    private populateInitMap() {
        // Get the techs from localstorage
        this.start();

        // Loop the techs and add a marker vor every visible or available tech
        for (var i = 0; i < this.allTechs.length; i++) {
            if (
                !this.allTechs[i]._isHiddenFromMap &&
                this.allTechs[i]._isAvailable
            ) {
                this.addTech(this.allTechs[i]);
            }
        }

        // Loop the orders and add the orders to the map
        for (var i = 0; i < this.allOrders.length; i++) {
            if (
                !Object.keys(this.allOrders[i]._techAssigned).length &&
                !this.allOrders[i]._isHidden &&
                this.allOrders[i]._show
            ) {
                this.addOrder(this.allOrders[i]);
            }
        }

        this.end();

        // Trigger the parent component
        this.allTechsUpdateEvent.emit(this.allTechs);
    }

    private updateStorage() {
        this.storage.setTechs(this.allTechs);
        this.storage.setOrders(this.allOrders);
    }

    private getStorage() {
        this.allTechs = this.storage.getTechs();
        this.allOrders = this.storage.getOrders();
    }

    private addTech(tech: any): void {
        // Get the image from server
        this.dispatchService.getTechImage(tech.id).subscribe((data) => {
            // Create a new div element to store the image
            var el = document.createElement('div');
            el.style.backgroundImage =
                'url(data:image/png;base64,' + data + ')';
            el.style.width = '20px';
            el.style.height = '20px';
            el.style.cursor = 'pointer';
            el.className = 'marker';

            // Create a new marker and add it to the map
            var marker = new mapboxgl.Marker(el)
                .setLngLat(
                    new mapboxgl.LngLat(tech.geocode[0], tech.geocode[1])
                )
                .addTo(this.map);

            // Add event listners on the element
            const element = marker.getElement();
            element.addEventListener('mouseenter', () =>
                this.setTechInfo(tech)
            );
            element.addEventListener('mouseleave', () => this.removeTechInfo());
            element.addEventListener('click', () => this.clickedTech(tech));

            // Set a markerkey
            this.allTechs[tech._origKey]._markerKey = this.allMarkers.length;

            // Push the marker to the all markers object
            this.allMarkers.push(marker);

            // Update the storage
            this.storage.setTechs(this.allTechs);
        });
    }

    private addOrder(order: any): void {
        this.dispatchService.getOrderImage(order.id).subscribe((data) => {
            var el = document.createElement('div');
            el.style.backgroundImage =
                'url(data:image/png;base64,' + data + ')';
            el.style.backgroundSize = 'cover';
            el.style.backgroundRepeat = 'no-repeat';
            el.style.backgroundPosition = 'center center';
            el.style.width = '20px';
            el.style.height = '20px';
            el.style.cursor = 'pointer';

            el.className = 'marker';
            order._markerKey = this.allMarkers.length;

            var marker = new mapboxgl.Marker(el)
                .setLngLat(
                    new mapboxgl.LngLat(order.geocode[0], order.geocode[1])
                )
                .addTo(this.map);

            const element = marker.getElement();
            element.addEventListener('mouseenter', () =>
                this.setOrderInfo(order)
            );
            element.addEventListener('mouseleave', () => this.removeTechInfo());
            element.addEventListener('click', () =>
                this.clickedOrder(order, marker)
            );

            this.allOrders[order._origKey]._markerKey = this.allMarkers.length;

            this.allMarkers.push(marker);
        });
    }

    private setOrderInfo(order: any) {
        this.type = order.type;
        this.name = order.order.address;
        this.info =
            order.order.jfs_task_timeslot + ' / ' + order.order.language;
    }

    private setTechInfo(tech: any) {
        this.type =
            'Tech (A' +
            tech.area +
            ')(full: ' +
            tech.skill_sct_full +
            ', light: ' +
            tech.skill_sct_light +
            ')';
        this.name = tech.tech_name;
        this.info = tech.tech_street_number + ' ' + tech.tech_zip_city;
    }

    private removeTechInfo() {
        this.type = '';
        this.name = '';
        this.info = '';
    }
}
