<h1><i class="fas fa-jedi"></i> Go4Fiber laskits</h1>
<hr />
<div class="w3-sidebar w3-bar-block" style="width: 15%">
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['dashboard'] } }]"
        ><i class="fas fa-tachometer-alt"></i> Dashboard</a
    >
    <a
        class="w3-bar-item w3-button active"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['technicians'] } }]"
        ><i class="fas fa-users"></i> Techniekers</a
    >
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['subcos'] } }]"
        ><i class="fas fa-user-tie"></i> Subco's</a
    >
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['toestellen'] } }]"
        ><i class="fas fa-tools"></i> Toestellen</a
    >
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['kits'] } }]"
        ><i class="fas fa-toolbox"></i> Kits</a
    >
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['onderhoud'] } }]"
        ><i class="fas fa-tools"></i> Onderhoud</a
    >
    <a
        class="w3-bar-item w3-button"
        [routerLink]="[{ outlets: { g4f_router_outlet: ['facturatie'] } }]"
        ><i class="fas fa-file-invoice-dollar"></i> Facturatie</a
    >
</div>

<div style="margin-left: 20%">
    <router-outlet name="g4f_router_outlet"></router-outlet>
</div>
