<div class="input-group">
    <div class="input-group-prepend">
        <div class="input-group-text"><i class="fa fa-search"></i></div>
    </div>
    <input
        type="text"
        class="form-control"
        placeholder="Search (anything)"
        [ngModel]="searchText"
        [attr.maxlength]="maxInputLength"
        [disabled]="loading"
        #techSearchInput />
</div>
<br />
<div class="alert alert-danger" *ngIf="stopSearch">
    There are no techs found with this search criteria.
</div>
<table class="table table-hover table-striped" *ngIf="!stopSearch">
    <thead>
        <tr>
            <th class="d-none d-md-block">
                <i class="fa fa-globe"></i>
            </th>
            <th>
                <span class="d-none d-md-block"
                    ><i class="fa fa-user"></i> Name</span
                >
                <span class="d-block d-md-none"
                    ><i class="fa fa-user"></i>&nbsp;</span
                >
            </th>
            <th>
                <span class="d-none d-md-block"
                    ><i class="fa fa-phone"></i> Phone</span
                >
                <span class="d-block d-md-none"
                    ><i class="fa fa-phone"></i
                ></span>
            </th>
            <th class="d-none d-md-block">
                <span class="d-none d-md-block"
                    ><i class="fa fa-map-marker-alt"></i> City</span
                >
                <span class="d-block d-md-none"
                    ><i class="fa fa-map-marker-alt"></i
                ></span>
            </th>
            <th>&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="loading"
            ><tr *ngIf="loading">
                <td colspan="5" class="bg-info">
                    <i class="fas fa-spinner fa-pulse"></i> Loading
                </td>
            </tr></ng-container
        >
        <ng-container *ngIf="!loading">
            <tr
                *ngFor="
                    let value of allTechs
                        | paginate
                            : {
                                  id: 'wiw_techs_pagination',
                                  itemsPerPage: 8,
                                  currentPage: pageNumber,
                                  totalItems: allPages
                              }
                ">
                <td class="d-none d-md-block">A{{ value.area }}</td>

                <td>{{ value.tech_name | titlecase }}</td>

                <td>
                    <a
                        href="tel:{{ value.phone_work_clean }}"
                        class="d-none d-md-block"
                        >{{ value.tech_tel_work }}</a
                    >
                    <div class="btn-group">
                        <a
                            href="tel:{{ value.phone_work_clean }}"
                            class="btn btn-primary d-block d-md-none"
                            ><i class="fa fa-phone"></i
                        ></a>
                        <a
                            href="tel:{{ value.phone_private_clean }}"
                            class="btn btn-warning d-block d-md-none"
                            ><i class="fa fa-mobile-alt"></i
                        ></a>
                    </div>
                </td>

                <td class="d-none d-md-block">{{ value.tech_zip_city }}</td>

                <td>
                    <button
                        type="button"
                        class="btn btn-info"
                        (click)="techSelected(value)">
                        <i class="fa fa-info-circle"></i>
                    </button>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<pagination-controls
    id="wiw_techs_pagination"
    (pageChange)="changePage($event)"
    *ngIf="totalPages > 1 && !loading"></pagination-controls>
