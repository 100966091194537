<h1>Meetingmap admin</h1>
<hr />
<div class="row">
    <div class="col-md-6">
        <app-mm-admin-file-upload
            (uploadedFileIDsChanged)="uploadedFileIDsChangedHandler($event)"
            (allDoneChanged)="allDoneChangedHandler($event)"
        ></app-mm-admin-file-upload>
    </div>
    <div class="col-md-6">
        <app-mm-admin-file-info
            [fileIDs]="fileIDs"
            [allDone]="allDone"
            (infoDoneChanged)="infoDoneChangedHandler($event)"
        ></app-mm-admin-file-info>
    </div>
</div>
<div class="row" *ngIf="infoDone">
    <div class="col-md-12">
        <app-mm-admin-insert [files]="infoDone"></app-mm-admin-insert>
    </div>
</div>
