<h1>Meetingmap Settings</h1>
<hr />
<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-6">
                <h2>Single LDC</h2>

                <div class="lexScroller">
                    <span *ngFor="let network of networkPolygon; let i = index">
                        <span *ngIf="network.properties.totalorders"
                            ><button
                                class="btn btn-sm"
                                style="
                                    margin-bottom: 10px;
                                    margin-left: 10px;
                                    margin-right: 30px;
                                    width: calc(100% - 20px);
                                "
                                [class]="{
                                    'btn-danger':
                                        network.properties.mmopen == 0,
                                    'btn-success':
                                        network.properties.mmopen == 1
                                }"
                                (click)="onCheckboxChangeEvent(i)"
                                (mouseenter)="mouseEnter(i)"
                                (mouseleave)="mouseLeave(i)"
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <i
                                            class="fas fa-lock-open"
                                            *ngIf="
                                                network.properties.mmopen == 1
                                            "
                                        ></i>
                                        <i
                                            class="fas fa-lock"
                                            *ngIf="
                                                network.properties.mmopen == 0
                                            "
                                        ></i>
                                    </div>
                                    <div class="col-md-5">
                                        {{ network.properties.netcode }}
                                    </div>
                                    <div class="col-md-5">
                                        <small
                                            >({{
                                                network.properties.totalorders
                                            }}
                                            orders)</small
                                        >
                                    </div>
                                </div></button
                            ><br
                        /></span>
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <h2>All LDC / Network</h2>

                <div class="lexScroller">
                    <span *ngFor="let network of networkGroup | keyvalue">
                        <span *ngIf="getNetworkGroupOrders(network.key)"
                            ><button
                                class="btn btn-sm btn-info"
                                style="
                                    margin-bottom: 10px;
                                    margin-left: 10px;
                                    margin-right: 30px;
                                    width: calc(100% - 20px);
                                "
                                (click)="groupClick(network.key)"
                                (mouseenter)="mouseEnterGroup(network.key)"
                                (mouseleave)="mouseLeaveGroup(network.key)"
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <i class="fas fa-layer-group"></i>
                                    </div>
                                    <div class="col-md-5">
                                        {{ network.key }}
                                    </div>
                                    <div class="col-md-5">
                                        <small
                                            >({{
                                                getNetworkGroupOrders(
                                                    network.key
                                                )
                                            }}
                                            orders)</small
                                        >
                                    </div>
                                </div></button
                            ><br
                        /></span>
                    </span>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-info" *ngIf="!successMessage">
                    Just click on a button to enable-disable the region.
                </div>
                <div class="alert alert-success" *ngIf="successMessage">
                    {{ successMessage }}
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h2>Total open orders: {{ totalOrders }}</h2>
        <app-mm-settings-map
            [networkPolygon]="networkPolygon"
        ></app-mm-settings-map>
    </div>
</div>
<div class="row">
    <div class="col-md-3">
        <button class="btn btn-success w-100" (click)="openAll()">
            Open all
        </button>
    </div>
    <div class="col-md-3">
        <button class="btn btn-danger w-100" (click)="closeAll()">
            Close all
        </button>
    </div>
    <div class="col-md-6">
        <div class="alert alert-info text-center">
            Colorcodes: <i class="fas fa-map text-success"></i> open for this
            network, <i class="fas fa-map text-danger"></i> closed for this
            network, <i class="fas fa-map text-dark"></i> no orders in database
            for this network
        </div>
    </div>
</div>
