import { DispatchExportModalComponent } from './modal/dispatch-export-modal/dispatch-export-modal.component';
import { DispatchStorageService } from './../_services/dispatch-storage.service';
import { DispatchSidebarComponent } from './dispatch-sidebar/dispatch-sidebar.component';
import { DispatchMapComponent } from './dispatch-map/dispatch-map.component';
import { DispatchActivetechComponent } from './dispatch-activetech/dispatch-activetech.component';
import { SubcoService } from './../_services/subco.service';
import { DispatchModalOrdersComponent } from './modal/dispatch-modal-orders/dispatch-modal-orders.component';
import { Subco } from './../_models/subco';
import { DispatchModalToolsComponent } from './modal/dispatch-modal-tools/dispatch-modal-tools.component';
import { DispatchModalTechniciansComponent } from './modal/dispatch-modal-technicians/dispatch-modal-technicians.component';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WatchCompilerHostOfFilesAndCompilerOptions } from 'typescript';
import { ThisReceiver } from '@angular/compiler';

@Component({
    selector: 'app-dispatch',
    templateUrl: './dispatch.component.html',
    styleUrls: ['./dispatch.component.css'],
})
export class DispatchComponent implements OnInit {
    @ViewChild(DispatchActivetechComponent)
    activeTechChild: DispatchActivetechComponent = new DispatchActivetechComponent(
        new DispatchStorageService()
    );

    @ViewChild(DispatchSidebarComponent)
    sidebarChild: DispatchSidebarComponent = new DispatchSidebarComponent(
        new DispatchStorageService()
    );

    @ViewChild(DispatchMapComponent)
    mapChild!: DispatchMapComponent;

    activeTech: any = {};

    activeTechError: any = false;

    newTech: any;

    allTechs: any = {};
    allOrders: any = [];
    removeAll: any = false;

    constructor(
        private modalService: NgbModal,
        private storage: DispatchStorageService
    ) {
        // Populate the storage
        this.getStorage();

        // Clear the active techhnician
        this.setActiveTechChange({});
    }

    ngOnInit(): void {}

    openModal(event: string) {
        if (event == 'techmodal') {
            const modalRef = this.modalService.open(
                DispatchModalTechniciansComponent,
                {
                    size: 'lg',
                    windowClass: 'zindex',
                }
            );

            modalRef.componentInstance.passTechs.subscribe((data: any) => {
                this.mapChild.addNewTech(data);
            });
        }
        if (event == 'exportmodal') {
            const modalRef = this.modalService.open(
                DispatchExportModalComponent,
                {
                    size: 'xl',
                    windowClass: 'zindex',
                }
            );
        }
        if (event == 'ordersmodal') {
            const modalRef = this.modalService.open(
                DispatchModalOrdersComponent,
                {
                    size: 'lg',
                    windowClass: 'zindex',
                }
            );

            modalRef.componentInstance.passOrderToMap.subscribe((data: any) => {
                this.sidebarChild.updateAreaCount();
                this.mapChild.start();
                for (var i = 0; i < data.length; i++) {
                    this.mapChild.addNewOrder(data[i]);
                    // Increases the order count
                    this.sidebarIncreaseOrderCounter();
                }
                this.mapChild.end();
            });
        }
        if (event == 'toolsmodal') {
            const modalRef = this.modalService.open(
                DispatchModalToolsComponent,
                { windowClass: 'zindex' }
            );

            modalRef.componentInstance.removeAllTechs.subscribe(
                (data: boolean) => {
                    this.sidebarChild.removeAllTechs();
                    this.activeTechChild.changeTech({});
                    this.mapChild.removeAll();
                }
            );
        }
    }

    sidebarIncreaseOrderCounter() {
        this.sidebarChild.countNewOrder();
    }

    // Add order back to map
    addOrderBackToMapChange(event: any) {
        this.mapChild.start();
        this.mapChild.addNewOrder(event);
        this.activeTechChild.updateTech(this.storage.getActiveTech());
        // Get the areacount
        var areaCount = this.storage.getAreaCount();
        for (var i = 0; i < areaCount.length; i++) {
            if (areaCount[i].area == this.allOrders[event._origKey].area) {
                areaCount[i].assigned--;
                areaCount[i].orders[
                    event._origKeyAreaCountOrder
                ]._techAssigned = {};
            }
        }
        this.storage.setAreaCount(areaCount);
        this.sidebarChild.updateAreaCount();
        this.mapChild.end();
    }

    // Listens from an event (map)
    // Is called when clicked on a tech
    setActiveTechChange(tech: any) {
        this.activeTechError = false;

        // Get the techs
        this.getStorage();

        if (Object.keys(tech).length) {
            this.activeTech = this.activeTechChild.changeTech(
                this.allTechs[tech._origKey]
            );
            this.sidebarChild.reloadTechs();
        } else {
            this.activeTechChild.changeTech(tech);
        }
    }

    // Only changes the activeTech in the current component (no loopback to activeTechChild)
    activeTechChangeNoLoopback(tech: any) {
        this.activeTech = tech;
        this.sidebarChild.reloadTechs();
    }

    // Fly the map to a tech
    locateTechChange(tech: any) {
        this.mapChild.locateMarker(tech);
    }

    // Hide a tech from the map
    hideTechChange(tech: any) {
        this.setActiveTechChange({});
        this.allTechs[tech._origKey]._isHiddenFromMap = true;
        this.updateStorage();
        this.mapChild.hideTech(tech);
        this.sidebarChild.reloadTechs();
    }

    // Adds an tech to the map
    showTechChange(tech: any) {
        if (this.allTechs[tech._origKey]._isHiddenFromMap) {
            this.allTechs[tech._origKey]._isHiddenFromMap = false;
            this.updateStorage();
            this.mapChild.addNewTech(this.allTechs[tech._origKey]);
        } else {
            this.hideTechChange(tech);
        }
        this.sidebarChild.reloadTechs();
    }

    // Is called when clicked on an orders (event on map component)
    setClickedOrderEvent(data: any) {
        if (!this.storage.activeTechCount()) {
            this.activeTechError = true;
        } else {
            // Set the order in the active tech object
            this.activeTech._orders.push(data.order);
            this.activeTech._points =
                this.activeTech._points + data.order.points;

            this.storage.setActiveTech(this.activeTech);

            // Alter the all techs object
            this.getStorage();

            this.allTechs[this.activeTech._origKey]._orders.push(data.order);

            this.allTechs[this.activeTech._origKey]._points =
                this.allTechs[this.activeTech._origKey]._points +
                data.order.points;

            this.storage.setTechs(this.allTechs);

            // Alter the allOrders object
            this.getStorage();

            // Change the order object
            this.allOrders[data.order._origKey]._isHidden = true;
            this.allOrders[data.order._origKey]._show = false;
            this.allOrders[data.order._origKey]._techAssigned = this.activeTech;

            this.mapChild.removeOrderMarker(data.marker);

            this.updateStorage();

            // Change activetech
            this.activeTechChild.updateTech(this.activeTech);

            // Get the areacount
            var areaCount = this.storage.getAreaCount();
            for (var i = 0; i < areaCount.length; i++) {
                if (
                    areaCount[i].area ==
                    this.allOrders[data.order._origKey].area
                ) {
                    areaCount[i].assigned++;
                    // Put the tech_id with the order
                    areaCount[i].orders[
                        data.order._origKeyAreaCountOrder
                    ]._techAssigned = this.allTechs[this.activeTech._origKey];
                }
            }
            this.storage.setAreaCount(areaCount);
            this.sidebarChild.updateAreaCount();
            this.sidebarChild.reloadTechs();
        }
    }

    // This triggers all the child modules (and parent) for an update of the techobject so the new marker id is fetched everywhere
    allTechsUpdateChange() {
        this.getStorage();
        this.sidebarChild.reloadTechs();
    }

    changeActiveTechGlobalChange(event: any) {
        this.setActiveTechChange({});
        this.setActiveTechChange(event);
    }

    private updateStorage() {
        this.storage.setTechs(this.allTechs);
        this.storage.setOrders(this.allOrders);
        this.storage.setActiveTech(this.activeTech);
    }

    private getStorage() {
        this.allTechs = this.storage.getTechs();
        this.allOrders = this.storage.getOrders();
        this.activeTech = this.storage.getActiveTech();
    }
}
