<table class="table table-bordered table-sm">
    <thead>
        <tr>
            <th>Filename</th>
            <th>Error</th>
            <th>OK</th>
            <th>Total</th>
            <th>Inserter</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let f of files; index as i">
            <td>{{ f.filename }}</td>
            <td>{{ f.progressInfo.error }}</td>
            <td>{{ f.progressInfo.inserted }}</td>
            <td>{{ f.items }}</td>
            <td>
                <div
                    class="progress-bar progress-bar-info"
                    role="progressbar"
                    attr.aria-valuenow="{{ f.progressInfo.value }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: f.progressInfo.value + '%' }"
                >
                    {{ f.progressInfo.value }}%
                </div>
            </td>
        </tr>
    </tbody>
</table>
