<div class="container mt-5 d-flex justify-content-center">
  <div class="card p-3">
    <div class="d-flex align-items-center">
      <div class="image">
        <img src="assets/img/user.png" class="rounded" width="155" />
      </div>
      <div class="ml-3 w-100">
        <h4 class="mb-0 mt-0">
          {{ user.username }}
        </h4>
        <span>{{ user.function }}</span>
        <div
          class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats"
        >
          <div class="d-flex flex-column">
            <span class="articles">Logins</span>
            <span class="number1">{{ user.login_attempts }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="followers">API Calls</span>
            <span class="number2">{{ user.api_calls }}</span>
          </div>
        </div>

        <div class="button mt-2 d-flex flex-row align-items-center">
          Last login: <strong>{{ user.last_login }}</strong>
        </div>
      </div>
    </div>
  </div>
</div>

<br />

<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-sm">
      <thead>
        <th>&nbsp;</th>
        <th>Variable</th>
        <th>Value</th>
      </thead>
      <tbody>
        <tr>
          <td><i class="fa fa-map-signs"></i></td>
          <td>Area's</td>
          <td>
            <ul>
              <li *ngFor="let area of user.area">{{ area }}</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td><i class="fa fa-tablet-alt"></i></td>
          <td>Apps's</td>
          <td>
            <ul>
              <li *ngFor="let app of user.apps">{{ app }}</li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="user.login_method == 'USER'">
          <td><i class="fa fa-mail-bulk"></i></td>
          <td>Automails</td>
          <td>
            <ul>
              <li *ngFor="let automail of user.automails">{{ automail }}</li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="user.login_method == 'USER'">
          <td><i class="fa fa-upload"></i></td>
          <td>File uploads</td>
          <td>
            <ul>
              <li>{{ user.file_uploads }}</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
