import { TechService } from './../../../_services/tech.service';
import { UserService } from './../../../_services/user.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { NgbDateStruct, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, Observable } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-kpiverslag-input',
    templateUrl: './kpiverslag-input.component.html',
    styleUrls: ['./kpiverslag-input.component.css'],
})
export class KpiverslagInputComponent implements OnInit {
    @Output() modelChangeEvent = new EventEmitter();

    modelDate!: NgbDateStruct;
    modelTech: any;
    modelUser: any;
    modelMaandKPIScores: number = 0;
    modelTempKPI: any;
    modelKPIScores: any = [];

    curMonth: string = '';
    prevMonth: string = '';
    prevPrevMonth: string = '';

    allUsers: any;
    allTechs: any;

    techNames: any = [];

    maanden: any = [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
    ];
    redenen: any = ['newbee', 'bottom 20', 'objectief nok'];
    techstatussen: any = ['Habituee', 'Trainee'];

    allKPI: any = [
        {
            name: 'CareE2E',
            objectief: 86,
            doelstelling: 86,
        },
        {
            name: 'TechSat',
            objectief: 90,
            doelstelling: 90,
        },
        {
            name: 'DominoLead',
            objectief: 12,
            doelstelling: 9,
        },
        {
            name: 'DoneProv',
            objectief: 78,
            doelstelling: 70,
        },
        {
            name: 'DoneRep',
            objectief: 90,
            doelstelling: 83,
        },
        {
            name: 'FTRProv',
            objectief: 96.6,
            doelstelling: 92,
        },
        {
            name: 'FTRRep',
            objectief: 95,
            doelstelling: 91,
        },
        {
            name: 'AppKept',
            objectief: 95.5,
            doelstelling: 95.5,
        },
        {
            name: 'Biq',
            objectief: 95,
            doelstelling: 85,
        },
        {
            name: 'DominoSucces',
            objectief: 70,
            doelstelling: 40,
        },
        {
            name: 'FTRCallsCHC',
            objectief: 90,
            doelstelling: 90,
        },
        {
            name: 'IBK',
            objectief: 95,
            doelstelling: 85,
        },
        {
            name: 'LBS',
            objectief: 90,
            doelstelling: 90,
        },
        {
            name: 'Audit',
            objectief: 95,
            doelstelling: 95,
        },
        {
            name: 'FTRSurvey',
            objectief: 80,
            doelstelling: 80,
        },
        {
            name: 'ResponsRatio',
            objectief: 15,
            doelstelling: 15,
        },
        {
            name: 'StartWork',
            objectief: 90,
            doelstelling: 80,
        },
        {
            name: 'C44Calls',
            objectief: 90,
            doelstelling: 85,
        },
    ];

    constructor(userservice: UserService, techservice: TechService) {
        userservice.getAll().subscribe((data) => (this.allUsers = data));
        techservice.getAll().subscribe((data) => {
            this.allTechs = data;
            data.forEach((e: { tech_name: any }) => {
                this.techNames.push(e.tech_name);
            });
        });
        this.initTempKPI();
        this.changeModel(false, false);
    }

    ngOnInit(): void {}

    // For the tech search
    search: OperatorFunction<string, readonly string[]> = (
        text$: Observable<string>
    ) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) =>
                term.length < 0
                    ? []
                    : this.techNames
                          .filter(
                              (v: string) =>
                                  v.toLowerCase().indexOf(term.toLowerCase()) >
                                  -1
                          )
                          .slice(0, 10)
            )
        );

    // For the modelKPIMonth
    getMonth(counting: number) {
        var current = this.modelMaandKPIScores;
        var newNumber = current - counting;

        if (newNumber == 0) {
            return 0;
        }

        if (newNumber < 0) {
            var fromTwelve = counting - current;

            fromTwelve = 12 - fromTwelve;

            return fromTwelve;
        }

        return newNumber;
    }

    initTempKPI() {
        this.modelTempKPI = {
            kpi: {},
            jaargemiddelde: '',
            score: '',
            text: '',
        };
    }

    // Add kpi score
    addKPIScore() {
        this.modelKPIScores.push(this.modelTempKPI);
        this.initTempKPI();
        console.log(this.modelKPIScores);
    }

    deleteKPIScore(i: number) {
        this.modelKPIScores.splice(i, 1);
    }

    // Changes to send to parent
    changeModel(newval: any, changed: boolean = true) {
        var data = {
            date: this.modelDate,
            maandKPIScores: this.modelMaandKPIScores,
            KPIScores: this.modelKPIScores,
            curMonth: this.curMonth,
            prevMonth: this.prevMonth,
            prevPrevMonth: this.prevPrevMonth,
            user: this.modelUser,
            changed: changed,
            tech: this.modelTech,
        };
        this.modelChangeEvent.emit(data);
    }
}
