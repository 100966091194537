import { AutomailService } from './../../../_services/automail.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-automail-previewer',
    templateUrl: './automail-previewer.component.html',
    styleUrls: ['./automail-previewer.component.css'],
})
export class AutomailPreviewerComponent implements OnInit {
    @Input() mails: any;

    mailsAllSend: boolean = false;
    mailSending: boolean = false;
    mailResend: boolean = false;

    collapse: any = [];

    constructor(private automailService: AutomailService) {}

    ngOnInit(): void {
        console.log(this.mails);
    }

    async resendMail(i: number) {
        this.mailSending = true;
        this.mailResend = true;

        this.mails.ok[i].mailsend = 'sending';
        var response = await this.automailService.sendAutomail(
            this.mails.ok[i].mail_db_id,
            i
        );

        if (response.ok) {
            this.mails.ok[i].mailsend = 'done';
        }

        if (response.error) {
            this.mails.ok[i].mailsend = 'error';
            this.mails.ok[i].mailsenderror = response.message;
        }

        this.mailSending = false;
        this.mailResend = false;
    }

    showHide(i: number) {
        const index = this.collapse.indexOf(i);

        if (index !== -1) {
            // Value exists in the array, so remove it
            this.collapse.splice(index, 1);
        } else {
            // Value doesn't exist in the array, so add it
            this.collapse.push(i);
        }
    }

    showCollapse(i: number) {
        const index = this.collapse.indexOf(i);

        if (index !== -1) {
            // Value exists in the array, so remove it
            return true;
        } else {
            // Value doesn't exist in the array, so add it
            return false;
        }
    }

    async sendMails() {
        this.mailSending = true;

        for (let i = 0; i < this.mails.ok.length; i++) {
            if (this.mails.ok[i].mailsend != 'done') {
                this.mails.ok[i].mailsend = 'sending';
                var response = await this.automailService.sendAutomail(
                    this.mails.ok[i].mail_db_id,
                    i
                );

                if (response.ok) {
                    this.mails.ok[i].mailsend = 'done';
                }

                if (response.error) {
                    this.mails.ok[i].mailsend = 'error';
                    this.mails.ok[i].mailsenderror = response.message;
                }
            }
        }

        this.mailSending = false;
        this.mailsAllSend = true;
    }
}
