<div class="row">
    <div class="col-md-6">
        <h1><i class="fas fa-lock"></i> User rights</h1>
    </div>
    <div class="col-md-6">
        <div class="alert alert-info">
            <span *ngIf="saving"
                ><i class="fas fa-spinner fa-pulse"></i> Saving</span
            >
            <span *ngIf="!saving"
                >Click to change. To see the full header, hover over it with
                your mouse.</span
            >
        </div>
    </div>
</div>
<div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>

<table class="table table-sm table-hover table-bordered">
    <thead>
        <tr>
            <th>
                <button
                    class="btn btn-sm btn-outline-primary"
                    [disabled]="loading || saving"
                    (click)="addUser()">
                    <i class="fas fa-user-plus"></i>
                </button>
            </th>
            <th>Username</th>
            <th>Function</th>
            <th>erp_id</th>
            <th>Main Branch</th>
            <th *ngFor="let right of allRights" class="rotated-text">
                <span ngbTooltip="{{ right.full }}">{{ right.capped }}</span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="!loading">
        <tr *ngFor="let user of allUsers; let i = index">
            <td>
                <button
                    class="btn btn-sm btn-outline-danger"
                    (click)="deleteUser(user)"
                    [disabled]="
                        user.username == 'jootn' ||
                        user.username == 'testjoachim' ||
                        saving
                    ">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </td>
            <td>{{ user.username }}</td>
            <td>{{ user.function }}</td>
            <td>{{ user.erp_id }}</td>
            <td>
                <ng-select
                    [(ngModel)]="user.branch"
                    [multiple]="false"
                    [items]="allBranches"
                    [closeOnSelect]="true"
                    (change)="saveBranch(user, i)"
                    [disabled]="
                        user.username == 'jootn' ||
                        user.username == 'testjoachim' ||
                        saving
                    ">
                </ng-select>
            </td>
            <td *ngFor="let right of allRights">
                <button
                    placement="top"
                    ngbTooltip="{{ user.username }} - {{ right.full }}"
                    class="btn btn-sm"
                    [class]="{
                        'btn-outline-success': user.rights[right.full],
                        'btn-outline-danger': !user.rights[right.full]
                    }"
                    [disabled]="
                        right.full == 'full' ||
                        right.full == 'user_admin' ||
                        user.username == 'jootn' ||
                        user.username == 'testjoachim' ||
                        saving
                    "
                    (click)="saveRight(user, right.full, i)">
                    <span *ngIf="!user.rights[right.full]"
                        ><i class="fas fa-unlock-alt"></i
                    ></span>
                    <span *ngIf="user.rights[right.full]"
                        ><i class="fas fa-lock"></i
                    ></span>
                </button>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="loading">
        <tr>
            <td><i class="fas fa-spinner fa-pulse"></i> Loading data</td>
        </tr>
    </tbody>
</table>
