import { DispatchService } from './../../../_services/dispatch.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    SimpleChange,
} from '@angular/core';

@Component({
    selector: 'app-dispatch-file-info',
    templateUrl: './dispatch-file-info.component.html',
    styleUrls: ['./dispatch-file-info.component.css'],
})
export class DispatchFileInfoComponent implements OnInit {
    @Input() fileIDs: any;
    @Input() allDone: any;

    fileInfo: any[] = [];

    infoDone: any;
    @Output() infoDoneChanged: EventEmitter<any> = new EventEmitter();

    constructor(private dispatchService: DispatchService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChange) {
        this.getFileInfo();
        // You can also use categoryId.previousValue and
        // categoryId.firstChange for comparing old and new values
    }

    getFileInfo() {
        if (this.allDone) {
            console.log(this.fileIDs);
            for (let i = 0; i < this.fileIDs.length; i++) {
                this.dispatchService
                    .getUploadedFileInfoById(this.fileIDs[i])
                    .subscribe((data) => {
                        this.fileInfo.push(data);

                        if (this.fileInfo.length == this.fileIDs.length) {
                            this.infoDone = this.fileInfo;
                            this.infoDoneChanged.emit(this.fileInfo);
                        }
                        console.log(data);
                    });
            }
        }
    }
}
