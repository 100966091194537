<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-download"></i> Export</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h3>Planning for {{ planDate }}</h3>
    <div class="row">
        <div class="col-md-3">
            <button
                class="btn btn-outline-success btn-sm w-100"
                (click)="send()"
            >
                <i class="far fa-file-excel"></i> Download planning
            </button>
        </div>
        <div class="col-md-3">
            <button
                class="btn btn-outline-danger btn-sm w-100"
                (click)="toggleAllMixplan()"
            >
                <i class="fas fa-blender"></i>All mixplan (1 ticket)
            </button>
        </div>
        <div class="col-md-6">
            Total orders: {{ cAllOrders }}, Assigned orders:
            {{ cAssignedOrders }}, Limbo orders: {{ cLimboOrders }}
        </div>
    </div>

    <hr />
    <div class="scroller">
        <div *ngFor="let tech of allTechs | availabletechspipe; let i = index">
            <div class="row border" [ngClass]="getBgColor(i)">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-12">
                            <strong
                                ><u
                                    >{{ i + 1 }}. {{ tech.tech_name }}</u
                                ></strong
                            ><span style="color: red" *ngIf="tech._mixplan">
                                (Mixplan!)</span
                            ><span style="color: red" *ngIf="tech._spare">
                                (Spare!)</span
                            >
                        </div>
                    </div>
                    <br />
                    <div class="row" *ngIf="!tech._mixplan">
                        <div class="col-md-3">
                            <button
                                class="btn btn-sm btn-outline-success w-100"
                                (click)="addOneEmpty(tech)"
                            >
                                +1
                            </button>
                        </div>
                        <div class="col-md-3">
                            <button
                                class="btn btn-sm btn-outline-success w-100"
                                (click)="fillEmpty(tech, 6)"
                            >
                                #6
                            </button>
                        </div>
                        <div class="col-md-3">
                            <button
                                class="btn btn-sm btn-outline-success w-100"
                                (click)="fillEmpty(tech, 7)"
                            >
                                #7
                            </button>
                        </div>
                        <div class="col-md-3">
                            <button
                                class="btn btn-sm btn-outline-success w-100"
                                (click)="fillEmpty(tech, 8)"
                            >
                                #8
                            </button>
                        </div>
                    </div>
                    <br *ngIf="!tech._mixplan" />
                    <div class="row" *ngIf="!tech._mixplan">
                        <div class="col-md-4">
                            <button
                                class="btn btn-outline-primary btn-sm w-100"
                                (click)="setOrdersBgColor(tech, 'bg-primary')"
                            >
                                Blue
                            </button>
                        </div>
                        <div class="col-md-4">
                            <button
                                class="btn btn-outline-warning btn-sm w-100"
                                (click)="setOrdersBgColor(tech, 'bg-warning')"
                            >
                                Orange
                            </button>
                        </div>
                        <div class="col-md-4">
                            <button
                                class="btn btn-outline-danger btn-sm w-100"
                                *ngIf="tech._ordersBgColor"
                                (click)="setOrdersBgColor(tech, '')"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <br *ngIf="!tech._mixplan" />
                    <div class="row">
                        <div class="col-md-12">
                            <button
                                class="btn btn-outline-info btn-sm w-100"
                                (click)="toggleMixplan(tech)"
                                *ngIf="!tech._mixplan"
                            >
                                Set mixplan
                            </button>
                            <button
                                class="btn btn-outline-danger btn-sm w-100"
                                (click)="toggleMixplan(tech)"
                                *ngIf="tech._mixplan"
                            >
                                Remove mixplan
                            </button>
                        </div>
                    </div>
                    <br *ngIf="!tech._mixplan" />
                    <div class="row" *ngIf="!tech._mixplan">
                        <div class="col-md-12">
                            <button
                                class="btn btn-sm"
                                [ngClass]="{
                                    'btn-outline-danger': tech.area != 1,
                                    'btn-danger': tech.area == 1
                                }"
                                (click)="changeTechArea(tech, '1')"
                            >
                                A1
                            </button>
                            <button
                                class="btn btn-sm"
                                [ngClass]="{
                                    'btn-outline-danger': tech.area != 2,
                                    'btn-danger': tech.area == 2
                                }"
                                (click)="changeTechArea(tech, '2')"
                            >
                                A2
                            </button>
                            <button
                                class="btn btn-sm"
                                [ngClass]="{
                                    'btn-outline-danger': tech.area != 3,
                                    'btn-danger': tech.area == 3
                                }"
                                (click)="changeTechArea(tech, '3')"
                            >
                                A3
                            </button>
                            <button
                                class="btn btn-sm"
                                [ngClass]="{
                                    'btn-outline-danger': tech.area != 4,
                                    'btn-danger': tech.area == 4
                                }"
                                (click)="changeTechArea(tech, '4')"
                            >
                                A4
                            </button>
                            <button
                                class="btn btn-sm"
                                [ngClass]="{
                                    'btn-outline-danger': tech.area != 5,
                                    'btn-danger': tech.area == 5
                                }"
                                (click)="changeTechArea(tech, '5')"
                            >
                                A5
                            </button>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-12">
                            <input
                                class="form-control w-100"
                                type="text"
                                [(ngModel)]="tech._remark"
                                placeholder="Remark"
                                (ngModelChange)="modelChangeFn($event, tech)"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <table class="table table-sm table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>jfs_task_id</th>
                                <th>jfs_ticket_name</th>
                                <th>jfs_task_subtype</th>
                                <th>jfs_tech_id</th>
                                <th>jfs_tech_per</th>
                                <th>address</th>
                                <th>jfs_task_timeslot</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="
                                    let order of tech._orders;
                                    let iOrders = index
                                "
                                [ngClass]="tech._ordersBgColor"
                            >
                                <td></td>
                                <td>{{ iOrders + 1 }}</td>
                                <td>{{ order.order.jfs_task_id }}</td>
                                <td>{{ order.order.jfs_ticket_name }}</td>
                                <td>{{ order.order.jfs_task_subtype }}</td>
                                <td>{{ tech.ERP_ID }}</td>
                                <td>{{ tech.pxm_per }}</td>
                                <td>{{ order.order.address }}</td>
                                <td>{{ order.order.jfs_task_timeslot }}</td>
                            </tr>
                            <tr
                                *ngFor="
                                    let emptyOrder of tech._emptyOrders;
                                    let iEmptyOrders = index
                                "
                                style="background-color: yellow"
                            >
                                <td>
                                    <button
                                        class="btn btn-sm btn-outline-danger"
                                        (click)="removeOneEmpty(tech)"
                                    >
                                        -
                                    </button>
                                </td>
                                <td>
                                    {{ countOrders(tech) + iEmptyOrders + 1 }}
                                </td>
                                <td colspan="7">Extra booking</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br /><br />
        </div>
        <!-- LIMBO -->
        <div class="row border">
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-12">
                        <strong><u>Limbo tickets</u></strong>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <table class="table table-sm table-bordered table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>jfs_task_id</th>
                            <th>jfs_ticket_name</th>
                            <th>jfs_task_subtype</th>
                            <th>jfs_tech_id</th>
                            <th>jfs_tech_per</th>
                            <th>address</th>
                            <th>jfs_task_timeslot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let limboOrder of limboOrders;
                                let iOrders = index
                            "
                            class="bg-danger"
                        >
                            <td></td>
                            <td>{{ iOrders + 1 }}</td>
                            <td>{{ limboOrder.order.jfs_task_id }}</td>
                            <td>{{ limboOrder.order.jfs_ticket_name }}</td>
                            <td>{{ limboOrder.order.jfs_task_subtype }}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>address</td>
                            <td>{{ limboOrder.order.jfs_task_timeslot }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- LIMBO -->
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')"
        >
            Close
        </button>
    </div>
</div>
