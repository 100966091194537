<div class="row">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-2">Tech type:</div>
            <div class="col-md-10">
                <select
                    class="form-control w-100"
                    [(ngModel)]="techtype"
                    [disabled]="loading || step != 'get_techs'"
                >
                    <option [ngValue]="'prf'">Prof market</option>
                    <option [ngValue]="'mas'">Mas market</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row" *ngIf="techs">
            <div class="col-md-2">Orderdate:</div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-4">
                        <input
                            type="number"
                            placeholder="DD"
                            class="form-control"
                            [(ngModel)]="date.day"
                            [disabled]="loading || step != 'get_orders'"
                        />
                    </div>
                    <div class="col-md-4">
                        <input
                            type="number"
                            placeholder="MM"
                            class="form-control"
                            [(ngModel)]="date.month"
                            [disabled]="loading || step != 'get_orders'"
                        />
                    </div>
                    <div class="col-md-4">
                        <input
                            type="number"
                            placeholder="YYYY"
                            class="form-control"
                            [(ngModel)]="date.year"
                            [disabled]="loading || step != 'get_orders'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <div class="col-md-6">
                <button
                    class="btn btn-primary w-100"
                    (click)="getTechs(techtype)"
                    [disabled]="!techtype || loading"
                    *ngIf="step == 'get_techs'"
                >
                    <i class="fas fa-spinner fa-pulse" *ngIf="loading"></i> Get
                    techs
                </button>

                <button
                    class="btn btn-primary w-100"
                    [disabled]="!date.day && !date.month && !date.year"
                    (click)="getOrders()"
                    [disabled]="loading"
                    *ngIf="step == 'get_orders'"
                >
                    <i class="fas fa-spinner fa-pulse" *ngIf="loading"></i> Get
                    orders
                </button>

                <button
                    class="btn btn-primary"
                    (click)="calculateRoutes()"
                    [disabled]="loading"
                    *ngIf="step == 'calculate_route'"
                >
                    <i class="fas fa-spinner fa-pulse" *ngIf="loading"></i>
                    Get routes
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn btn-warning"
                    (click)="restart()"
                    *ngIf="techs"
                >
                    Restart
                </button>
            </div>
        </div>
        <!-- All buttons -->
    </div>
</div>
<div class="row" *ngIf="progressbar">
    <div class="col-md-12">
        <br />
        <div
            class="progress-bar progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ progressvalue }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: progressvalue + '%' }"
        >
            {{ progressvalue }}%
        </div>
    </div>
</div>
<br />
<div class="row" *ngIf="techs">
    <div class="col-md-4">
        <table class="table table-sm table-bordered table-hover">
            <thead>
                <tr>
                    <th>Tech</th>
                    <th>Orders</th>
                    <th>Time</th>
                    <th>Distance</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let tech of techs; let i = index">
                    <tr
                        [style.background-color]="tech.color"
                        *ngIf="
                            getOrderLength(tech.orders) > 0 ||
                            step == 'get_orders'
                        "
                    >
                        <td>{{ tech.tech_name }}</td>
                        <td
                            [ngClass]="{
                                'bg-dark': getOrderLength(tech.orders) == 0
                            }"
                        >
                            <span *ngIf="getOrderLength(tech.orders)">{{
                                getOrderLength(tech.orders)
                            }}</span>
                        </td>
                        <td
                            [ngClass]="{
                                'bg-dark':
                                    !tech.driving_time || tech.driving_time == 0
                            }"
                        >
                            <span
                                *ngIf="
                                    tech.driving_time && tech.driving_time > 0
                                "
                                >{{ tech.driving_time }}h</span
                            >
                        </td>
                        <td
                            [ngClass]="{
                                'bg-dark':
                                    !tech.driving_distance ||
                                    tech.driving_distance == 0
                            }"
                        >
                            <span
                                *ngIf="
                                    tech.driving_distance &&
                                    tech.driving_distance > 0
                                "
                                >{{ tech.driving_distance }}km</span
                            >
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-md-8" *ngIf="mapData">
        <app-techroutes-map [mapData]="mapData"></app-techroutes-map>
    </div>
</div>
