import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SubcoService {
    private API_URL = environment.apiUrl;
    constructor(private http: HttpClient) {}

    getAll(): Observable<any> {
        return this.http.get(this.API_URL + 'subco/all', {
            responseType: 'json',
        });
    }

    getById(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'subco/subco/' + id, {
            responseType: 'json',
        });
    }

    add(subcoObject: any): Observable<any> {
        return this.http.post(this.API_URL + 'subco/new', subcoObject);
    }

    delete(id: any): Observable<any> {
        return this.http.delete(this.API_URL + 'subco/delete/' + id, {
            responseType: 'json',
        });
    }

    edit(subcoObject: any): Observable<any> {
        return this.http.post(this.API_URL + 'subco/update', subcoObject, {
            responseType: 'json',
        });
    }
}
