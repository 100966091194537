import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dispatch-modal-tools',
    templateUrl: './dispatch-modal-tools.component.html',
    styleUrls: ['./dispatch-modal-tools.component.css'],
})
export class DispatchModalToolsComponent implements OnInit {
    @Output() removeAllTechs = new EventEmitter();
    @Output() removeActiveTech = new EventEmitter();
    constructor(
        public activeModal: NgbActiveModal,
        private storage: DispatchStorageService
    ) {}

    ngOnInit(): void {}

    clearLocalStorage() {
        this.storage.delTechs();
        this.storage.delActiveTech();
        localStorage.removeItem('dispatch_orderCounter');
        localStorage.removeItem('dispatch_planDate');
        localStorage.removeItem('dispatch_uploadDate');
        this.storage.delOrders();

        this.removeAllTechs.emit(true);
        this.removeActiveTech.emit(true);
    }
}
