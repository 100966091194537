<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-map-marked-alt"></i> Orders</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-3">
            Planning date:<br />{{ planDate }}<br /><br />Upload date:<br />{{
                uploadDate
            }}
            <div class="alert alert-danger" *ngIf="oldData">
                This is old data, clear storage to fetch new data!
            </div>
        </div>
        <div class="col-md-9">
            <table class="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Area</th>
                        <th>Install</th>
                        <th>Repair</th>
                        <th>Move</th>
                        <th>AddDecoder</th>
                        <th>Total</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let area of areaCount; let i = index">
                        <td>{{ area.area }}</td>
                        <td>{{ area.install }}</td>
                        <td>{{ area.repair }}</td>
                        <td>{{ area.move }}</td>
                        <td>{{ area.adddecoder }}</td>
                        <td>{{ area.total }}</td>
                        <td>
                            <button
                                class="btn btn-sm btn-outline-success"
                                (click)="addToMap(i)"
                                *ngIf="!area.addedToMap"
                                [disabled]="loading"
                            >
                                <i
                                    class="fas fa-map-marked-alt"
                                    *ngIf="!loading"
                                ></i>
                                <i
                                    class="fas fa-spinner fa-pulse"
                                    *ngIf="loading"
                                ></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')"
        >
            Close
        </button>
    </div>
</div>
