<div class="row">
    <div class="col-md-3" *ngIf="!popupDetails">
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
        <div class="alert alert-success" *ngIf="successMessage">
            {{ successMessage }}
        </div>
        <div class="alert alert-info">
            <i class="fas fa-map-marker-alt"></i> Markers
            <hr />
            Click on one to see the details appear here.<br /><br />
            <i class="fas fa-exclamation-circle"></i> Imports
            <hr />
            <strong
                >If client is currently with another provider, these orders
                can't be moved forward, so don't forget to ask this to the
                customer</strong
            ><br /><br />
            <i class="fas fa-info-circle"></i> Colors
            <hr />
            <table class="table table-sm">
                <tr>
                    <td>
                        <i
                            class="fas fa-map-marker-alt"
                            style="color: #263aa9"
                        ></i>
                    </td>
                    <td>Copper order</td>
                </tr>
                <tr>
                    <td>
                        <i
                            class="fas fa-map-marker-alt"
                            style="color: #fcff1e"
                        ></i>
                    </td>
                    <td>Fiber order</td>
                </tr>
                <tr>
                    <td>
                        <i
                            class="fas fa-map-marker-alt"
                            style="color: #ff1eb4"
                        ></i>
                    </td>
                    <td>Fis order</td>
                </tr>
                <tr>
                    <td>
                        <i class="fas fa-map-marker-alt" style="color: red"></i>
                    </td>
                    <td>Selected order</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="col-md-3" *ngIf="popupDetails">
        <table class="table table-sm table-bordered">
            <thead>
                <tr>
                    <th>Variable</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>City</td>
                    <td>
                        {{ popupDetails.element.zip_city }}
                        <small>({{ popupDetails.element.lex }})</small>
                    </td>
                </tr>
                <tr>
                    <td>Original appointment date</td>
                    <td>{{ popupDetails.element.dueDate }}</td>
                </tr>
                <tr>
                    <td>WB type</td>
                    <td>{{ popupDetails.element.prev_wb_type }}</td>
                </tr>
                <tr>
                    <td>Jobcode + Duration</td>
                    <td>
                        {{ popupDetails.element.job_code }} ({{
                            popupDetails.element.duration
                        }})
                    </td>
                </tr>
                <tr *ngIf="popupDetails.element.PhoneNumber != 'None'">
                    <td>NA</td>
                    <td>
                        {{ popupDetails.element.PhoneNumber }}
                    </td>
                </tr>
                <tr *ngIf="popupDetails.element.CircuitID != 'None'">
                    <td>CID</td>
                    <td>
                        {{ popupDetails.element.CircuitID }}
                    </td>
                </tr>
                <tr>
                    <td>Customer Name</td>
                    <td>{{ popupDetails.element.customer_name }}</td>
                </tr>
                <tr>
                    <td>Customer Phone</td>
                    <td>{{ popupDetails.element.contact }}</td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col-md-6">
                <button
                    class="btn btn-success w-100 h-100"
                    (click)="
                        markerEvent(
                            'put',
                            popupDetails.element.id,
                            popupDetails.marker
                        )
                    "
                >
                    <i class="far fa-check-circle"></i> Ok
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn btn-danger w-100 h-100"
                    (click)="
                        markerEvent(
                            'hide',
                            popupDetails.element.id,
                            popupDetails.marker
                        )
                    "
                >
                    <i class="fas fa-times-circle"></i> Not ok
                </button>
            </div>
        </div>
        <br />
        <div class="alert alert-info">
            <i class="far fa-check-circle"></i> Ok
            <hr />
            <ul>
                <li>
                    <strong
                        >Don't forget to ask if it is an import, IMPORTS ARE NOT
                        POSSIBLE TO CHANGE DATE!!</strong
                    >
                </li>
                <li>Removes the marker from the map</li>
                <li>Sends a mail to coaching</li>
                <li>
                    Coaching asks dispatch to put the order on your planning
                </li>
            </ul>
            <i class="fas fa-times-circle"></i> Not ok
            <hr />
            <ul>
                <li>Removes the marker from the map</li>
            </ul>
        </div>
    </div>
    <div class="col-md-9">
        <div
            class="map map-container"
            id="mapMeetingmapMain"
            class="map-container"
            [style.height.px]="screenHeight"
            [style.width.px]="screenWidth"
        ></div>
    </div>
</div>
