import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TechService } from 'src/app/_services/tech.service';

@Component({
  selector: 'app-sms-techs',
  templateUrl: './sms-techs.component.html',
  styleUrls: ['./sms-techs.component.css']
})
export class SmsTechsComponent implements OnInit {

  @Output() filteredTechsEvent = new EventEmitter<any>();

  allTechs: any[] = [];
  filteredTechs: any[] = [];
  loadingTechs:boolean = true;

  techAreas: any[] = [];
  selectedAreas: any[] = [];
  techSkills: any[] = [];
  selectedSkills: any[] = [];
  selectedTechs: any[] = [];

  skills: any[] = [
    {readable: 'FIS', skill: 'skill_fiber_fis', selected: false, inTechArray: false},
    {readable: 'Fiber install', skill: 'skill_fiber_install', selected: false, inTechArray: false},
    {readable: 'Mass', skill: 'skill_pxm', selected: false, inTechArray: false},
    {readable: 'Prof', skill: 'skill_proff', selected: false, inTechArray: false},
    {readable: 'Full', skill: 'skill_sct_full', selected: false, inTechArray: false},
    {readable: 'Light', skill: 'skill_sct_light', selected: false, inTechArray: false},
    {readable: 'Academy', skill: 'skill_hp', selected: false, inTechArray: false},
    {readable: 'Coaching', skill: 'skill_thermo', selected: false, inTechArray: false},
    {readable: 'Aide', skill: 'skill_acn_aide', selected: false, inTechArray: false},
    {readable: 'Construction', skill: 'skill_acn_cop_construction', selected: false, inTechArray: false},
    {readable: 'Provisioning', skill: 'skill_acn_cop_prov', selected: false, inTechArray: false},
    {readable: 'Repair', skill: 'skill_acn_cop_repair', selected: false, inTechArray: false},
    {readable: 'Facade', skill: 'skill_acn_fib_facade', selected: false, inTechArray: false},
    {readable: 'Underground', skill: 'skill_acn_fib_underground', selected: false, inTechArray: false},
    {readable: 'Machine', skill: 'skill_acn_machine', selected: false, inTechArray: false},

    
  ];

  constructor(private techService: TechService) { 
    
  }

  ngOnInit(): void {

    
    this.techService.getAll().subscribe((response) => {
      this.allTechs = response;

      // Loop all the techs and set some variables
      this.allTechs.forEach((tech) => {
        if( !this.techAreas.includes(tech.area)){
          this.techAreas.push(tech.area);
          //this.selectedAreas.push(tech.area);
        }
      });
      this.techAreas.sort();
      this.selectedAreas.sort();

      this.setTechArraySkills(this.allTechs);
      this.filteredTechs = this.allTechs;
      
      this.loadingTechs = false;
    });
  }

  setTechArraySkills(techs:any[]){

    // First reset the original array
    for (let i = 0; i < this.skills.length; i++){
      this.skills[i].inTechArray = false;
    }

    techs.forEach((tech) => {
      this.skills.forEach((s) => {
        if( tech[s.skill]){
          if(tech[s.skill] == 1){
            s.inTechArray = true;
          }
        }
      });
    });

  }

  areaClick(area:any){
    this.selectedSkills = [];
    this.selectedTechs = [];
    this.setSelectOnFilteredTechList();
    if( this.selectedAreas.includes(area)){
      const index = this.selectedAreas.indexOf(area);
      this.selectedAreas.splice(index, 1);
    }else{
      this.selectedAreas.push(area);
    }
    this.filteredTechsEvent.emit(this.selectedTechs);
  }

  buClick(region:any){
    this.selectedSkills = [];
    this.selectedTechs = [];
    this.setSelectOnFilteredTechList();
    if( region == 'flandres'){
      this.selectedAreas = [1, 2];
    }else{
      this.selectedAreas = [3,4,5];
    }
    this.filteredTechsEvent.emit(this.selectedTechs);
  }

  skillClick(skill:string){
    const indexAll = this.selectedSkills.indexOf('ALL');
    if( skill != 'ALL' ){
      if( this.selectedSkills.includes(skill)){
        const index = this.selectedSkills.indexOf(skill);
        this.selectedSkills.splice(index, 1);
        if( indexAll >= 0 ){
          const index = this.selectedSkills.indexOf('ALL');
          this.selectedSkills.splice(index,1);
        }
      }else{
        this.selectedSkills.push(skill);
      }
    }else{
      if( indexAll >= 0 ){
        this.selectedSkills = [];
      }else{
        this.selectedSkills = ['ALL'];
        this.skills.forEach((element) => {
          this.selectedSkills.push(element.skill);
        })
      }
    }
    
    // Get the selected techs
    this.selectedTechs = this.allTechs.filter((tech) => {
      // Check for techs in the selected areas
      let areaCheck = false;
      const areaCheckIndex = this.selectedAreas.indexOf(tech.area);
      if( areaCheckIndex >= 0 ){
        areaCheck = true;
      }

      // Check if the tech has one of the selected skills
      let skillCheck = false;
      this.selectedSkills.forEach((skill) => {
        if( tech[skill] == '1' ){
          skillCheck = true;
        }
      });

      let toReturn = false;
      if( areaCheck && skillCheck ){
        toReturn = true;
      }
      return toReturn;
    });

    this.setSelectOnFilteredTechList(); 
    this.filteredTechsEvent.emit(this.selectedTechs);

  }

  setSelectOnFilteredTechList(){
    // Loop trough all the filtered techs and check if we have a selected tech
    let selectedTechIds: any[] = [];
    this.selectedTechs.forEach((tech) => {
      selectedTechIds.push(tech.id);
    });

    for (let i = 0; i < this.filteredTechs.length; i++){
      if( selectedTechIds.includes(this.filteredTechs[i].id)){
        this.filteredTechs[i].setForSMS = true;
      }else{
        this.filteredTechs[i].setForSMS = false;
      }
      
    }
  }

  areaSelectedCheck(area:string){
    if( this.selectedAreas.includes(area)){
      return true;
    }else{
      return false;
    }
  }


  skillSelectedCheckTech(tech:any){
    // Loop all the skills and check if we have one of them on 1 inside the tech object
    let hasOneSkill = false;
    this.selectedSkills.forEach((skill) => {
      if( tech[skill] == 1){
        hasOneSkill = true;
      }
    });
    return hasOneSkill;
  }

  skillSelectedCheck(skill:string){
    if( this.selectedSkills.includes(skill)){
      return true;
    }else{
      return false;
    }
  }

  toggleSingleTech(t:any){
    // First check if he is in the selectedTechs array
    let isSelectedTech = false;
    const index = this.selectedTechs.findIndex((tech) => tech.id === t.id);

    if (index !== -1) {
      this.selectedTechs.splice(index, 1);
    }else{
      t.setForSMS = true;
      this.selectedTechs.push(t);
    }

    this.setSelectOnFilteredTechList();
  }

  

  

}
