import { SmsService } from './../../_services/sms.service';
import { Component, OnInit } from '@angular/core';
import { TechService } from '../../_services/tech.service';

@Component({
    selector: 'app-sms-gateway',
    templateUrl: './sms-gateway-v2.component.html',
    styleUrls: ['./sms-gateway-v2.component.css'],
})
export class SmsGatewayV2Component implements OnInit {
    filteredTechs: any[] = [];

    constructor() {}

    ngOnInit() {
        /*
        this.techService.workingToday().subscribe((response) => {
                this.workingToday = response;
            });
        */
    }

    filteredTechsEvent(techs: any) {
        this.filteredTechs = techs;
    }
}
