<div class="card h-90">
    <div class="card-header">
        <div class="alert alert-info">
            A{{ tech.area }} - {{ tech.tech_name | titlecase }}
        </div>
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'info' }"
                    (click)="changeTab('info')"
                    ><i class="fa fa-user"></i> Tech info</a
                >
            </li>
            <li class="nav-item" *ngIf="userLoginMethod != 'TECH'">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'contract' }"
                    (click)="changeTab('contract')"
                    ><i class="fa fa-file"></i> Contract</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'pxm' }"
                    (click)="changeTab('pxm')"
                    ><i class="fa fa-building"></i> PXM Info</a
                >
            </li>
            <li class="nav-item" *ngIf="userLoginMethod != 'TECH'">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'jfs' }"
                    (click)="changeTab('jfs')"
                    ><i class="fa fa-car"></i> JFS Info</a
                >
            </li>
            <li class="nav-item" *ngIf="userLoginMethod != 'TECH'">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'skillz' }"
                    (click)="changeTab('skillz')"
                    ><i class="fa fa-tachometer-alt"></i> Skillz</a
                >
            </li>
            <li class="nav-item" *ngIf="tech.geocode">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'map' }"
                    (click)="changeTab('map')"
                    ><i class="fa fa-map-marker-alt"></i> Location</a
                >
            </li>
            <li class="nav-item" *ngIf="userLoginMethod != 'TECH'">
                <a
                    class="nav-link text-dark"
                    [ngClass]="{ active: showTab == 'site_stats' }"
                    (click)="changeTab('site_stats')"
                    ><i class="fa fa-chart-bar"></i> Site stats</a
                >
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <table
                    class="table table-hover table-bordered text-left"
                    *ngIf="showTab == 'info'">
                    <tbody>
                        <tr>
                            <td><i class="fa fa-user"></i> Name</td>
                            <td>{{ tech.tech_name | titlecase }}</td>
                        </tr>
                        <tr>
                            <td><i class="fa fa-envelope"></i> Mail</td>
                            <td>
                                <a href="mailto:{{ tech.tech_mail }}">{{
                                    tech.tech_mail
                                }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <i class="fa fa-mobile-alt"></i> Phone (work)
                            </td>
                            <td>
                                <a href="tel:{{ tech.phone_work_clean }}">{{
                                    tech.tech_tel_work
                                }}</a>
                            </td>
                        </tr>
                        <tr *ngIf="userLoginMethod != 'TECH'">
                            <td>
                                <i class="fa fa-mobile-alt"></i> Phone (private)
                            </td>
                            <td>
                                <a href="tel:{{ tech.phone_private_clean }}">{{
                                    tech.tech_tel_private
                                }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td><i class="fa fa-map-marker-alt"></i> Adress</td>
                            <td>
                                <span *ngIf="userLoginMethod != 'TECH'"
                                    >{{ tech.tech_street_number }}<br /></span
                                >{{ tech.tech_zip_city }}
                            </td>
                        </tr>
                        <tr *ngIf="userLoginMethod != 'TECH'">
                            <td>
                                <i class="fa fa-birthday-cake"></i> Birthday
                            </td>
                            <td>{{ tech.tech_birthday }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <table
            class="table table-hover table-bordered text-left"
            *ngIf="showTab == 'contract'">
            <tbody>
                <tr>
                    <td><i class="fa fa-file"></i> Contract</td>
                    <td>{{ tech.firm_status }}</td>
                </tr>
                <tr *ngIf="tech.firm_status == 'Subco'">
                    <td><i class="fa fa-building"></i>Firm</td>
                    <td>{{ subco?.firm_name }}</td>
                </tr>

                <tr
                    *ngIf="subco?.main && tech.firm_status == 'Subco'"
                    class="bg-warning">
                    <td>
                        <i class="fa fa-funnal-dollar"></i> "Subco for Subco"
                    </td>
                    <td>
                        Deze technieker werkt op zelfstandige basis onder
                        <strong>{{ subco?.main?.firm_name }}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
        <table
            class="table table-hover table-bordered text-left"
            *ngIf="showTab == 'pxm'">
            <tbody>
                <tr>
                    <td><i class="fa fa-id-card-alt"></i> PER</td>
                    <td>{{ tech.pxm_per }}</td>
                </tr>
                <tr *ngIf="userLoginMethod != 'TECH'">
                    <td><i class="fa fa-chart-line"></i> Load</td>
                    <td>{{ tech.pxm_load }}</td>
                </tr>
                <tr *ngIf="userLoginMethod != 'TECH'">
                    <td><i class="fa fa-flag-checkered"></i> Start NET</td>
                    <td>{{ tech.pxm_start_net }}</td>
                </tr>
                <tr>
                    <td><i class="fa fa-calendar-alt"></i> Calendar</td>
                    <td>{{ tech.pxm_calendar }}</td>
                </tr>
                <tr *ngIf="userLoginMethod != 'TECH'">
                    <td><i class="fa fa-key"></i> LDC Key ID</td>
                    <td>{{ tech.pxm_ldc_key_id }}</td>
                </tr>
                <tr *ngIf="userLoginMethod != 'TECH'">
                    <td><i class="fa fa-laptop"></i> WFM Serial</td>
                    <td>{{ tech.pxm_wfm_serial }}</td>
                </tr>
            </tbody>
        </table>
        <table
            class="table table-hover table-bordered text-left"
            *ngIf="showTab == 'jfs'">
            <tbody>
                <tr>
                    <td><i class="fa fa-car"></i> Car type</td>
                    <td>{{ tech.jfs_car }}</td>
                </tr>
                <tr>
                    <td><i class="fa fa-taxi"></i> License plate</td>
                    <td>{{ tech.jfs_license_plate }}</td>
                </tr>
                <tr>
                    <td><i class="fa fa-truck"></i> UPS point</td>
                    <td>{{ tech.jfs_ups }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table text-left" *ngIf="showTab == 'skillz'">
            <tbody>
                <tr>
                    <td>
                        <table class="table table-sm table-bordered">
                            <ng-container *ngFor="let skill of skills">
                                <tr *ngIf="tech[skill.name] == '1'">
                                    <td
                                        style="width: 100%"
                                        [ngStyle]="{
                                            'background-color': skill.color
                                        }">
                                        {{ skill.text }}
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <table
            class="table table-bordered"
            *ngIf="showTab == 'map' && tech.geocode">
            <tbody>
                <tr>
                    <td style="height: 500px">
                        <app-techmap [tech]="tech"></app-techmap>
                    </td>
                </tr>
            </tbody>
        </table>
        <table
            class="table table-hover table-bordered text-left"
            *ngIf="showTab == 'site_stats'">
            <tbody>
                <tr>
                    <td>Username</td>
                    <td>{{ tech.username | titlecase }}</td>
                </tr>
                <tr>
                    <td>Function</td>
                    <td>{{ tech.function }}</td>
                </tr>
                <tr>
                    <td>Area</td>
                    <td>{{ tech.area }}</td>
                </tr>
                <tr>
                    <td>Total logins</td>
                    <td>{{ tech.login_attempts }}</td>
                </tr>
                <tr>
                    <td>Last login</td>
                    <td>{{ tech.last_login }}</td>
                </tr>
                <tr>
                    <td>API calls</td>
                    <td>{{ tech.api_calls }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
