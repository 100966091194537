import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';
import { TechService } from '../../_services/tech.service';

@Component({
    selector: 'app-wiw-techs',
    templateUrl: './wiw-techs.component.html',
    styleUrls: ['./wiw-techs.component.css'],
})
export class WiwTechsComponent implements OnInit {
    // This monitors the search input #techSearchInput
    @ViewChild('techSearchInput', { static: true })
    techSearchInput!: ElementRef;

    @Output() techSelectedEvent = new EventEmitter<any>();
    allTechs?: any;
    errorMessage: boolean = false;
    loading: boolean = false;

    pageNumber: number = 1;
    totalPages: number = 0;
    allPages: number = 0;

    searchString: string = 'false';

    stopSearch: boolean = false;
    maxInputLength: number = 2;
    searchText = '';

    constructor(private techService: TechService) {}

    ngOnInit(): void {
        this.getTechs();
        fromEvent(this.techSearchInput.nativeElement, 'keyup')
            .pipe(
                // get value
                map((event: any) => {
                    return event.target.value;
                }),
                // if character length greater then 2
                filter((res) => {
                    if (res.length > 0) {
                        return true;
                    } else {
                        this.getTechs();
                        return false;
                    }
                }),

                // Time in milliseconds between key events
                debounceTime(500),

                // If previous query is diffent from current
                distinctUntilChanged()

                // subscription for response
            )
            .subscribe((text: string) => {
                this.loading = true;

                this.techService.getPaginated(1, text).subscribe((res) => {
                    console.log('res', res);
                    this.loading = false;
                    this.processData(res);
                });
            });
    }

    getTechs() {
        this.loading = true;
        this.techService
            .getPaginated(this.pageNumber, this.searchString)
            .subscribe((data) => {
                this.processData(data);
                this.loading = false;
            });
        return true;
    }

    // Processes the return data from api
    processData(data: any) {
        this.allTechs = data.techs;
        this.totalPages = data.total_pages;
        this.pageNumber = data.current_page;
        this.allPages = data.total_items;
        if (this.allTechs.length == 0) {
            this.stopSearch = true;
            this.maxInputLength = this.searchString.length;
        } else {
            this.stopSearch = false;
            this.maxInputLength = 100;
        }
    }

    techSelected(value: any) {
        this.techSelectedEvent.emit(value);
    }

    changePage(data: any) {
        this.pageNumber = data;
        this.getTechs();
    }
}
