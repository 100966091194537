<style>
    .has-error {
        border: 1px solid red;
        border-radius: 4px;
    }
</style>
<div class="row" ng-controller="wiwTechEditCtrl">
    <div class="col-md-1">
        <a class="btn btn-secondary" [routerLink]="['/admin/wiw/allsubcos']"
            ><i class="far fa-hand-point-left"></i> Back</a
        >
    </div>
    <div class="col-md-10">
        <div class="alert alert-info">
            <i class="fas fa-plus-square"></i> Edit new subco
        </div>

        <div class="alert alert-success" *ngIf="successMessage.message">
            {{ successMessage.message }}
        </div>

        <div class="alert alert-danger" *ngIf="errorMessage.message">
            {{ errorMessage.message }}
        </div>

        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-2">
                        <label for="techPer"
                            ><i class="fas fa-building"></i> Firm name</label
                        >
                    </div>
                    <div class="col-md-10">
                        <input
                            type="text"
                            class="form-control"
                            formControlName="firm_name"
                            placeholder="Firm name"
                            required
                            [ngClass]="{
                                'is-invalid':
                                    !editForm.controls['firm_name'].valid
                            }"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-md-2">
                        <label
                            ><i class="fas fa-user-shield"></i> Main subco
                            ("N+1")</label
                        >
                    </div>
                    <div class="col-md-10">
                        <select
                            class="form-control"
                            [(ngModel)]="mainSubco"
                            formControlName="main"
                        >
                            <option
                                *ngFor="let value of allSubcos"
                                [ngValue]="value.id"
                            >
                                {{ value.firm_name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-md-2">
                        <label><i class="fas fa-users-cog"></i> Contacts</label
                        ><br />
                        <button
                            class="btn btn-sm btn-primary"
                            (click)="addContact()"
                        >
                            Add contact
                        </button>
                    </div>

                    <div class="col-md-10">
                        <!-- DIFFICULT SHIT THIS ONE -->
                        <!-- https://netbasal.com/angular-reactive-forms-the-ultimate-guide-to-formarray-3adbe6b0b61a -->
                        <!-- https://www.concretepage.com/angular/angular-formarray-validation#:~:text=FormArray%20tracks%20the%20value%20and,FormControl%20or%20FormGroup%20are%20validated. -->
                        <div class="row">
                            <div class="col-md-1">&nbsp;</div>
                            <div class="col-md-3 font-weight-bold">
                                Full name
                            </div>
                            <div class="col-md-2 font-weight-bold">
                                Firstname
                            </div>
                            <div class="col-md- font-weight-bold">Phone</div>
                            <div class="col-md-3 font-weight-bold">Mail</div>
                        </div>
                        <hr />
                        <ng-container formArrayName="contacts">
                            <div
                                class="row"
                                *ngFor="let _ of contacts.controls; index as i"
                                [formGroupName]="i"
                            >
                                <div class="col-md-1">#{{ i + 1 }}.</div>
                                <div class="col-md-3">
                                    <input
                                        class="form-control w-100"
                                        formControlName="name"
                                        [ngClass]="{
                                            'is-invalid':
                                                !contacts.controls[i].valid
                                        }"
                                    />
                                </div>
                                <div class="col-md-2">
                                    <input
                                        class="form-control w-100"
                                        formControlName="firstname"
                                        [ngClass]="{
                                            'is-invalid':
                                                !contacts.controls[i].valid
                                        }"
                                    />
                                </div>
                                <div class="col-md-2">
                                    <input
                                        class="form-control w-100"
                                        formControlName="phone"
                                        [ngClass]="{
                                            'is-invalid':
                                                !contacts.controls[i].valid
                                        }"
                                    />
                                </div>
                                <div class="col-md-3">
                                    <input
                                        class="form-control w-100"
                                        formControlName="mail"
                                        [ngClass]="{
                                            'is-invalid':
                                                !contacts.controls[i].valid
                                        }"
                                    />
                                </div>
                                <div class="col-md-1">
                                    <button
                                        class="btn btn-sm btn-danger"
                                        (click)="deleteContact(i)"
                                    >
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                                <br /><br />
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">&nbsp;</div>
                <div class="col-md-10">
                    <button
                        type="submit"
                        class="btn btn-success"
                        [disabled]="loading || !editForm.valid"
                    >
                        <i class="far fa-save" *ngIf="!loading"></i
                        ><i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
                        Save Subco
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
