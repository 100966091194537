import {
    Component,
    OnInit,
    HostListener,
    Input,
    OnChanges,
    SimpleChange,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Component({
    selector: 'app-mm-settings-map',
    templateUrl: './mm-settings-map.component.html',
    styleUrls: ['./mm-settings-map.component.css'],
})
export class MmSettingsMapComponent implements OnInit {
    @Input() networkPolygon: any;

    map: any;
    marker: any;
    style = 'mapbox://styles/mapbox/streets-v11';
    allTechs: any;

    public screenWidth: any;
    public screenHeight: any;

    constructor() {}

    ngOnDestroy() {
        this.map.remove();
        this.map = null;
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 100;

        this.map = new mapboxgl.Map({
            container: 'mapSettingsMap',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 7,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
        });

        this.map.addControl(new mapboxgl.NavigationControl());

        this.map.on('load', () => {
            this.map.resize();
        });
    }

    ngOnChanges(changes: SimpleChange) {
        if (this.map !== undefined) {
            this.loadMapContent(this.networkPolygon);
        }
    }

    loadMapContent(networkPolygon: any) {
        var polygonData = {
            type: 'FeatureCollection',
            features: networkPolygon,
        };

        this.map.addSource('networks', {
            type: 'geojson',
            data: polygonData,
        });

        // Add a new layer to visualize the polygon.
        this.map.addLayer({
            id: 'maine',
            type: 'fill',
            source: 'networks', // reference the data source
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['==', ['get', 'totalorders'], 0],
                    '#343a40',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#f0ad4e',
                    ['==', ['get', 'mmopen'], '1'],
                    '#5cb85c',
                    ['==', ['feature-state', 'statuschange'], 1],
                    '#5cb85c',
                    '#d9534f',
                ],
                'fill-opacity': 1,
            },
        });
        // Add a black outline around the polygon.
        this.map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'networks',
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 0.2,
            },
        });
    }

    highlightPolygon(event: any, todo: boolean) {
        this.map.setFeatureState(
            { source: 'networks', id: event },
            { hover: todo }
        );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 100;
    }

    onCheckboxChangeEvent(event: any, newStatus: any) {
        console.log(event);
        var status = this.networkPolygon[event].mm_open;
        this.networkPolygon[event].mm_open = newStatus;
        this.map.setFeatureState(
            { source: 'networks', id: event },
            { statuschange: newStatus }
        );
    }
}
