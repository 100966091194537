<div class="row" *ngIf="!uploadStart">
    <div class="col-md-8">
        <label class="btn btn-default p-0">
            <input type="file" multiple (change)="selectFiles($event)" />
        </label>
    </div>

    <div class="col-md-4">
        <button
            class="btn btn-success btn-sm"
            [disabled]="!selectedFiles"
            (click)="uploadFiles()"
        >
            Upload
        </button>
    </div>
</div>
<div class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }}
</div>

<table class="table table-sm table-bordered" *ngIf="uploadStart">
    <thead>
        <tr>
            <th>Filename</th>
            <th>Progress</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let progressInfo of progressInfos">
            <td>{{ progressInfo.fileName }}</td>
            <td>
                <div
                    class="progress-bar progress-bar-info"
                    role="progressbar"
                    attr.aria-valuenow="{{ progressInfo.value }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: progressInfo.value + '%' }"
                >
                    {{ progressInfo.value }}%
                </div>
            </td>
        </tr>
    </tbody>
</table>
