<div class="row">
    <div class="col-md-5">Gedaan op</div>
    <div class="col-md-7">
        <form class="form-inline">
            <div class="form-group">
                <div class="input-group">
                    <input
                        class="form-control"
                        placeholder="dd-mm-yyyy"
                        name="dp"
                        [(ngModel)]="modelDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        (ngModelChange)="changeModel($event)"
                    />
                    <div class="input-group-append">
                        <button
                            class="btn btn-outline-secondary"
                            (click)="d.toggle()"
                            type="button"
                        >
                            <i class="far fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Gedaan door</div>
    <div class="col-md-7">
        <select
            class="form-control w-100"
            [(ngModel)]="modelUser"
            (ngModelChange)="changeModel($event)"
        >
            <option *ngFor="let user of allUsers | kpiverslag" [ngValue]="user">
                {{ user.nice_name }}
            </option>
        </select>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Technieker</div>
    <div class="col-md-7">
        <div class="input-group">
            <input
                id="typeahead-basic"
                type="text"
                class="form-control"
                [(ngModel)]="modelTech"
                [ngbTypeahead]="search"
                (ngModelChange)="changeModel($event)"
                (click)="changeModel($event)"
            />
            <div class="input-group-append">
                <button
                    class="btn btn-outline-danger"
                    (click)="modelTech = ''"
                    type="button"
                >
                    <i class="far fa-trash-alt"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Reden</div>
    <div class="col-md-7">
        <select class="form-control w-100">
            <option *ngFor="let reden of redenen">{{ reden }}</option>
        </select>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Maand KPI scores</div>
    <div class="col-md-7">
        <select class="form-control w-100" [(ngModel)]="modelMaandKPIScores">
            <option *ngFor="let maand of maanden; let i = index" [ngValue]="i">
                {{ maand }}
            </option>
        </select>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Technieker status</div>
    <div class="col-md-7">
        <select class="form-control w-100">
            <option *ngFor="let status of techstatussen">{{ status }}</option>
        </select>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">Technieker KPI scores</div>
    <div class="col-md-7">
        <table class="table table-sm table-bordered">
            <tr>
                <td style="width: 70%">YTD</td>
                <td>
                    <input type="number" class="form-control w-100" />
                </td>
            </tr>
            <tr>
                <td>Score {{ maanden[getMonth(3)] }}</td>
                <td><input type="number" class="form-control w-100" /></td>
            </tr>
            <tr>
                <td>Score {{ maanden[getMonth(2)] }}</td>
                <td><input type="number" class="form-control w-100" /></td>
            </tr>
            <tr>
                <td>Score {{ maanden[getMonth(1)] }}</td>
                <td><input type="number" class="form-control w-100" /></td>
            </tr>
            <tr>
                <td>Score {{ maanden[modelMaandKPIScores] }}</td>
                <td><input type="number" class="form-control w-100" /></td>
            </tr>
        </table>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">KPI Scores</div>
    <div class="col-md-7">
        <span *ngIf="!modelKPIScores.length">Nog geen scores in de lijst</span>
        <table class="table table-sm table-bordered">
            <tr *ngFor="let kpi of modelKPIScores; let i = index">
                <td>{{ kpi.kpi.name }}</td>
                <td>
                    <button
                        class="btn btn-outline-danger btn-sm w-100"
                        type="button"
                        (click)="deleteKPIScore(i)"
                    >
                        <i class="far fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-5">KPI Scores toevoegen</div>
    <div class="col-md-7">
        <table class="table table-sm table-bordered w-100">
            <tr>
                <td>KPI</td>
                <td>
                    <select class="form-control" [(ngModel)]="modelTempKPI.kpi">
                        <option
                            *ngFor="let kpi of allKPI; let i = index"
                            [ngValue]="kpi"
                        >
                            {{ kpi.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Jaargemiddelde</td>
                <td>
                    <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="modelTempKPI.jaargemiddelde"
                    />
                </td>
            </tr>
            <tr>
                <td>Score {{ maanden[getMonth(1)] }}</td>
                <td>
                    <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="modelTempKPI.score"
                    />
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <textarea
                        class="form-control w-100"
                        [(ngModel)]="modelTempKPI.text"
                    ></textarea>
                </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>
                    <button
                        class="btn btn-sm btn-outline-primary w-100"
                        (click)="addKPIScore()"
                    >
                        Voeg kpi toe
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>
