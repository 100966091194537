import { SubcoService } from './../../../../_services/subco.service';
import { Component, OnInit } from '@angular/core';
import { TechService } from '../../../../_services/tech.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-admin-wiw-alltechs-edit',
    templateUrl: './admin-wiw-alltechs-edit.component.html',
    styleUrls: ['./admin-wiw-alltechs-edit.component.css'],
})
export class AdminWiwAlltechsEditComponent implements OnInit {
    formFields: any = {
        area: { type: 'select', values: ['1', '2', '3', '4', '5'] },
        area_sub: {
            type: 'select',
            values: ['1.1', '1.2', '2.1', '2.2', '3', '4', '5'],
        },
        firm_status: { type: 'select', values: ['Payroll', 'Subco'] },
        subco_id: { type: 'subcos' },
        firstname: { type: 'text', placeholder: 'Firstname', required: true },
        lastname: { type: 'text', placeholder: 'Lastname', required: true },
        pxm_calendar: {
            type: 'select',
            values: [
                'C1DWAA',
                'C1DEAA',
                'C3DAAA',
                'C3DEAA',
                'A3CALENDAR',
                'A4CALENDAR',
                'A5CALENDAR',
                'C6DAAA_AUTO_GEN',
            ],
        },
        pxm_load: {
            type: 'select',
            values: ['0.6', '0.7', '0.8', '0.9', '1.0', '1.1', '1.2', '1.3'],
        },
        tech_street_number: {
            type: 'text',
            placeholder: 'Street + Number',
            required: true,
        },
        tech_zip_city: {
            type: 'text',
            placeholder: 'Zip + City',
            required: true,
        },
        tech_tel_work: {
            type: 'text',
            placeholder: '0456/12.34.56',
            required: true,
        },
        tech_tel_private: { type: 'text', placeholder: '0456/12.34.56' },
        tech_birthday: { type: 'text', placeholder: 'DD/MM/YYYY' },
        jfs_ups: { type: 'text', placeholder: 'JFS UPS Point' },
        jfs_car: { type: 'text', placeholder: 'Car type' },
        jfs_license_plate: { type: 'text', placeholder: 'X-XXX-XXX' },
        skill_pxm: { type: 'checkbox', hidden: true },
        skill_proff: { type: 'checkbox', hidden: true },
        skill_sct_full: { type: 'checkbox', hidden: true },
        skill_sct_light: { type: 'checkbox', hidden: true },
        skill_thermo: { type: 'checkbox', hidden: true },
        skill_fiber_fis: { type: 'checkbox', hidden: true },
        skill_fiber_install: { type: 'checkbox', hidden: true },
        skill_hp: { type: 'checkbox', hidden: true },
        pxm_ldc_key_id: { type: 'text', placeholder: '' },
        pxm_wfm_serial: { type: 'text', placeholder: '' },
        pxm_start_net: { type: 'netcodes', hidden: true },
        mixplan: { type: 'checkbox', hidden: true },
        pxm_home_location: { type: 'netcodes', hidden: true },
        subco_for_subco: { type: 'checkbox' },
        pxm_click_startnet: { type: 'text', hidden: true },
        pxm_click_startnet_geocode: { type: 'text', hidden: true },
        pxm_click_homelocation: { type: 'text', hidden: true },
        pxm_click_homelocation_geocode: { type: 'text', hidden: true },

        skill_acn_cop_construction: { type: 'text', hidden: true },
        skill_acn_cop_prov: { type: 'text', hidden: true },
        skill_acn_cop_repair: { type: 'text', hidden: true },
        skill_acn_fib_underground: { type: 'text', hidden: true },
        skill_acn_fib_facade: { type: 'text', hidden: true },
        skill_acn_aide: { type: 'text', hidden: true },
        skill_acn_paving: { type: 'text', hidden: true },
        skill_acn_machine: { type: 'text', hidden: true },
    };
    tech: any;
    loading: boolean = false;
    formValues: { [k: string]: any } = {};
    subcos: any = [];
    constructor(
        private techService: TechService,
        private actRoute: ActivatedRoute,
        private router: Router,
        private subcoService: SubcoService
    ) {
        this.techService
            .getTechById(this.actRoute.snapshot.params.id)
            .subscribe((data) => {
                this.tech = data;
                // Get the subcos
                this.subcoService.getAll().subscribe((response) => {
                    for (var i = 0; i < response.length; i++) {
                        this.subcos[i] = {
                            name: response[i].firm_name,
                            id: response[i].id,
                        };
                    }
                    console.log(this.subcos);
                });
            });
    }

    getItem(key: any) {
        if (this.formFields[key]) {
            return this.formFields[key].type;
        }
        return 'print';
    }

    checkHidden(key: any) {
        if (this.formFields[key]) {
            return this.formFields[key].hidden;
        }
    }
    getItemContent(key: any) {
        if (this.formFields[key]) {
            return this.formFields[key];
        }
        return false;
    }

    asIsOrder(a: any, b: any) {
        return 1;
    }
    ngOnInit(): void {}
    saveForm(items: any) {
        this.loading = true;
        items.value['id'] = this.tech.id;
        items.value['pxm_per'] = this.tech.pxm_per;
        items.value['ERP_ID'] = this.tech.ERP_ID;
        items.value['GPS_ID'] = this.tech.GPS_ID;
        items.value['WM_ID'] = this.tech.WM_ID;
        items.value['tech_mail'] = this.tech.tech_mail;

        this.techService.editTech(items.value).subscribe((data) => {
            console.log(data);
        });

        this.loading = false;
        this.router.navigate(['/admin/wiw/alltechs']);
    }
}
