import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DispatchStorageService {
    constructor() {}

    // dispatch_allTechs
    getTechs() {
        return JSON.parse(localStorage.getItem('dispatch_allTechs') || '[]');
    }

    setTechs(techs: any) {
        return localStorage.setItem('dispatch_allTechs', JSON.stringify(techs));
    }

    delTechs() {
        return localStorage.removeItem('dispatch_allTechs');
    }
    techsCount() {
        var count = this.getTechs().length;
        return count ? count : false;
    }

    // dispatch_activeTech
    getActiveTech() {
        return JSON.parse(localStorage.getItem('dispatch_activeTech') || '{}');
    }

    setActiveTech(tech: any) {
        return localStorage.setItem(
            'dispatch_activeTech',
            JSON.stringify(tech)
        );
    }

    delActiveTech() {
        return localStorage.removeItem('dispatch_activeTech');
    }
    activeTechCount() {
        var count = Object.keys(this.getActiveTech()).length;
        return count ? count : false;
    }

    // dispatch_allOrders
    getOrders() {
        return JSON.parse(localStorage.getItem('dispatch_allOrders') || '[]');
    }

    setOrders(orders: any) {
        return localStorage.setItem(
            'dispatch_allOrders',
            JSON.stringify(orders)
        );
    }

    delOrders() {
        return localStorage.removeItem('dispatch_allOrders');
    }
    ordersCount() {
        var count = this.getOrders().length;
        return count ? count : false;
    }

    getAreaCount() {
        return JSON.parse(
            localStorage.getItem('dispatch_orderCounter') || '{}'
        );
    }

    setAreaCount(areaCount: any) {
        return localStorage.setItem(
            'dispatch_orderCounter',
            JSON.stringify(areaCount)
        );
    }
}
