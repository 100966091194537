<div class="alert alert-info" *ngIf="set">
    <table class="table table-sm">
        <tbody>
            <tr>
                <td><strong>Active:</strong></td>
                <td>{{ tech?.tech_name }}</td>
                <td>
                    <button
                        class="btn btn-outline-primary btn-sm"
                        placement="right"
                        ngbTooltip="Locate on map"
                        (click)="locateTech(tech)"
                    >
                        <i class="fas fa-search-location"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <td><strong>Orders:</strong></td>
                <td>
                    <ngb-progressbar
                        type="success"
                        [value]="tech?._orders.length"
                        [max]="8"
                        >{{ tech?._orders.length }}/8</ngb-progressbar
                    >
                </td>
                <td>
                    <button
                        class="btn btn-outline-warning btn-sm"
                        placement="right"
                        ngbTooltip="Hide technician (hotkey: e)"
                        (click)="hideTech(tech)"
                    >
                        <i class="fas fa-eye-slash"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <td><strong>Points:</strong></td>
                <td>
                    <ngb-progressbar
                        type="success"
                        [value]="tech?._points"
                        [max]="6.5"
                        >{{
                            tech?._points | number: '1.2-2'
                        }}/6.5</ngb-progressbar
                    >
                </td>
                <td>
                    <button
                        class="btn btn-outline-danger btn-sm"
                        placement="right"
                        ngbTooltip="Remove 'active' status"
                        (click)="removeActiveStatus()"
                    >
                        <i class="fas fa-car"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="alert alert-danger" *ngIf="error">
    First remove the active tech before setting another one.
    <button
        type="button"
        class="close"
        (click)="error = false"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
