<div class="row">
    <div class="col-md-4">
        <app-dispatch-activetech
            (activeTechChangedEvent)="activeTechChangeNoLoopback($event)"
            (locateTechEvent)="locateTechChange($event)"
            (hideTechEvent)="hideTechChange($event)"
        ></app-dispatch-activetech>
        <div class="alert alert-danger" *ngIf="activeTechError">
            First set an active technician
            <button
                type="button"
                class="close"
                (click)="activeTechError = false"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-dispatch-sidebar
            (openModal)="openModal($event)"
            (showTechOnMapEvent)="showTechChange($event)"
            (addOrderBackToMapEvent)="addOrderBackToMapChange($event)"
            (changeActiveTechGlobalEvent)="changeActiveTechGlobalChange($event)"
            [activeTech]="activeTech"
        ></app-dispatch-sidebar
        ><!--[activeTech]="activeTech"-->
    </div>
    <div class="col-md-8">
        <app-dispatch-map
            (setActiveTechEvent)="setActiveTechChange($event)"
            (setClickedOrder)="setClickedOrderEvent($event)"
            (allTechsUpdateEvent)="allTechsUpdateChange()"
        ></app-dispatch-map>
    </div>
</div>
