<div class="container h-100 text-center align-middle" *ngIf="!user.username">
    <i class="fas fa-spinner fa-pulse"></i> Loading app
</div>
<div class="container-fluid" *ngIf="user.username">
    <div class="row">
        <div class="col-md-6">Welcome {{ user.username }}</div>
        <div class="col-md-6 text-right">
            <!--<small>(autologout in {{autoLogoutTime}} seconds)</small>-->
        </div>
    </div>
    <hr />

    <ng-container *ngIf="userCoachingAppCount > 0">
        <h1>Coaching</h1>
        <div class="row">
            <ng-container *ngFor="let app of appsCoaching; let i = index">
                <div class="col-md-{{ tileWidth() }}" *ngIf="checkRight(app)">
                    <br />
                    <div class="card text-center">
                        <br />
                        <i class="fas {{ app.fa }} fa-4x"></i>
                        <div class="card-body">
                            <h5 class="card-title">{{ app.name }}</h5>

                            <a [routerLink]="[app.link]" class="btn btn-primary"
                                >Go there</a
                            >
                            <a
                                class="btn btn-sm btn-outline-danger"
                                *ngIf="isUser() && app.userSettingsLink"
                                [routerLink]="app.userSettingsLink"
                                >Settings</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
