import { JwtdecoderService } from './../_helpers/jwtdecoder.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private userSubject: BehaviorSubject<User>;
    private userDetailsSubject: BehaviorSubject<{}>;
    public user: Observable<User>;
    public userDetails: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private userService: UserService,
        private jwtDecoderService: JwtdecoderService
    ) {
        this.userSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('user') || '{}')
        );
        this.userDetailsSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('user-details') || '{}')
        );
        this.user = this.userSubject.asObservable();
        this.userDetails = this.userDetailsSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public get userDetailsValue(): any {
        return this.userDetailsSubject.getValue();
    }

    login(username: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}login`, { username, password })
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    this.userSubject.next(user);

                    // Add the complete user thingy to the localstorage
                    this.userService.getUserById(user.id).subscribe((data) => {
                        localStorage.setItem(
                            'user-details',
                            JSON.stringify(data)
                        );
                        this.userDetailsSubject.next(data);
                        return this.userDetails as User;
                    });
                    return user as User;
                })
            );
    }

    sso(jwt: string) {
        return this.http
            .post<any>(`${environment.apiUrl}sso`, { jwt: jwt })
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    this.userSubject.next(user);

                    // Add the complete user thingy to the localstorage
                    this.userService.getUserById(user.id).subscribe((data) => {
                        localStorage.setItem(
                            'user-details',
                            JSON.stringify(data)
                        );
                        this.userDetailsSubject.next(data);
                        return this.userDetails as User;
                    });
                    return user as User;
                })
            );
    }

    logout() {
        this.stopRefreshTokenTimer();
        localStorage.removeItem('user');
        localStorage.removeItem('user-details');
        this.userSubject.next({});
        this.router.navigate(['/login']);
    }

    refreshToken() {
        return this.http
            .post<any>(`${environment.apiUrl}auth/refresh`, {})
            .pipe(
                map(
                    (user) => {
                        localStorage.setItem('user', JSON.stringify(user));
                        this.userSubject.next(user);
                        this.startRefreshTokenTimer();
                        return user;
                    },
                    (err: any) => {
                        console.log('error refresh');
                    }
                )
            );
    }

    private refreshTokenTimeout: any;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.stringify(this.userValue.token);
        const jwtTokenDecoded = this.jwtDecoderService.parseJwt(jwtToken);

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtTokenDecoded.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;

        if (this.userDetails) {
            this.refreshTokenTimeout = setTimeout(
                () => this.refreshToken().subscribe(),
                timeout
            );
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
