<div class="alert alert-info" *ngIf="loadingTechs">Loading technicians</div>
<ng-container *ngIf="!loadingTechs">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-1">Areas:</div>
                <div class="col-md-11">
                    <div
                        class="btn-group btn-group-stretched"
                        role="group"
                        aria-label="Basic checkbox toggle button group">
                        <ng-container *ngFor="let area of techAreas">
                            <input
                                type="checkbox"
                                class="btn-check"
                                id="btncheck{{ area }}"
                                autocomplete="off"
                                [checked]="areaSelectedCheck(area)"
                                (click)="areaClick(area)" />
                            <label
                                class="btn btn-outline-primary"
                                for="btncheck{{ area }}"
                                >A{{ area }}</label
                            >
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 10px">
                <div class="col-md-1">Business unit:</div>
                <div class="col-md-11">
                    <div
                        class="btn-group btn-group-stretched"
                        role="group"
                        aria-label="Basic checkbox toggle button group">
                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio"
                            id="btncheckFlandres"
                            autocomplete="off"
                            (click)="buClick('flandres')" />
                        <label
                            class="btn btn-outline-primary"
                            for="btncheckFlandres"
                            >Flandres</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio"
                            id="btncheckWalonieBrussels"
                            autocomplete="off"
                            (click)="buClick('walonia')" />
                        <label
                            class="btn btn-outline-primary"
                            for="btncheckWalonieBrussels"
                            >Walonia & Brussels</label
                        >
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 10px">
                <div class="col-md-1">Skills:</div>
                <div class="col-md-11">
                    <div
                        class="btn-group btn-group-stretched"
                        role="group"
                        aria-label="Basic checkbox toggle button group">
                        <input
                            type="checkbox"
                            class="btn-check"
                            id="btncheckAll"
                            [disabled]="!selectedAreas.length"
                            [checked]="skillSelectedCheck('ALL')"
                            autocomplete="off"
                            (click)="skillClick('ALL')" />
                        <label class="btn btn-outline-primary" for="btncheckAll"
                            >All</label
                        >
                        <ng-container *ngFor="let skill of skills">
                            <ng-container *ngIf="skill.inTechArray">
                                <input
                                    type="checkbox"
                                    class="btn-check"
                                    id="btncheck{{ skill.skill }}"
                                    [disabled]="!selectedAreas.length"
                                    [checked]="skillSelectedCheck(skill.skill)"
                                    autocomplete="off"
                                    (click)="skillClick(skill.skill)" />
                                <label
                                    class="btn btn-outline-primary"
                                    for="btncheck{{ skill.skill }}"
                                    >{{ skill.readable }}</label
                                >
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-bordered table-hover">
                <thead class="text-center">
                    <tr>
                        <th colspan="4">&nbsp;</th>
                        <th colspan="4">Proximus</th>
                        <th colspan="2">Scarlet</th>
                        <th colspan="4">Backoffice</th>
                        <th colspan="7">ACN</th>
                    </tr>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Area</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th *ngFor="let skill of skills" class="rotation">
                            {{ skill.readable }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let tech of filteredTechs"
                        [ngClass]="{ 'bg-success': tech.setForSMS }">
                        <td>
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault{{ tech.id }}"
                                    [checked]="tech.setForSMS"
                                    [disabled]="!selectedAreas.length"
                                    (click)="toggleSingleTech(tech)" />
                            </div>
                        </td>
                        <td>A{{ tech.area }}</td>
                        <td>{{ tech.tech_name }}</td>
                        <td>{{ tech.phone_work_clean }}</td>
                        <td *ngFor="let skill of skills">
                            <span *ngIf="tech[skill.skill] == '1'"
                                ><i class="fas fa-check-circle text-success"></i
                            ></span>
                            <span *ngIf="tech[skill.skill] != '1'"
                                ><i class="fas fa-times-circle text-danger"></i
                            ></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
