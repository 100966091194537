import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PlanningService {
    private API_URL = environment.apiUrl;
    constructor(private http: HttpClient) {}

    month(number: any): Observable<any> {
        return this.http.get(this.API_URL + 'site/month/' + number, {
            responseType: 'json',
        });
    }
}
