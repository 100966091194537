import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'app-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.css'],
})
export class SsoComponent implements OnInit {
    errorMessage: any = false;
    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            console.log(params.jwt);
            this.authenticationService
                .sso(params.jwt)
                .pipe()
                .subscribe(
                    (data) => {
                        this.router.navigate(['/home']);
                    },
                    (error) => {
                        console.log(error);
                        this.errorMessage =
                            'Cant login! Please contact Joachim Logghe or visit https://vl2.jfs.be/ and log in on that page (workmeter credentials)';
                    }
                );
        });
    }
}
