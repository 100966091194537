<div class="row">
    <div class="col-md-6">
        Network:
        <select
            class="form-control"
            #networkselect
            (change)="getKVD(networkselect.value)"
        >
            <option disbled>--Select a network--</option>
            <option *ngFor="let network of allNetworks" [value]="network.id">
                {{ network.net_code }} - {{ network.netname }}
            </option>
        </select>
    </div>
    <div class="col-md-6" *ngIf="activeNetworks">
        KVD:
        <select
            class="form-control"
            #itemselect
            (change)="jumptoKVD(itemselect.value)"
        >
            <option disbled>--Select a kvd--</option>
            <option
                *ngFor="let kvd of activeNetworks.kvd; let i = index"
                [value]="i"
            >
                NET_{{ kvd.properties.net }} - LDC_{{ kvd.properties.lexldc }} -
                KVD_{{ kvd.properties.kvdnr }}
            </option>
        </select>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-12">
        <div class="map-container">
            <div
                class="map"
                id="mapKvdMap"
                class="map-container"
                [style.height.px]="screenHeight"
            ></div>
        </div>
    </div>
</div>
