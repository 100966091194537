import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'kpiverslag',
})
export class KpiverslagPipe implements PipeTransform {
    transform(items: any): any {
        if (!items) {
            return items;
        }
        return Object.values(items).filter((item: any) => {
            if (item.apps.includes('kpiverslag')) {
                return item;
            }
        });
    }
}
