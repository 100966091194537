import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    private API_URL = environment.apiUrl;

    getUserById(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'user/user/' + id, {
            responseType: 'json',
        });
    }

    getAll(minimal: boolean = false): Observable<any> {
        var append = '';
        if (minimal) {
            append = '/minimal';
        }
        return this.http.get(this.API_URL + 'user/all' + append, {
            responseType: 'json',
        });
    }

    getAllRights(): Observable<any> {
        return this.http.get(this.API_URL + 'user/rights', {
            responseType: 'json',
        });
    }

    updateRight(data: any): Observable<any> {
        return this.http.post(this.API_URL + 'user/rights', data);
    }
}
