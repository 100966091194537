<div class="row">
    <div class="col-md-6">
        <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
            maxlength="160"
            [(ngModel)]="message"
            (ngModelChange)="valueChange()"
            [disabled]="sending"
            placeholder="Message (max 160 characters)"></textarea>
    </div>
    <div class="col-md-3">
        <div class="row">
            <div class="col-md-5">Charcount:</div>
            <div class="col-md-7">{{ charCount }}/160</div>
        </div>
        <div class="row">
            <div class="col-md-5">Selected techs:</div>
            <div class="col-md-7">
                {{ filteredTechs.length }}
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="progress w-100">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        [ngStyle]="{ width: progressValue + '%' }"
                        attr.aria-valuenow="{{ progressValue }}"
                        aria-valuemin="0"
                        aria-valuemax="100">
                        {{ progressValue }}%
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 8px">
            <div class="col-md-12">
                <button
                    class="btn btn-danger w-100"
                    [disabled]="
                        filteredTechs.length == 0 ||
                        charCount == 0 ||
                        sending ||
                        message.length == 0
                    "
                    (click)="sendSms()">
                    <i class="far fa-paper-plane"></i> Send SMS
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="alert alert-info" *ngIf="loadingTemplates">
            Loading templates
        </div>
        <ng-container *ngIf="!loadingTemplates">
            <div
                class="row"
                *ngFor="let template of templates"
                style="margin-bottom: 5px">
                <div class="col-md-12">
                    <button
                        class="btn btn-outline-warning btn-sm w-100 text-left"
                        (click)="setMessageValue(template.text)">
                        {{ template.title }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
