<table class="table table-bordered table-sm">
    <thead>
        <tr>
            <th colspan="2">Coach availability (this week)</th>
            <th>07u30 - 08u30</th>
            <th>8h30 - 11u00</th>
            <th>11u00 - 13u00</th>
            <th>13u00 - 15u00</th>
            <th>15u00 - 20u00</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Maandag</td>
            <td>19/04/2021</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-warning">Bern</td>
            <td class="bg-info">Jelle</td>
            <td class="bg-danger">Koen</td>
        </tr>
        <tr>
            <td>Dinsdag</td>
            <td>20/04/2021</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-info">Jelle</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-danger">Koen</td>
            <td class="bg-primary">Christophe</td>
        </tr>
        <tr>
            <td>Woensdag</td>
            <td>21/04/2021</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-info">Jelle</td>
            <td class="bg-warning">Bern</td>
            <td class="bg-dark">Tommy</td>
        </tr>
        <tr>
            <td>Donderdag</td>
            <td>22/04/2021</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-warning">Bern</td>
            <td class="bg-danger">Koen</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-info">Jelle</td>
        </tr>
        <tr>
            <td>Vrijdag</td>
            <td>23/04/2021</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-danger">Koen</td>
            <td class="bg-success">Joachim</td>
            <td class="bg-info">Jelle</td>
            <td class="bg-warning">Bern</td>
        </tr>
        <tr>
            <td>Zaterdag</td>
            <td>24/04/2021</td>
            <td class="bg-secondary" colspan="5">
                Joachim
                <small
                    ><strong>(07u30 - 16u00, enkel telefonisch)</strong></small
                >
            </td>
        </tr>
    </tbody>
</table>
