import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
})
export class KeysPipe implements PipeTransform {
    transform(value: any) {
        console.log(value);
        let keys = [];
        for (let key in value) {
            keys.push({ key: key, value: value[key] });
        }

        return keys;
    }
}
