import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'availabletechspipe',
    pure: false,
})
export class AvailabletechsPipe implements PipeTransform {
    transform(items: any): any {
        if (!items) {
            return items;
        }

        return Object.values(items).filter((item: any) => item._isAvailable);
    }
}
