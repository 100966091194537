<style>
    .has-error {
        border: 1px solid red;
        border-radius: 4px;
    }
</style>
<div class="row" ng-controller="wiwTechEditCtrl">
    <div class="col-md-1">
        <a
            class="btn btn-secondary w-100"
            [routerLink]="['/admin/wiw/alltechs']"
            ><i class="far fa-hand-point-left"></i> Back</a
        >
    </div>
    <div class="col-md-10">
        <div class="alert alert-info">
            <i class="fas fa-plus-square"></i> Add new technician
        </div>

        <div class="alert alert-success" *ngIf="successMessage.message">
            {{ successMessage.message }}<br />
            {{ successMessage.workmeter_info }}<br />
            <a
                [routerLink]="[
                    '/admin/wiw/alltechs/edit',
                    successMessage.tech_id
                ]"
                >Click here to edit the tech directly</a
            >
        </div>

        <form
            #f="ngForm"
            name="addTechForm"
            (ngSubmit)="f.form.valid && onSubmit()"
            novalidate>
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-text">
                                <i class="fas fa-street-view"></i>&nbsp;Area
                            </div>

                            <select
                                class="form-control"
                                id="techArea"
                                name="techArea"
                                [(ngModel)]="area"
                                [disabled]="loading"
                                required
                                #techArea="ngModel">
                                <option
                                    value="1"
                                    *ngIf="checkInAreaArray('A1')">
                                    A1
                                </option>
                                <option
                                    value="2"
                                    *ngIf="checkInAreaArray('A2')">
                                    A2
                                </option>
                                <option
                                    value="3"
                                    *ngIf="checkInAreaArray('A3')">
                                    A3
                                </option>
                                <option
                                    value="4"
                                    *ngIf="checkInAreaArray('A4')">
                                    A4
                                </option>
                                <option
                                    value="5"
                                    *ngIf="checkInAreaArray('A5')">
                                    A5
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div
                            class="alert alert-danger"
                            *ngIf="techArea.errors && f.submitted">
                            Please select a corresponding area
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-text">
                                <i class="fas fa-id-card-alt"></i
                                >&nbsp;Pernumber
                            </div>
                            <input
                                type="number"
                                class="form-control"
                                id="techPer"
                                name="techPer"
                                [(ngModel)]="pernumber"
                                placeholder="8XXXXX"
                                [disabled]="loading"
                                minlength="6"
                                maxlength="6"
                                required
                                #techPer="ngModel" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div
                            class="alert alert-danger"
                            *ngIf="techPer.errors && f.submitted">
                            Please provide a correct pernumber (only numbers
                            allowed, 6 digits)
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-text">
                                <i class="fas fa-user"></i>&nbsp;Lastname
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                id="techName"
                                name="techName"
                                [(ngModel)]="name"
                                placeholder="Lastname"
                                [disabled]="loading"
                                required
                                #techName="ngModel" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div
                            class="alert alert-danger"
                            *ngIf="techName.errors && f.submitted">
                            Please provide a name
                        </div>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-text">
                                    <i class="fas fa-user"></i>&nbsp;Firstname
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="techFirstname"
                                    name="techFirstname"
                                    [(ngModel)]="firstname"
                                    placeholder="Firstname"
                                    [disabled]="loading"
                                    required
                                    #techFirstname="ngModel" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                class="alert alert-danger"
                                *ngIf="techFirstname.errors && f.submitted">
                                Please provide a name
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <button type="submit" class="btn btn-primary" [disabled]="loading">
                <i class="far fa-save" *ngIf="!loading"></i
                ><i class="fas fa-spinner fa-spin" *ngIf="loading"></i> Save
                technician
            </button>
        </form>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
