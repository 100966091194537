import { DispatchService } from './../../../_services/dispatch.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-dispatch-insert',
    templateUrl: './dispatch-insert.component.html',
    styleUrls: ['./dispatch-insert.component.css'],
})
export class DispatchInsertComponent implements OnInit {
    @Input() files: any;
    newOrder: any;

    areaCount: any = [
        { area: 'A1', install: 0, repair: 0, move: 0, adddecoder: 0, total: 0 },
        { area: 'A2', install: 0, repair: 0, move: 0, adddecoder: 0, total: 0 },
        { area: 'A3', install: 0, repair: 0, move: 0, adddecoder: 0, total: 0 },
        { area: 'A4', install: 0, repair: 0, move: 0, adddecoder: 0, total: 0 },
        { area: 'A5', install: 0, repair: 0, move: 0, adddecoder: 0, total: 0 },
        {
            area: 'other',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
        },
    ];

    constructor(private dispatchService: DispatchService) {}

    ngOnInit(): void {
        //console.log(this.files);
        this.startInserter();
    }

    async startInserter() {
        for (let iFiles = 0; iFiles < this.files.length; iFiles++) {
            // Set the main progress on 0
            this.files[iFiles].progressInfo = {
                value: 0,
                inserted: 0,
                error: 0,
            };

            for (
                let iWO = 0;
                iWO < this.files[iFiles].data_table.length;
                iWO++
            ) {
                // This WO
                var WO = this.files[iFiles].data_table[iWO];
                WO.file_upload_id = this.files[iFiles].file_upload_id;

                var temp = {
                    data: WO,
                    file_upload_id: WO.file_upload_id,
                };
                // Do the call to api
                var response = await this.dispatchService.insert(temp);
                if (response.ok) {
                    console.log(response);
                    this.newOrder = response;
                    this.increaseAreaCounter(response.area, response.type);
                    // Set the progress info correctly
                    this.files[iFiles].progressInfo.value = Math.round(
                        ((iWO + 1) / this.files[iFiles].data_table.length) * 100
                    );
                    this.files[iFiles].progressInfo.inserted++;
                }

                if (response.error) {
                    this.files[iFiles].progressInfo.error++;
                    console.log(response.message);
                }
            }
        }
    }

    increaseAreaCounter(area: string, type: string) {
        for (var i = 0; i < this.areaCount.length; i++) {
            if (this.areaCount[i].area == area) {
                this.areaCount[i][type]++;
                this.areaCount[i]['total']++;
            }
        }
    }
}
