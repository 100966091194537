import { MmSettingsMapComponent } from './mm-settings-map/mm-settings-map.component';
import { MeetginmapService } from './../../../_services/meetginmap.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-mm-settings',
    templateUrl: './mm-settings.component.html',
    styleUrls: ['./mm-settings.component.css'],
})
export class MmSettingsComponent implements OnInit {
    constructor(private meetginmapService: MeetginmapService) {}

    @ViewChild(MmSettingsMapComponent) child!: MmSettingsMapComponent;

    successMessage: any;

    networkPolygon: any;
    networkGroup: any = {};
    networkGroupOrders: any = {};
    totalOrders: number = 0;
    ngOnInit(): void {
        this.meetginmapService.getNetworkPolygon().subscribe((data) => {
            this.networkPolygon = data;
            console.log(this.networkPolygon);

            // Loop the list to generate the "netcodes"
            for (let i = 0; i < this.networkPolygon.length; i++) {
                var network = this.networkPolygon[i].properties;
                var netKey = network.netcode.substring(0, 2);

                if (!(netKey in this.networkGroup)) {
                    this.networkGroup[netKey] = [];
                    this.networkGroupOrders[netKey] = 0;
                }

                this.networkGroupOrders[netKey] =
                    this.networkGroupOrders[netKey] + network.totalorders;
                this.totalOrders = this.totalOrders + network.totalorders;

                // Add the orgi id so we can use it later
                network.orig_id = i;
                this.networkGroup[netKey].push(network);
            }
        });
    }

    getNetworkGroupOrders(key: any) {
        return this.networkGroupOrders[key];
    }

    mouseEnter(event: any) {
        this.child.highlightPolygon(event, true);
    }

    mouseEnterGroup(key: any) {
        var groups = this.networkGroup[key];
        for (let i = 0; i < groups.length; i++) {
            this.child.highlightPolygon(groups[i].orig_id, true);
        }
    }

    mouseLeaveGroup(key: any) {
        var groups = this.networkGroup[key];
        for (let i = 0; i < groups.length; i++) {
            this.child.highlightPolygon(groups[i].orig_id, false);
        }
    }

    mouseLeave(event: any) {
        this.child.highlightPolygon(event, false);
    }

    groupClick(key: any) {
        var groups = this.networkGroup[key];
        for (let i = 0; i < groups.length; i++) {
            this.onCheckboxChangeEvent(groups[i].orig_id);
        }
    }

    openAll() {
        for (let i = 0; i < this.networkPolygon.length; i++) {
            var network = this.networkPolygon[i].properties;
            this.onCheckboxChangeEvent(network.orig_id, 'force_open');
        }
    }

    closeAll() {
        for (let i = 0; i < this.networkPolygon.length; i++) {
            var network = this.networkPolygon[i].properties;
            this.onCheckboxChangeEvent(network.orig_id, 'force_close');
        }
    }

    onCheckboxChangeEvent(event: any, changeStatus: string = 'normal') {
        var status = this.networkPolygon[event].properties.mmopen;
        var newStatus = 0;
        if (status == 0) {
            newStatus = 1;
        }

        if (changeStatus == 'force_open') {
            newStatus = 1;
        } else if (changeStatus == 'force_close') {
            newStatus = 0;
        }

        this.networkPolygon[event].properties.mmopen = newStatus;

        var networks = {
            status: changeStatus,
            networks: [this.networkPolygon[event].properties.netcode],
        };

        this.meetginmapService.changeStatus(networks).subscribe((data) => {
            console.log(data);
            if (data.ok) {
                this.successMessage = data.ok;
            }
        });

        this.child.onCheckboxChangeEvent(event, newStatus);
    }
}
