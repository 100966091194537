<div class="row">
    <div class="col-md-1">
        <a
            *ngIf="
                user.apps.includes('full') || user.apps.includes('wiw_admin')
            "
            class="btn btn-success btn-sm w-100"
            [routerLink]="['/admin/wiw/allsubco/add']"
            [class.disabled]="loading">
            <i class="fas fa-user-plus"></i> Add new
        </a>
    </div>
    <div class="col-md-10">
        <div class="alert alert-danger" *ngIf="stopSearch">
            There are no Subcos found with this search criteria.
        </div>
        <table
            class="table table-sm table-bordered table-hover"
            *ngIf="!stopSearch && !loading">
            <thead>
                <th
                    *ngIf="
                        user.apps.includes('full') ||
                        user.apps.includes('wiw_admin')
                    ">
                    &nbsp;
                </th>
                <th><i class="fas fa-building"></i> Firm name</th>
                <th><i class="fas fa-users-cog"></i> Contacts</th>
                <th><i class="fas fa-user-shield"></i> N+1</th>
                <th><i class="fas fa-users"></i> Techs (incl subco)</th>
            </thead>
            <tbody>
                <tr *ngFor="let subco of subcos">
                    <td
                        *ngIf="
                            user.apps.includes('full') ||
                            user.apps.includes('wiw_admin')
                        ">
                        <table
                            class="table table-borderless table-sm text-center">
                            <tr>
                                <td style="width: 50%">
                                    <a
                                        ngbTooltip="Edit subco"
                                        class="btn bg-primary btn-sm w-100"
                                        [routerLink]="[
                                            '/admin/wiw/allsubco/edit',
                                            subco.id
                                        ]">
                                        <i class="fas fa-user-edit"></i>
                                    </a>
                                </td>
                                <td style="width: 50%">
                                    <a
                                        ngbTooltip="Delete subco"
                                        class="btn bg-danger btn-sm w-100"
                                        (click)="
                                            openModal2(subco, templateDelete)
                                        ">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td>{{ subco.firm_name }}</td>
                    <td>
                        <ul>
                            <li *ngFor="let contact of subco.contacts">
                                {{ contact.name }} ({{ contact.phone }})
                            </li>
                        </ul>
                    </td>
                    <td>{{ searchSubcoById(subco.main).firm_name }}</td>
                    <td>
                        <a
                            class="btn btn-sm btn-outline-primary w-100"
                            (click)="openModal(subco, template)"
                            [class.disabled]="subco.total_tech_count == 0"
                            >{{ subco.tech_count_own }} own +
                            {{ subco.tech_count_subco }} subco =
                            {{ subco.total_tech_count }} total</a
                        >
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-info" *ngIf="loading">
            <i class="fas fa-spinner fa-pulse"></i> Loading
        </div>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>

<ng-template #template>
    <div class="modal-body">
        <h1>Own techs</h1>
        <table class="table table-sm table-hover table-bordered">
            <thead>
                <tr>
                    <th>Area</th>
                    <th>Name</th>
                    <th>Main firm</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tech of modaltechs.techs">
                    <td>A{{ tech.area }}</td>
                    <td>{{ tech.tech_name }}</td>
                    <td>{{ searchSubcoById(tech.subco_id).firm_name }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #templateDelete>
    <div class="modal-body text-center bg-warning">
        <p>
            Are you sure you want to delete this Subco?<br />
            <strong>{{ deleteFirm.firm_name }}</strong>
        </p>
        <div class="alert alert-info">
            If you delete this firm, all technicians with this firm assigned to
            it will be deleted!
        </div>
        <button type="button" class="btn btn-danger" (click)="confirm()">
            Yes</button
        >&nbsp;
        <button type="button" class="btn btn-primary" (click)="decline()">
            No
        </button>
    </div>
</ng-template>
