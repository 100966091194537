<div class="row">
  <div class="col-md-6">
    <div style="text-align: center">
      <apx-chart
        [series]="chartOptions?.series"
        [chart]="chartOptions?.chart"
        [labels]="chartOptions?.labels"
        [title]="chartOptions?.title"
      ></apx-chart>
    </div>
  </div>
  <div class="col-md-6">
    <div style="text-align: center">
      <apx-chart
        [series]="chartApiOptions?.series"
        [chart]="chartApiOptions?.chart"
        [labels]="chartApiOptions?.labels"
        [title]="chartApiOptions?.title"
      ></apx-chart>
    </div>
  </div>
</div>
