import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-wiw',
  templateUrl: './wiw.component.html',
  styleUrls: ['./wiw.component.css'],
})
export class WiwComponent implements OnInit {
  errorMessage: string = '';
  showDetails: any;
  user: any;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.userDetails.subscribe(
      (data) => (this.user = data)
    );
  }
  ngOnInit(): void {}

  passTech(data: any) {
    this.showDetails = data;
  }
}
