import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SubcoService } from './../../../../_services/subco.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-admin-wiw-allsubco-add',
    templateUrl: './admin-wiw-allsubco-add.component.html',
    styleUrls: ['./admin-wiw-allsubco-add.component.css'],
})
export class AdminWiwAllsubcoAddComponent implements OnInit {
    errorMessage: any = {};
    successMessage: any = {};
    loading: boolean = false;

    allSubcos: any;

    addForm = new FormGroup({
        firm_name: new FormControl('', [Validators.required]),
        main: new FormControl('', [Validators.required]),
        contacts: new FormArray([]),
    });

    contacts = this.addForm.get('contacts') as FormArray;

    constructor(private subcoService: SubcoService, private router: Router) {
        this.subcoService.getAll().subscribe((data) => {
            this.allSubcos = data;
            this.addContact();
        });
    }

    ngOnInit(): void {}

    get firm_name() {
        return this.addForm.get('firm_name');
    }

    get main() {
        return this.addForm.get('main');
    }

    addContact(
        contactVal: any = {
            name: '',
            firstname: '',
            phone: '',
            mail: '',
        }
    ) {
        console.log('test');
        const contact = new FormGroup({
            id: new FormControl(contactVal.id),
            name: new FormControl(contactVal.name, Validators.required),
            firstname: new FormControl(
                contactVal.firstname,
                Validators.required
            ),
            phone: new FormControl(contactVal.phone, Validators.required),
            mail: new FormControl(contactVal.mail, Validators.required),
        });

        this.contacts.push(contact);
    }

    deleteContact(iDelete: number) {
        this.contacts.removeAt(iDelete);
    }

    onSubmit() {
        var data = {
            firm_name: this.firm_name?.value,
            main: this.main?.value,
            contacts: this.contacts?.value,
        };

        this.subcoService.add(data).subscribe((response) => {
            if (response.error) {
                this.errorMessage.message = response.message;
            } else {
                this.router.navigate(['admin/wiw/allsubcos']);
            }
        });
    }
}
