import { DispatchStorageService } from './dispatch-storage.service';
import { Injectable } from '@angular/core';
import { onInitEffects } from '@ngrx/effects/src/lifecycle_hooks';

@Injectable({
    providedIn: 'root',
})
export class DispatchTechService {
    public tech: any;
    private allTechs: any;

    constructor(private storage: DispatchStorageService) {}

    ngOnInit() {
        console.log('init tech service');
    }

    getTechs() {
        return this.init();
    }

    addTech() {
        if (this.tech) {
            this.init();
            this.tech._origKey = this.allTechs.length;
            this.allTechs.push(this.addTechData(this.tech));
            this.save();
            return this.allTechs;
        }
        return false;
    }

    setAvailable() {
        if (this.tech) {
            this.init();
            this.allTechs[this.tech._origKey]._isHidden = true;
            this.allTechs[this.tech._origKey]._isAvailable = true;
            this.save();
            return this.allTechs;
        }
        return false;
    }

    setUnavailable() {
        if (this.tech) {
            this.init();
            this.allTechs[this.tech._origKey]._isHidden = false;
            this.allTechs[this.tech._origKey]._isAvailable = false;
            this.save();
            return this.allTechs;
        }
        return false;
    }

    private addTechData(tech: any) {
        tech._orders = [];
        tech._emptyOrders = [];
        tech._remark = '';
        tech._ordersBgColor = '';
        tech._mixplan = false;
        tech._spare = false;
        tech._points = 0;
        tech._isHidden = false;
        tech._isAvailable = false;
        tech._isHiddenFromMap = false;
        return tech;
    }

    private init() {
        this.allTechs = this.storage.getTechs();
        return this.allTechs;
    }

    private save() {
        this.storage.setTechs(this.allTechs);
        return this.init();
    }
}
