<div class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }}
</div>
<div class="row" *ngIf="!errorMessage">
    <div class="col-md-6">
        <div class="row">
            <div
                class="col-5 d-block d-md-none"
                style="
                    background-image: url('img/jfs.jpg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                "
                ng-click="search=''"
            >
                <div class="form-group">&nbsp;</div>
            </div>
        </div>
        <br />
        <app-wiw-techs (techSelectedEvent)="passTech($event)"></app-wiw-techs>
    </div>
    <div class="col-md-6" *ngIf="showDetails">
        <br />
        <app-wiw-detail
            [tech]="showDetails"
            [userLoginMethod]="user.login_method"
        ></app-wiw-detail>
    </div>
</div>
