import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubcoService } from './../../../_services/subco.service';
import { AuthenticationService } from './../../../_services/authentication.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-admin-wiw-allsubcos',
    templateUrl: './admin-wiw-allsubcos.component.html',
    styleUrls: ['./admin-wiw-allsubcos.component.css'],
})
export class AdminWiwAllsubcosComponent implements OnInit {
    user: any;
    subcos: any;
    loading: boolean = false;
    stopSearch: boolean = false;
    modalRef?: BsModalRef;
    modaltechs: any;
    deleteFirm: any;
    constructor(
        private authenticationService: AuthenticationService,
        private subcoService: SubcoService,
        private modalService: BsModalService
    ) {
        this.loading = true;
        this.authenticationService.userDetails.subscribe((data) => {
            this.user = data;

            // Get all the subcos
            this.subcoService.getAll().subscribe((response) => {
                this.subcos = response;
                this.loading = false;
            });
        });
    }

    ngOnInit(): void {}

    searchSubcoById(id: number) {
        for (var i = 0; i < this.subcos.length; i++) {
            if (this.subcos[i].id == id) {
                return this.subcos[i];
            }
        }
        return false;
    }

    openModal(techs: any, template: TemplateRef<any>) {
        this.modaltechs = techs;
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog modal-lg',
        });
    }

    openModal2(firm: any, template: TemplateRef<any>) {
        this.deleteFirm = firm;
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog',
        });
    }

    confirm(): void {
        //DELETE
        this.subcoService.delete(this.deleteFirm.id).subscribe((x) => {
            this.subcoService.getAll().subscribe((response) => {
                this.subcos = response;
                this.modalRef?.hide();
                this.deleteFirm = false;
            });
        });
    }

    decline(): void {
        this.deleteFirm = false;
        this.modalRef?.hide();
    }
}
