import { GeoService } from './../../../_services/geo.service';
import { TechService } from './../../../_services/tech.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Component({
    selector: 'app-kvdmap',
    templateUrl: './kvdmap.component.html',
    styleUrls: ['./kvdmap.component.css'],
})
export class KvdmapComponent implements OnInit {
    map: any;
    marker: any;
    style = 'mapbox://styles/mapbox/satellite-v9';
    allKVD: any;

    allNetworks: any;
    previousNetwork: any = false;
    activeNetworks: any = false;

    loading: boolean = false;

    public screenWidth: any;
    public screenHeight: any;

    constructor(private geoService: GeoService) {
        this.geoService.getAllNetworks().subscribe((data) => {
            this.allNetworks = data;
            console.log(this.allNetworks);
        });
    }

    ngOnDestroy() {
        this.map.remove();
        this.map = null;
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 200;

        this.map = new mapboxgl.Map({
            container: 'mapKvdMap',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 8,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
        });

        this.map.addControl(new mapboxgl.NavigationControl());
        this.geoService.getNetworkPolygon().subscribe((data) => {
            this.drawNetworkPolygon(data);
        });
        //this.getKVD();
    }

    // Get all the techs
    getKVD(id: any) {
        return this.geoService.getAllKVD(id).subscribe((data1) => {
            console.log(data1);
            this.drawKVDLDC(id, data1);
        });
    }

    drawKVDLDC(sourceid: any, data: any) {
        // Check if previous is present
        if (this.previousNetwork) {
            this.map.removeLayer('maine_kvd_' + this.previousNetwork);
            this.map.removeSource('kvds_' + this.previousNetwork);
            this.map.removeLayer('maine_ldc_' + this.previousNetwork);
            this.map.removeSource('ldcs_' + this.previousNetwork);
            this.previousNetwork = false;
            this.activeNetworks = false;
            this.activeNetworks = data;
        } else {
            this.previousNetwork = sourceid;
            this.activeNetworks = data;
        }
        var popup = new mapboxgl.Popup({
            closeButton: false,
        });

        var collectionKVD = {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: data.kvd },
        };

        var collectionLDC = {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: data.ldc },
        };

        this.map.addSource('kvds_' + sourceid, collectionKVD);
        this.map.addSource('ldcs_' + sourceid, collectionLDC);

        this.map.addLayer({
            id: 'maine_kvd_' + sourceid,
            type: 'circle',
            source: 'kvds_' + sourceid,
            paint: {
                'circle-color': 'red',
                'circle-radius': 4,
                'circle-stroke-width': 1,
            },
        });

        this.map.addLayer({
            id: 'maine_ldc_' + sourceid,
            type: 'circle',
            source: 'ldcs_' + sourceid,
            paint: {
                'circle-color': 'yellow',
                'circle-radius': 4,
                'circle-stroke-width': 1,
            },
        });

        // Zoom to the first ldc
        this.map.flyTo({
            center: data.ldc[0].properties.coordinates,
            zoom: 11,
        });
        console.log(collectionKVD);
        this.map.on('mousemove', 'maine_kvd_' + sourceid, (e: any) => {
            this.map.getCanvas().style.cursor = 'pointer';

            // Populate the popup and set its coordinates based on the feature.
            var feature = e.features[0];
            popup
                .setLngLat(feature.geometry.coordinates)
                .setHTML(this.generatePopupContentKVD(feature))
                .addTo(this.map);
        });
        this.map.on('mouseleave', 'maine_kvd_' + sourceid, (e: any) => {
            this.map.getCanvas().style.cursor = '';

            // Populate the popup and set its coordinates based on the feature.
            popup.off();
        });
    }

    jumptoKVD(index: any) {
        this.map.flyTo({
            center: this.activeNetworks.kvd[index].properties.coordinates,
            zoom: 16,
        });
    }

    generatePopupContentKVD(f: any) {
        var html =
            '<table>' +
            '<tr><td>Netcode</td><td>' +
            f.properties.net +
            '</td></tr>' +
            '<tr><td>KVD Number</td><td>' +
            f.properties.kvdnr +
            '</td></tr>' +
            '<tr><td>Street + number</td><td>' +
            f.properties.street_name +
            ' ' +
            f.properties.nr +
            '</td></tr>' +
            '<tr><td>City</td><td>' +
            f.properties.city_name +
            '</td></tr>' +
            '<tr><td>Type</td><td>' +
            f.properties.access_point_type +
            '</td></tr>' +
            '</table>';

        return html;
    }

    addMarker(element: any) {
        var colorsCode: any = {
            Copper: '#263AA9',
            Fiber: '#FCFF1E',
            FISS: '#FF1EB4',
            Active: 'red',
        };
        var markerElement: any = new mapboxgl.Marker({
            color: colorsCode[element.wb_type],
            scale: 0,
        })

            .setLngLat(
                new mapboxgl.LngLat(element.geocode[0], element.geocode[1])
            )

            .addTo(this.map);

        return markerElement;
    }

    drawNetworkPolygon(data: any) {
        var polygonData = {
            type: 'FeatureCollection',
            features: data,
        };

        this.map.addSource('networks', {
            type: 'geojson',
            data: polygonData,
        });

        // Add a new layer to visualize the polygon.
        this.map.addLayer({
            id: 'maine',
            type: 'fill',
            source: 'networks', // reference the data source
            layout: {},
            paint: {
                'fill-color': '#5bc0de',
                'fill-opacity': 0.3,
            },
        });
        // Add a black outline around the polygon.
        this.map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'networks',
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 0.2,
            },
        });
    }
}
