import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DispatchService {
    constructor(private http: HttpClient) {}
    private API_URL = environment.apiUrl;

    getTechImage(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'dispatch/imageTech/' + id, {
            responseType: 'json',
        });
    }

    getOrderImage(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'dispatch/imageOrder/' + id, {
            responseType: 'json',
        });
    }

    getOrders(): Observable<any> {
        return this.http.get(this.API_URL + 'dispatch/orders', {
            responseType: 'json',
        });
    }

    getUploadedFileInfoById(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'dispatch/uploadFileInfo/' + id, {
            responseType: 'json',
        });
    }

    insert(body: any): any {
        return this.http
            .post(this.API_URL + 'dispatch/insert', body)
            .toPromise()
            .then((r) => {
                return r;
            });
    }

    getRoute(body: any): Observable<any> {
        return this.http.post(this.API_URL + 'dispatch/route', body, {
            responseType: 'json',
        });
    }

    generateExcel(toSend: any): Observable<any> {
        return this.http.post(this.API_URL + 'dispatch/createexcel', toSend, {
            responseType: 'arraybuffer',
        });
    }
}
