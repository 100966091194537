<div class="alert alert-danger" *ngIf="errorMessage">
  {{ errorMessage }}
</div>
<table
  class="table table-sm table-hover table-bordered"
  *ngIf="allUsers && allUsers.length > 0 && !errorMessage"
>
  <thead>
    <th>Username</th>
    <th>Function</th>
    <th>Area</th>
    <th>Total logins</th>
    <th>Last login</th>
    <th>Api calls</th>
    <th>Apps</th>
    <th>Automails</th>
    <th>File uploads</th>
  </thead>
  <tbody>
    <tr *ngFor="let userDetail of allUsers">
      <td>{{ userDetail.username }}</td>
      <td>{{ userDetail.function }}</td>
      <td>
        <ul>
          <li *ngFor="let area of userDetail.area">{{ area }}</li>
        </ul>
      </td>
      <td>{{ userDetail.login_attempts }}</td>
      <td>{{ userDetail.last_login }}</td>
      <td>{{ userDetail.api_calls }}</td>
      <td>
        <ul>
          <li *ngFor="let app of userDetail.apps">{{ app }}</li>
        </ul>
      </td>
      <td>
        <ul>
          <li *ngFor="let automail of userDetail.automails">
            {{ automail }}
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>{{ userDetail.file_uploads }}</li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
