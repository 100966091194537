import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-wiw-allsubco-details',
  templateUrl: './admin-wiw-allsubco-details.component.html',
  styleUrls: ['./admin-wiw-allsubco-details.component.css']
})
export class AdminWiwAllsubcoDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
