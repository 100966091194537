import { Component, OnInit, Pipe } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '../_models/user';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    // Init vars
    public isLoggedIn: boolean = false;
    public errorMessage: any = false;
    public isLoginFailed: boolean = false;
    public roles: string[] = [];
    public form: any = {
        username: null,
        password: null,
    };

    loading: boolean = false;
    submitted: boolean = false;

    user: User = new User();

    production = environment.production;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    clearMSG() {
        this.errorMessage = false;
    }

    onSubmit(): void {
        this.submitted = true;
        this.loading = true;
        this.clearMSG();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService
            .login(this.user.username || '', this.user.password || '')
            .pipe()
            .subscribe((data) => {
                if (data.error) {
                    this.errorMessage = data.message;
                    this.loading = false;
                } else {
                    this.router.navigate(['/home']);
                }
            });
    }
}
