import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    DoCheck,
} from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { User } from '../../_models/user';
import { UserService } from '../../_services/user.service';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
    // Init variables
    public isAuthenticated: any = false;
    public brandLogo: any;
    currentRoute: string = '';

    @Input() user: any = {};

    production = environment.production;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) {
        this.user = authenticationService.userDetailsValue;
    }

    ngOnInit(): void {
        console.log(this.user);
    }

    logout() {
        this.authenticationService.logout();
    }

    clearUser() {
        this.user = {};
    }
}
