import { Component, Input, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { FileService } from '../../../_services/file.service';

@Component({
    selector: 'app-button-download-techtable',
    template:
        '<button class="btn btn-primary btn-sm w-100" (click)="download()" [disabled]="loading"><i class="fa fa-file-excel"></i> Download</button>',
})
export class ButtonDownloadTechtableComponent implements OnInit {
    @Input() loading: boolean = false;
    constructor(private fileService: FileService) {}

    ngOnInit(): void {}

    download() {
        this.fileService.downloadTechTable().subscribe((response: any) => {
            //when you use stricter type checking
            console.log(response);
            let blob: any = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
            });
            const url = window.URL.createObjectURL(blob);
            //window.open(url);
            //window.location.href = response.url;
            fileSaver.saveAs(blob, 'all_techs.xlsx');
            //}), error => console.log('Error downloading the file'),
        }),
            (error: any) => console.log('Error downloading the file'), //when you use stricter type checking
            () => console.info('File downloaded successfully');
    }
}
