import { AuthenticationService } from './../../../../_services/authentication.service';
import { Router } from '@angular/router';
import { TechService } from './../../../../_services/tech.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-wiw-alltechs-add',
    templateUrl: './admin-wiw-alltechs-add.component.html',
    styleUrls: ['./admin-wiw-alltechs-add.component.css'],
})
export class AdminWiwAlltechsAddComponent implements OnInit {
    area: any;
    pernumber: any;
    name: any;
    firstname: any;
    loading: boolean = false;
    errorMessage: any = false;
    successMessage: any = {};
    user: any;
    constructor(
        private techService: TechService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.authenticationService.userDetails.subscribe((data) => {
            this.user = data;
            console.log(data);
        });
    }

    checkInAreaArray(area: any) {
        if (this.user.area.includes(area)) {
            return true;
        }
        return false;
    }

    onSubmit() {
        this.loading = true;
        this.errorMessage = false;
        this.successMessage = {};
        var temp = {
            techName: this.name,
            techFirstname: this.firstname,
            techArea: this.area,
            techPer: this.pernumber,
        };
        this.techService.addTech(temp).subscribe((data) => {
            console.log(data);
            if (data.error) {
                this.errorMessage = data.message;
            } else {
                this.area = '';
                this.pernumber = '';
                this.name = '';
                this.successMessage.tech_id = data.add_id;
                this.successMessage.message = data.message;
                this.successMessage.workmeter_info = data.workmeter_info;

                this.router.navigate([
                    'admin/wiw/alltechs/edit/' + data.add_id,
                ]);
            }
            this.loading = false;
        });
    }
}
