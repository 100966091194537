import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-go4fiber',
  templateUrl: './go4fiber.component.html',
  styleUrls: ['./go4fiber.component.css']
})
export class Go4fiberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
