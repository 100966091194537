<div class="scroller">
    <span
        *ngFor="
            let tech of allTechs | availabletechspipe | orderBy: 'tech_name';
            index as key
        "
    >
        <div class="row">
            <div class="col-md-1">
                <button
                    class="btn btn-sm btn-outline-info"
                    (click)="alterActiveTech(tech)"
                >
                    <i class="fas fa-car"></i>
                </button>
            </div>
            <div class="col-md-1">
                <button
                    class="btn btn-sm"
                    [ngClass]="{
                        'btn-outline-warning':
                            !tech._isHiddenFromMap && tech.id != activeTech.id,
                        'btn-outline-success':
                            tech._isHiddenFromMap && tech.id != activeTech.id,
                        'btn-warning':
                            !tech._isHiddenFromMap && tech.id == activeTech.id,
                        'btn-success':
                            tech._isHiddenFromMap && tech.id == activeTech.id
                    }"
                    (click)="showTech(tech)"
                >
                    <i class="far fa-eye" *ngIf="tech._isHiddenFromMap"></i>
                    <i
                        class="far fa-eye-slash"
                        *ngIf="!tech._isHiddenFromMap"
                    ></i>
                </button>
            </div>
            <div class="col-md-10">
                <button
                    class="btn btn-sm w-100 text-left"
                    style="margin-bottom: 5px"
                    [ngClass]="{
                        'btn-info': tech.id == activeTech.id,
                        'btn-success':
                            tech._points > 0 && tech.id != activeTech.id,
                        'btn-outline-danger':
                            tech._points == 0 && tech.id != activeTech.id
                    }"
                    (click)="openModal(tech)"
                >
                    #{{ tech._orders.length }} |
                    {{ tech._points | number: '1.2-2' }}% -
                    <span
                        *ngIf="
                            tech.skill_sct_light == 1 &&
                            tech.skill_sct_full == 0
                        "
                        ><strong>(mix)</strong></span
                    >{{ tech.tech_name }}
                </button>
            </div>
        </div>
    </span>
</div>
Total: {{ techCounter }}
