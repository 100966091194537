import { MeetginmapService } from './../../../../_services/meetginmap.service';
import { Component, Input, OnInit } from '@angular/core';
import { workerCount } from 'mapbox-gl';

@Component({
    selector: 'app-mm-admin-insert',
    templateUrl: './mm-admin-insert.component.html',
    styleUrls: ['./mm-admin-insert.component.css'],
})
export class MmAdminInsertComponent implements OnInit {
    @Input() files: any;
    constructor(private meetginmapService: MeetginmapService) {}

    ngOnInit(): void {
        //console.log(this.files);
        this.startInserter();
    }

    async startInserter() {
        for (let iFiles = 0; iFiles < this.files.length; iFiles++) {
            // Set the main progress on 0
            this.files[iFiles].progressInfo = {
                value: 0,
                inserted: 0,
                error: 0,
            };

            for (
                let iWO = 0;
                iWO < this.files[iFiles].data_table.length;
                iWO++
            ) {
                // This WO
                var WO = this.files[iFiles].data_table[iWO];
                WO.file_upload_id = this.files[iFiles].file_upload_id;

                var temp = {
                    data: WO,
                };
                // Do the call to api
                var response = await this.meetginmapService.insert(temp);
                if (response.ok) {
                    // Set the progress info correctly
                    this.files[iFiles].progressInfo.value = Math.round(
                        ((iWO + 1) / this.files[iFiles].data_table.length) * 100
                    );
                    this.files[iFiles].progressInfo.inserted++;
                }

                if (response.error) {
                    this.files[iFiles].progressInfo.error++;
                    console.log(response.message);
                }
            }
        }
    }
}
