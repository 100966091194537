import { TechService } from './../../../_services/tech.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, OperatorFunction, Observable } from 'rxjs';
import { filter } from 'rxjs-compat/operator/filter';
import { merge } from 'rxjs-compat/operator/merge';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'app-g4f-tech-add',
    templateUrl: './g4f-tech-add.component.html',
    styleUrls: ['./g4f-tech-add.component.css'],
})
export class G4fTechAddComponent implements OnInit {
    allTechs: any;

    constructor(private techService: TechService) {}

    ngOnInit(): void {
        this.techService
            .getAllArea()
            .subscribe((data) => (this.allTechs = data));
    }
}
