<table class="table table-sm table-borderd table-hover">
    <thead>
        <tr>
            <th>Name tech</th>
            <th>Status</th>
            <th>Firma</th>
            <th>Area</th>
            <th>Project</th>
            <th>Laskit</th>
            <th>&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Joachim Logghe</td>
            <td>Subco</td>
            <td>APND Gcv</td>
            <td>A1</td>
            <td>Fiberklaar</td>
            <td>
                <button class="btn btn-sm btn-primary">
                    <i class="fas fa-toolbox"></i> 35
                </button>
            </td>
            <td>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-warning w-100">
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-danger w-100">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>Christophe Schoofs</td>
            <td>Payroll</td>
            <td>Janssens Field Services</td>
            <td>A2</td>
            <td>FIS</td>
            <td>
                <button class="btn btn-sm btn-primary">
                    <i class="fas fa-toolbox"></i> 105
                </button>
            </td>
            <td>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-warning w-100">
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-danger w-100">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
