import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  user: any;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.userDetails.subscribe(
      (data) => (this.user = data)
    );
  }

  ngOnInit(): void {}
}
