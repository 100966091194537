import { UserRightsComponent } from './admin/user-rights/user-rights.component';
import { G4fFacturatieComponent } from './go4fiber/g4f-facturatie/g4f-facturatie.component';
import { G4fOnderhoudComponent } from './go4fiber/g4f-onderhoud/g4f-onderhoud.component';
import { G4fKitsComponent } from './go4fiber/g4f-kits/g4f-kits.component';
import { G4fToestellenComponent } from './go4fiber/g4f-toestellen/g4f-toestellen.component';
import { G4fSubcosComponent } from './go4fiber/g4f-subcos/g4f-subcos.component';
import { G4fDashboardComponent } from './go4fiber/g4f-dashboard/g4f-dashboard.component';
import { G4fTechniciansComponent } from './go4fiber/g4f-technicians/g4f-technicians.component';
import { Go4fiberComponent } from './go4fiber/go4fiber.component';
import { KpiverslagComponent } from './admin/kpiverslag/kpiverslag.component';
import { DispatchUploadComponent } from './admin/dispatch/dispatch-upload/dispatch-upload.component';
import { DispatchComponent } from './dispatch/dispatch.component';
import { AdminTechsGridComponent } from './admin/techs/admin-techs-grid/admin-techs-grid.component';
import { AdminWiwSkillsComponent } from './admin/wiw/admin-wiw-skills/admin-wiw-skills.component';
import { AdminWiwAllsubcoAddComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubco-add/admin-wiw-allsubco-add.component';
import { AdminWiwAllsubcoEditComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubco-edit/admin-wiw-allsubco-edit.component';
import { SmsGatewayComponent } from './admin/sms-gateway/sms-gateway.component';
import { TechroutesComponent } from './admin/geo/techroutes/techroutes.component';
import { KvdmapComponent } from './common/map/kvdmap/kvdmap.component';
import { MmMapComponent } from './meetingmap/mm-map/mm-map.component';
import { MmSettingsComponent } from './admin/meetingmap/mm-settings/mm-settings.component';
import { MmAdminComponent } from './admin/meetingmap/mm-admin/mm-admin.component';
import { AutomailComponent } from './admin/automail/automail.component';
import { AdminWiwStatsComponent } from './admin/wiw/admin-wiw-stats/admin-wiw-stats.component';
import { AdminWiwAlltechsDetailsComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-details/admin-wiw-alltechs-details.component';
import { AdminWiwAlltechsEditComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-edit/admin-wiw-alltechs-edit.component';
import { AdminWiwAlltechsAddComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs-add/admin-wiw-alltechs-add.component';
import { AdminWiwAllsubcosComponent } from './admin/wiw/admin-wiw-allsubcos/admin-wiw-allsubcos.component';
import { AdminWiwAlltechsComponent } from './admin/wiw/admin-wiw-alltechs/admin-wiw-alltechs.component';
import { AdminTechsAllComponent } from './admin/techs/admin-techs-all/admin-techs-all.component';
import { AdminUsersAllComponent } from './admin/users/admin-users-all/admin-users-all.component';
import { AlltechsComponent } from './common/map/alltechs/alltechs.component';
import { WiwComponent } from './wiw/wiw.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { SsoComponent } from './sso/sso.component';
import { SmsGatewayV2Component } from './admin/sms-gateway-v2/sms-gateway-v2.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'profile', component: UserProfileComponent },
    { path: 'login', component: LoginComponent },
    { path: 'sso/:jwt', component: SsoComponent },
    { path: 'wiw', component: WiwComponent },
    { path: 'techmap', component: AlltechsComponent },
    { path: 'sms-gateway', component: SmsGatewayComponent },
    { path: 'sms-gateway-v2', component: SmsGatewayV2Component },
    { path: 'admin/users/all', component: AdminUsersAllComponent },
    { path: 'admin/user-rights', component: UserRightsComponent },
    { path: 'admin/techs/all', component: AdminTechsAllComponent },
    { path: 'admin/techs/grid', component: AdminTechsGridComponent },
    { path: 'admin/automail', component: AutomailComponent },
    { path: 'admin/wiw/stats', component: AdminWiwStatsComponent },
    { path: 'admin/wiw/skills', component: AdminWiwSkillsComponent },
    { path: 'admin/wiw/alltechs', component: AdminWiwAlltechsComponent },
    { path: 'admin/wiw/alltechs/add', component: AdminWiwAlltechsAddComponent },
    {
        path: 'admin/wiw/alltechs/edit/:id',
        component: AdminWiwAlltechsEditComponent,
    },
    {
        path: 'admin/wiw/alltechs/details/:id',
        component: AdminWiwAlltechsDetailsComponent,
    },

    { path: 'admin/wiw/allsubcos', component: AdminWiwAllsubcosComponent },
    {
        path: 'admin/wiw/allsubco/add',
        component: AdminWiwAllsubcoAddComponent,
    },
    {
        path: 'admin/wiw/allsubco/edit/:id',
        component: AdminWiwAllsubcoEditComponent,
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'admin/meetingmap/mm-admin', component: MmAdminComponent },
    { path: 'admin/meetingmap/mm-settings', component: MmSettingsComponent },
    { path: 'meetingmap', component: MmMapComponent },
    { path: 'dispatch', component: DispatchComponent },
    { path: 'admin/dispatch-upload', component: DispatchUploadComponent },
    { path: 'kvdmap', component: KvdmapComponent },
    { path: 'admin/techroutes', component: TechroutesComponent },
    { path: 'admin/kpiverslag', component: KpiverslagComponent },
    {
        path: 'admin/go4fiber',
        component: Go4fiberComponent,
        children: [
            {
                path: 'dashboard',
                component: G4fDashboardComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'technicians',
                component: G4fTechniciansComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'subcos',
                component: G4fSubcosComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'toestellen',
                component: G4fToestellenComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'kits',
                component: G4fKitsComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'onderhoud',
                component: G4fOnderhoudComponent,
                outlet: 'g4f_router_outlet',
            },
            {
                path: 'facturatie',
                component: G4fFacturatieComponent,
                outlet: 'g4f_router_outlet',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
