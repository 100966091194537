import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TechService {
    constructor(private http: HttpClient) {}
    private API_URL = environment.apiUrl;

    getAll(): Observable<any> {
        return this.http.get(this.API_URL + 'tech/all', {
            responseType: 'json',
        });
    }

    getAllArea(): Observable<any> {
        return this.http.get(this.API_URL + 'tech/allArea', {
            responseType: 'json',
        });
    }

    workingToday(): Observable<any> {
        return this.http.get(this.API_URL + 'tech/working_today', {
            responseType: 'json',
        });
    }

    getTechById(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'tech/tech/' + id, {
            responseType: 'json',
        });
    }

    getTechBySkill(skill: string): Observable<any> {
        return this.http.get(this.API_URL + 'tech/byskill/' + skill, {
            responseType: 'json',
        });
    }

    getStats(): Observable<any> {
        return this.http.get(this.API_URL + 'wiw/stats', {
            responseType: 'json',
        });
    }

    deleteTechByPer(per: any): Observable<any> {
        return this.http.delete(this.API_URL + 'tech/delete/' + per, {
            responseType: 'json',
        });
    }

    editTech(techObject: any): Observable<any> {
        return this.http.post(this.API_URL + 'tech/update', techObject, {
            responseType: 'text',
        });
    }

    getTechOrders(data: any): any {
        return this.http
            .post(this.API_URL + 'workmeter/getordersdate', data)
            .toPromise()
            .then((r) => {
                return r;
            });
    }

    addTech(techObject: any): Observable<any> {
        return this.http.post(this.API_URL + 'tech/new', techObject);
    }

    getPaginated(
        pageNumber: any,
        searchString: any,
        diffPagination: any = false
    ): Observable<any> {
        var newPagination = '';
        if (diffPagination) {
            newPagination = '/' + diffPagination;
        }
        return this.http.get(
            this.API_URL +
                'wiw/techPaginate/' +
                pageNumber +
                '/' +
                encodeURIComponent(btoa(searchString)) +
                newPagination,
            { responseType: 'json' }
        );
    }
}
