import { TechService } from './../../../_services/tech.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
};

@Component({
  selector: 'app-admin-wiw-stats',
  templateUrl: './admin-wiw-stats.component.html',
  styleUrls: ['./admin-wiw-stats.component.css'],
})
export class AdminWiwStatsComponent implements OnInit {
  chart: any;
  chartApi: any;
  chartOptions: any;
  chartApiOptions: any;

  stats: any;
  constructor(private techService: TechService) {
    this.techService.getStats().subscribe((data) => {
      this.stats = data;

      // Pie chart areas
      this.chartOptions = {
        series: this.stats.charts.techs_by_area.values,
        labels: this.stats.charts.techs_by_area.labels,

        chart: {
          height: 500,
          type: 'pie',
        },
        title: {
          text: 'Techs by area',
        },
      };

      // Wiw api calls
      this.chartApiOptions = {
        series: [
          {
            name: 'ApiCalls(wiw)',
            data: this.stats.charts.wiw_api_calls.values,
          },
        ],
        chart: {
          height: 500,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Api calls WIW',
          align: 'left',
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.stats.charts.wiw_api_calls.labels,
        },
      };
    });
  }

  ngOnInit(): void {}
}
