<div class="container h-100">
    <div class="d-flex justify-content-center h-100">
        <div class="user_card">
            <div class="d-flex justify-content-center">
                <div class="brand_logo_container">
                    <img class="brand_logo" src="assets/img/favicon.png" />
                </div>
            </div>

            <div class="d-flex justify-content-center form_container">
                <form
                    name="loginForm"
                    *ngIf="!isLoggedIn"
                    (ngSubmit)="f.form.valid && onSubmit()"
                    #f="ngForm"
                    novalidate>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <span class="badge bg-danger" *ngIf="!production"
                                ><i class="fas fa-database"></i> Dev</span
                            >
                            <span class="badge bg-success" *ngIf="production"
                                ><i class="fas fa-database"></i> Live</span
                            >
                        </div>
                    </div>
                    <br />
                    <!-- Username -->
                    <div class="row">
                        <div class="input-group mb-3 w-100">
                            <span class="input-group-text" id="basic-addon1"
                                ><i class="fa fa-users"></i
                            ></span>
                            <input
                                type="text"
                                name="username"
                                class="form-control"
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                [(ngModel)]="user.username"
                                required
                                #username="ngModel"
                                [disabled]="loading"
                                (change)="clearMSG()"
                                (focus)="clearMSG()" />
                        </div>
                    </div>
                    <div
                        class="alert alert-danger"
                        role="alert"
                        *ngIf="username.errors && f.submitted">
                        Username is required!
                    </div>

                    <!-- Password -->
                    <div class="input-group mb-3 w-100">
                        <span class="input-group-text" id="basic-addon2"
                            ><i class="fa fa-key"></i
                        ></span>
                        <input
                            type="password"
                            name="password"
                            class="form-control"
                            placeholder="password"
                            aria-label="password"
                            aria-describedby="basic-addon2"
                            required
                            [(ngModel)]="user.password"
                            [disabled]="loading"
                            minlength="6"
                            #password="ngModel"
                            (click)="clearMSG()"
                            (focus)="clearMSG()" />
                    </div>

                    <div
                        class="alert alert-danger"
                        role="alert"
                        *ngIf="password.errors && f.submitted">
                        <div *ngIf="password.errors.required">
                            Password is required
                        </div>
                        <div *ngIf="password.errors.minlength">
                            Password must be at least 6 characters
                        </div>
                    </div>

                    <!-- Error message -->
                    <div
                        class="alert alert-danger"
                        role="alert"
                        *ngIf="f.submitted && errorMessage">
                        {{ errorMessage }}
                    </div>

                    <!-- Submit button -->
                    <div
                        class="d-flex justify-content-center mt-3 login_container">
                        <button
                            name="button"
                            class="btn login_btn"
                            [disabled]="password.errors || username.errors">
                            <i class="fa fa-sign-in-alt"></i>
                        </button>
                    </div>
                </form>
                <div class="alert alert-warning" *ngIf="isLoggedIn">
                    You are already logged in!
                </div>
            </div>
            <br />
        </div>
    </div>
</div>
