import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AutomailService {
    constructor(private http: HttpClient) {}

    private API_URL = environment.apiUrl;

    sendAutomail(id: number, passtrough: number): any {
        return this.http
            .get(this.API_URL + 'automail/mailSend/' + id + '/' + passtrough)
            .toPromise()
            .then((r) => {
                return r;
            });
    }
}
