import { RouterLink } from '@angular/router';
import { Roll } from './roll';

export class User {
    [x: string]: any;
    id?: string;
    username?: string;
    perid?: string;
    area?: any;
    apps?: string[];
    login_method?: string = 'false';
    password?: string;
    token?: string;
    role?: Roll;
}
