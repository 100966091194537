import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SmsService {
    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {}

    insert(body: any): any {
        return this.http
            .post(this.API_URL + 'wiw/sms', body)
            .toPromise()
            .then((r) => {
                return r;
            });
    }

    getTemplates(): Observable<any> {
        return this.http.get(this.API_URL + 'wiw/smstemplate', {
            responseType: 'json',
        });
    }
}
