<div class="row">
    <div class="col-md-1">
        <a
            class="btn btn-secondary w-100"
            [routerLink]="['/admin/wiw/alltechs']"
            ><i class="far fa-hand-point-left"></i> Back</a
        >
    </div>

    <div class="col-md-10">
        <form #f="ngForm" (ngSubmit)="saveForm(f)">
            <div class="alert alert-info">
                <i class="fas fa-search"></i> Details for
                {{ tech?.tech_name | titlecase }}
            </div>
            <table class="table table-sm table-bordered table-hover">
                <thead>
                    <th>Variable</th>
                    <th>Value</th>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let item of tech | keyvalue : asIsOrder;
                            let i = index
                        "
                        [ngStyle]="{
                            display: checkHidden(item.key) ? 'none' : ''
                        }">
                        <td class="font-italic">{{ item.key }}</td>
                        <td
                            [ngClass]="{
                                'bg-warning': !item.value,
                                'bg-secondary': getItem(item.key) == 'print'
                            }">
                            <span
                                *ngIf="getItem(item.key) == 'print'"
                                class="text-light"
                                >{{ item.value }}</span
                            >
                            <input
                                name="{{ item.key }}"
                                class="form-control"
                                type="text"
                                *ngIf="getItem(item.key) == 'text'"
                                [(ngModel)]="item.value"
                                [disabled]="loading" />
                            <select
                                name="{{ item.key }}"
                                class="form-control"
                                *ngIf="getItem(item.key) == 'select'"
                                [disabled]="loading"
                                [(ngModel)]="item.value">
                                <option
                                    *ngFor="
                                        let value of getItemContent(item.key)
                                            .values
                                    "
                                    [value]="value">
                                    {{ value }}
                                </option>
                            </select>
                            <!--SUBCOS-->
                            <select
                                name="{{ item.key }}"
                                class="form-control"
                                *ngIf="getItem(item.key) == 'subcos'"
                                [disabled]="loading"
                                [(ngModel)]="item.value">
                                <option
                                    *ngFor="let value of subcos"
                                    [value]="value.id">
                                    {{ value.name }}
                                </option>
                            </select>
                            <!-- SUBCOS-->
                            <label
                                class="switch"
                                *ngIf="
                                    getItem(item.key) == 'checkbox' &&
                                    !checkHidden(item.key)
                                ">
                                <input
                                    name="{{ item.key }}"
                                    type="checkbox"
                                    [(ngModel)]="item.value"
                                    [disabled]="loading" />
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button
                type="submit"
                class="btn btn-primary btn-sm"
                [disabled]="loading">
                <i class="fa fa-save" *ngIf="!loading"></i
                ><i class="fa fa-spinner fa-pulse" *ngIf="loading"></i> Save
            </button>
        </form>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
