import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { TechService } from '../../../_services/tech.service';
import { of } from 'rxjs';

@Component({
    selector: 'app-alltechs',
    templateUrl: './alltechs.component.html',
    styleUrls: ['./alltechs.component.css'],
})
export class AlltechsComponent implements OnInit {
    map: any;
    marker: any;
    style = 'mapbox://styles/mapbox/streets-v11';
    allTechs: any;

    allMarkers: any = [];

    loading: boolean = false;

    skills: any = [
        {
            name: 'skill_pxm',
            text: 'PXM - Mass market',
            color: '#03B7EA',
            selected: false,
        },
        {
            name: 'skill_proff',
            text: 'PXM - Prof market',
            color: '#5A2C8F',
            selected: false,
        },
        {
            name: 'skill_fiber_fis',
            text: 'PXM - FIS',
            color: '#B1B8CB',
            selected: false,
        },
        {
            name: 'skill_sct_full',
            text: 'Scarlet',
            color: '#C60404',
            selected: false,
        },
        {
            name: 'skill_sct_light',
            text: 'Scarlet (light)',
            color: '#FA4204',
            selected: false,
        },
        {
            name: 'mixplan',
            text: 'Mixplan',
            color: '#04C73B',
            selected: false,
        },
    ];

    public screenWidth: any;
    public screenHeight: any;

    constructor(private techService: TechService) {}

    ngOnDestroy() {
        this.map.remove();
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 200;

        this.map = new mapboxgl.Map({
            container: 'mapTechmap',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 7,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            console.log('loading map');
            this.map.resize();
        });

        this.map.addControl(new mapboxgl.NavigationControl());

        this.getTechs();
    }

    mapResizer() {
        this.map.on('load', () => {
            console.log('loading map');
            this.map.resize();
        });
    }

    clearSkillSelect() {
        this.skills.forEach((v: any, k: any) => {
            this.skills[k].selected = false;
        });
    }

    setAllSkill() {
        this.skills.forEach((v: any, k: any) => {
            if (this.skills[k].name != 'mixplan') {
                this.skills[k].selected = true;
            }
        });
    }

    // Get all the techs
    getTechs() {
        return this.techService.getAll().subscribe((response) => {
            this.setTech(response);
            console.log(response);
        });
    }

    setTech(data: any) {
        this.allTechs = data;
        this.mapResizer();
    }

    clearMap(clearSkills = true) {
        for (var i = 0; i < this.allMarkers.length; i++) {
            this.allMarkers[i].remove();
        }
        this.allMarkers = [];

        if (clearSkills) {
            this.clearSkillSelect();
        }
    }

    // This will add/delete markers from the map based on selected skills
    changeSkillView(event: any, skillindex: any) {
        console.log(event);
        this.clearMap(false);

        this.skills[skillindex].selected = event.target.checked;

        // Loop all the skills
        this.skills.forEach((skill: any) => {
            if (skill.selected) {
                // Now add all the techs with this skill
                this.allTechs.forEach((techElement: any) => {
                    if (techElement.geocode) {
                        if (techElement[skill.name] == 1) {
                            this.allMarkers.push(
                                this.createMarker(techElement, skill.color)
                            );
                        }
                        console.log('added');
                    } else {
                        console.log('geocode error' + techElement.tech_name);
                    }
                });
            }
        });
    }

    getColor(type: string, techObj: any) {
        if (type == 'all') {
            if (techObj.skill_sct_full == 1) {
                return '#C60404';
            }
            if (techObj.skill_pxm == 1) {
                return '#03B7EA';
            }
            if (techObj.skill_proff == 1) {
                return '#5A2C8F';
            }
            if (techObj.skill_fiber_fis == 1) {
                return '#B1B8CB';
            }
            if (techObj.skill_sct_light == 1) {
                return '#FA4204';
            }
        }

        return '#000001';
    }

    addAllTechs() {
        this.clearMap();

        this.setAllSkill();

        this.allTechs.forEach((element: any) => {
            if (element.geocode) {
                this.allMarkers.push(this.createMarker(element));
            } else {
                console.log('geocode error' + element.tech_name);
            }
        });
    }

    createMarker(element: any, colorPush: any = false) {
        var colorT = '';
        if (!colorPush) {
            colorT = this.getColor('all', element); //colorsCode[element.pxm_calendar],
        } else {
            colorT = colorPush;
        }
        var marker = new mapboxgl.Marker({
            color: colorT,
        })
            .setLngLat(
                new mapboxgl.LngLat(element.geocode[0], element.geocode[1])
            )
            .setPopup(
                new mapboxgl.Popup().setHTML(
                    '<strong>' +
                        element.pxm_per +
                        ' - ' +
                        element.tech_name +
                        '</strong><br />' +
                        '<i class="fas fa-home"></i> ' +
                        element.tech_zip_city +
                        '<br />' +
                        '<i class="fas fa-phone-square-alt"></i> ' +
                        element.tech_tel_work +
                        '<br />' +
                        '<table class="table table-sm table-hover table-bordered">' +
                        '<thead>' +
                        '<tr>' +
                        '<th>Skill</th>' +
                        '<th>&nbsp;</th>' +
                        '</thead>' +
                        '<tbody>' +
                        this.skillsTableMarker(element) +
                        '</tbody>' +
                        '</table>'
                )
            )
            .addTo(this.map);
        // Do the popover shit
        const markerDiv = marker.getElement();
        markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
        markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
        return marker;
    }

    skillsTableMarker(tech: any) {
        var html = '';
        this.skills.forEach((element: any) => {
            html += '<tr>';
            html +=
                '<td style="background-color: ' +
                element.color +
                ';">' +
                element.text +
                '</td>';
            if (tech[element.name] == 1) {
                html += '<td><i class="fas fa-check-circle"></i></td>';
            } else {
                html += '<td></td>';
            }
            html += '</tr>';
        });
        return html;
    }

    addPayrollSubco() {
        this.clearMap();

        var colorsCode: any = {
            Payroll: '#2E86C1',
            Subco: '#D68910',
        };

        this.addMarkers(colorsCode, 'firm_status');
    }

    addPayroll(type: any = 'Payroll') {
        this.clearMap();

        var colorCodes: any = {
            Payroll: '#2E86C1',
            Subco: '#D68910',
        };

        this.allTechs.forEach((element: any) => {
            if (element.geocode && element.firm_status == type) {
                this.allMarkers.push(
                    this.createMarker(element, colorCodes[element.firm_status])
                );
            } else {
                console.log('geocode error' + element.tech_name);
            }
        });
    }

    addMarkers(colorcodes: any, colorcheck: string) {
        var colorCodes: any = {
            Payroll: '#2E86C1',
            Subco: '#D68910',
        };
        this.allTechs.forEach((element: any) => {
            if (element.geocode) {
                this.allMarkers.push(
                    this.createMarker(element, colorCodes[element.firm_status])
                );
            } else {
                console.log('geocode error' + element.tech_name);
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight - 100;
    }
}
