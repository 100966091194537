import { GeoService } from './../../../_services/geo.service';
import { TechService } from './../../../_services/tech.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TechroutesMapComponent } from './techroutes-map/techroutes-map.component';

@Component({
    selector: 'app-techroutes',
    templateUrl: './techroutes.component.html',
    styleUrls: ['./techroutes.component.css'],
})
export class TechroutesComponent implements OnInit {
    techtype: string = '';
    techs: any;
    date: any = {};
    techOrders: boolean = false;
    mapData: any = false;
    loading: boolean = false;

    step: string = 'get_techs';

    progressbar: boolean = false;
    progress: number = 0;
    progressvalue: any;

    @ViewChild(TechroutesMapComponent) child:
        | TechroutesMapComponent
        | undefined;

    constructor(
        private techService: TechService,
        private geoService: GeoService
    ) {}

    ngOnInit(): void {}

    initProgress() {
        this.progressbar = false;
        this.progress = 0;
        this.progressvalue = 0;
    }

    restart() {
        this.techtype = '';
        this.techs = false;
        this.date = {};
        // Fix to auto do something

        this.techOrders = false;
        this.mapData = false;
        this.loading = false;
        this.step = 'get_techs';
        this.initProgress();
    }

    getTechs(skill: string) {
        this.loading = true;
        this.techOrders = false;
        this.techService.getTechBySkill(skill).subscribe((data) => {
            for (var i = 0; i < data.length; i++) {
                data[i].color = this.getRandomColor();
                data[i].orders = {};
            }
            this.techs = data;

            this.loading = false;

            this.step = 'get_orders';
        });
    }

    async getOrders() {
        // Set the loading
        this.loading = true;
        this.mapData = false;
        this.progressbar = true;

        // Put the date textboxes together
        var dateMerge =
            this.date.year + '-' + this.date.month + '-' + this.date.day;

        // Loop the tech object
        for (let i = 0; i < this.techs.length; i++) {
            // Create a single call to server
            var data = {
                date: dateMerge,
                techs: [this.techs[i]],
                type: this.techtype,
            };

            var response = await this.techService.getTechOrders(data);

            if (response) {
                this.techs[i].orders = response[0].orders;

                this.progress++;

                this.progressvalue = Math.round(
                    ((i + 1) / this.techs.length) * 100
                );
            }
        }

        this.techOrders = true;
        this.loading = false;
        this.initProgress();

        this.step = 'calculate_route';

        // Increase the step to the next one

        /*
        var data = { date: dateMerge, techs: this.techs, type: this.techtype };
        this.techService.getTechOrders(data).subscribe((data) => {
            this.techOrders = JSON.parse(data);
            this.loading = false;
        });
        */
    }

    getOrderLength(orders: any) {
        if (orders) {
            return Object.keys(orders).length;
        }
        return 0;
    }

    async calculateRoutes() {
        this.loading = true;
        this.progressbar = true;

        this.mapData = [];

        for (let i = 0; i < this.techs.length; i++) {
            // Create a single call to server

            var data = [
                {
                    tech: this.techs[i],
                    orders: this.techs[i].orders,
                },
            ];

            var response = await this.geoService.calculateRoute(data);

            if (typeof response !== 'undefined') {
                if (typeof response[0] !== 'undefined') {
                    // Push the return to the maps
                    this.mapData.push(response[0]);

                    // Only add when orderlength is set
                    if (this.getOrderLength(response[0].orders)) {
                        this.child?.loadRoutes(response[0], i);

                        // Alter tech object
                        this.techs[i].driving_time = (
                            response[0].driving_layer.trips[0].duration /
                            60 /
                            60
                        ).toFixed(2);
                        this.techs[i].driving_distance = (
                            response[0].driving_layer.trips[0].distance / 1000
                        ).toFixed(0);

                        this.progress++;
                    }
                }
            }

            this.progressvalue = Math.round(
                ((i + 1) / this.techs.length) * 100
            );
        }

        this.step = 'finish';
        this.initProgress();

        /*
        // Loop the techs and only add the techs which have orders
        var data = [];
        for (var i = 0; i < this.techOrders.length; i++) {
            if (this.getOrderLength(this.techOrders[i].orders)) {
                data.push(this.techOrders[i]);
            }
        }

        this.geoService.calculateRoute(data).subscribe((data) => {
            this.mapData = JSON.parse(data);
            this.loading = false;
        });
        */
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
}
