import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'areaCountShowArea',
})
export class AreaCountShowAreaPipe implements PipeTransform {
    transform(items: any): any {
        if (!items) {
            return items;
        }

        return Object.values(items).filter(
            (item: any) => item.addedToMap == true
        );
    }
}
