<ngb-accordion #acc="ngbAccordion" activeIds="main_controls,users,orders">
    <ngb-panel id="main_controls">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">
                    <span><i class="fas fa-cogs"></i> Controls</span>
                </h5>
                <div>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-danger ml-2"
                        (click)="acc.collapseAll()"
                    >
                        Collapse all</button
                    >&nbsp;
                    <button
                        ngbPanelToggle
                        class="btn btn-sm"
                        [ngClass]="
                            opened
                                ? 'btn-outline-warning'
                                : 'btn-outline-success'
                        "
                    >
                        <i class="far fa-folder" *ngIf="opened"></i>
                        <i class="far fa-folder-open" *ngIf="!opened"></i>
                    </button>
                </div>
            </div>
        </ng-template>

        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-md-6">
                    <button
                        class="btn btn-sm btn-outline-primary w-100"
                        (click)="openOrdersModal()"
                    >
                        <i class="fas fa-map-marked-alt"></i> 1. Orders
                    </button>
                </div>
                <div class="col-md-6">
                    <button
                        class="btn btn-sm btn-outline-primary w-100"
                        (click)="openTechModal()"
                    >
                        <i class="fas fa-users-cog"></i> 2. Technicians
                    </button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-6">
                    <button
                        class="btn btn-sm btn-outline-primary w-100"
                        (click)="openExportModal()"
                    >
                        <i class="fas fa-download"></i> 3. Export
                    </button>
                </div>
                <div class="col-md-6">
                    <button
                        class="btn btn-sm btn-outline-danger w-100"
                        (click)="openToolsModal()"
                    >
                        <i class="fas fa-tools"></i> Tools
                    </button>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="users">
        <ng-template ngbPanelHeader let-opened="opened1">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0"><i class="fas fa-users"></i> Techs</h5>
                <div>
                    <button
                        ngbPanelToggle
                        class="btn btn-sm"
                        [ngClass]="
                            opened1
                                ? 'btn-outline-warning'
                                : 'btn-outline-success'
                        "
                    >
                        <i class="far fa-folder" *ngIf="opened1"></i>
                        <i class="far fa-folder-open" *ngIf="!opened1"></i>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-dispatch-sidebar-techs
                [allTechs]="allTechs"
                [activeTech]="activeTech"
                (showTechMapEvent)="showTechMapChange($event)"
                (makeOrderVisibleEvent)="makeOrderVisibleChange($event)"
                (changeActiveTechEvent)="changeActiveTechChange($event)"
            ></app-dispatch-sidebar-techs>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="orders">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">
                    <span><i class="fas fa-map-marked-alt"></i> Orders</span>
                </h5>
                <div>
                    <button
                        ngbPanelToggle
                        class="btn btn-sm"
                        [ngClass]="
                            opened
                                ? 'btn-outline-warning'
                                : 'btn-outline-success'
                        "
                    >
                        <i class="far fa-folder" *ngIf="opened"></i>
                        <i class="far fa-folder-open" *ngIf="!opened"></i>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <table
                class="table table-sm table-bordered"
                *ngIf="countObject(areaCount)"
            >
                <tbody>
                    <tr *ngFor="let area of areaCount | areaCountShowArea">
                        <td style="width: 10%">{{ area.area }}</td>
                        <td>
                            <ngb-progressbar
                                type="success"
                                [value]="area.assigned"
                                [max]="area.total"
                                ><span class="progress-text"
                                    >{{ area.assigned }}/{{ area.total }}</span
                                ></ngb-progressbar
                            >
                        </td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>
                            {{ totalOrders }} ({{
                                techsNeeded | number: '1.0-0'
                            }}
                            techs needed)
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
