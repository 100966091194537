import { MeetginmapService } from './../../_services/meetginmap.service';
import {
    Component,
    OnInit,
    Input,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-mm-map',
    templateUrl: './mm-map.component.html',
    styleUrls: ['./mm-map.component.css'],
})
export class MmMapComponent implements OnInit {
    // Declare some vars
    map: any;
    style = 'mapbox://styles/mapbox/streets-v11';

    loading: boolean = true;
    errorMessage: any = false;
    successMessage: any = false;
    loadingText: string =
        '<span class="text-center">Meetingmap is loading</span>';

    popupDetails: any = false;

    networkPolygon: any;
    orders: any;

    public screenWidth: any;
    public screenHeight: any;

    constructor(
        private authenticationService: AuthenticationService,
        private meetginmapService: MeetginmapService
    ) {}

    ngOnDestroy() {
        this.map.remove();
        this.map = null;
    }

    ngOnInit(): void {
        this.screenWidth = '100%';
        this.screenHeight = window.innerHeight - 100;

        this.map = new mapboxgl.Map({
            container: 'mapMeetingmapMain',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 8,
            center: [4.34878, 50.85045],
            accessToken: environment.mapboxAccessToken,
        });

        this.map.on('load', () => {
            this.map.resize();
            this.loadNetworkPolygons();
        });
    }

    loadNetworkPolygons() {
        this.loadingText += '<br /> - Networks downloaded';
        this.meetginmapService.getNetworkPolygon().subscribe((data) => {
            this.networkPolygon = data;
            console.log(data);
            this.loadMapContent(data);
        });
    }

    loadMapContent(networkPolygon: any) {
        this.loadingText += '<br />- Networks are rendered';

        var polygonData = {
            type: 'FeatureCollection',
            features: networkPolygon,
        };

        this.map.addSource('networks', {
            type: 'geojson',
            data: polygonData,
        });

        console.log(polygonData);

        // Add a new layer to visualize the polygon.
        this.map.addLayer({
            id: 'maine',
            type: 'fill',
            source: 'networks', // reference the data source
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['==', ['get', 'totalorders'], 0],
                    '#343a40',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#f0ad4e',
                    ['==', ['get', 'mmopen'], '1'],
                    '#5cb85c',
                    ['==', ['feature-state', 'statuschange'], 1],
                    '#5cb85c',
                    '#d9534f',
                ],
                'fill-opacity': 0.3,
            },
        });
        // Add a black outline around the polygon.
        this.map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'networks',
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 0.2,
            },
        });

        this.loadOrders();
    }

    loadOrders() {
        this.loadingText += '<br /> - Open orders downloaded';
        this.meetginmapService.getOpenOrders().subscribe((data) => {
            if (data.error) {
                this.errorMessage = data.message;
            } else {
                this.orders = data.meetings;
                //console.log(data);
                this.visualizeOrders();
            }
        });
    }

    visualizeOrders() {
        this.loadingText += '<br /> - Visualizing orders';

        this.orders.forEach((element: any) => {
            if (element.geocode) {
                this.addMarker(element);
            } else {
                console.log('geocode error' + element.id);
            }
        });
    }

    addMarker(element: any) {
        var colorsCode: any = {
            Copper: '#263AA9',
            Fiber: '#FCFF1E',
            FISS: '#FF1EB4',
            Active: 'red',
        };
        var markerElement: any = new mapboxgl.Marker({
            color: colorsCode[element.wb_type],
            scale: 0,
        })

            .setLngLat(
                new mapboxgl.LngLat(element.geocode[0], element.geocode[1])
            )

            .setPopup(
                new mapboxgl.Popup().on(
                    'open',
                    (e, f: any = element, g: any = markerElement) => {
                        // Clear errormessage & successmessage
                        this.errorMessage = false;
                        this.successMessage = false;

                        // Remove the previous element and create a new one
                        if (this.popupDetails.marker !== undefined) {
                            this.popupDetails.marker.remove();
                            this.popupDetails.element.wb_type =
                                this.popupDetails.element.prev_wb_type;
                            this.addMarker(this.popupDetails.element);
                        }

                        markerElement.remove();

                        element.prev_wb_type = element.wb_type;
                        element.wb_type = 'Active';

                        var data = {
                            marker: this.addMarker(element),
                            element: element,
                        };

                        this.popupDetails = data;
                    }
                )
            )
            .addTo(this.map);

        return markerElement;
    }

    markerEvent(event: any, orderID: any, markerElement: any) {
        markerElement.remove();
        console.log(orderID);

        this.meetginmapService.techchange(event, orderID).subscribe((data) => {
            console.log(data);
            if (data.success) {
                this.successMessage = data.message;
            }
            if (data.error) {
                this.errorMessage = data.message;
            }
        });
        this.popupDetails = false;
    }
}
