<div class="row">
    <div class="col-md-12">
        <div class="alert alert-info">
            <h2><i class="far fa-meh-rolling-eyes"></i> Mail preview</h2>
            <div id="accordion">
                <div class="card" *ngFor="let mail of mails.ok; index as i">
                    <div class="card-header" id="headingOne">
                        <!--Index-->
                        <h5 class="mb-0">
                            <div class="row">
                                <div class="col-md-1">
                                    <button
                                        class="btn btn-sm btn-info"
                                        *ngIf="
                                            mail.mailsend != 'error' &&
                                            mail.mailsend != 'done' &&
                                            mail.mailsend != 'sending' &&
                                            !mailSending
                                        "
                                        (click)="resendMail(i)"
                                        ng-disabled="mailResendSending">
                                        <i class="far fa-paper-plane"></i> Send
                                        only this one
                                    </button>
                                    <button
                                        class="btn btn-sm btn-danger"
                                        *ngIf="mail.mailsend == 'error'"
                                        (click)="resendMail(i)"
                                        ng-disabled="mailResendSending">
                                        <i class="fas fa-undo"></i> Resend
                                    </button>
                                </div>
                                <div class="col-md-1">
                                    <i
                                        class="fas fa-shipping-fast text-success"
                                        *ngIf="mail.mailsend == 'done'"></i>
                                    <i
                                        class="fas fa-spinner fa-pulse"
                                        *ngIf="mail.mailsend == 'sending'"></i>
                                    <i
                                        class="fas fa-exclamation-triangle text-danger"
                                        *ngIf="mail.mailsend == 'error'"></i>
                                    <i
                                        class="fas fa-inbox"
                                        *ngIf="
                                            mailSending &&
                                            mail.mailsend != 'sending' &&
                                            mail.mailsend != 'done' &&
                                            mail.mailsend != 'error' &&
                                            !mailResend
                                        "></i>
                                </div>
                                <div class="col-md-8 text-left border-left">
                                    <button
                                        class="btn btn-link"
                                        data-toggle="collapse"
                                        [attr.data-target]="'#collapseOne' + i"
                                        [attr.aria-control]="'collapseOne' + i"
                                        aria-expanded="true"
                                        (click)="showHide(i)">
                                        {{ mail.subject }}
                                    </button>
                                </div>
                                <div class="col-md-2 text-right border-left">
                                    <span *ngIf="mail.subco">Subco</span>
                                    <span *ngIf="!mail.subco"
                                        >Payroll/Single independant</span
                                    >
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-md-12 text-danger"
                                    *ngIf="mail.mailsend == 'error'">
                                    <small>{{ mail.mailsenderror }}</small>
                                </div>
                            </div>
                        </h5>
                    </div>

                    <div
                        id="collapseOne{{ i }}"
                        *ngIf="showCollapse(i)"
                        aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card-body">
                                    <h3>To:</h3>
                                    <ul>
                                        <li
                                            *ngFor="
                                                let adres of mail.mail_send_to
                                                    .to
                                            ">
                                            {{ adres }}
                                        </li>
                                    </ul>
                                    <h3>CC:</h3>
                                    <ul>
                                        <li
                                            *ngFor="
                                                let adres of mail.mail_send_to
                                                    .cc
                                            ">
                                            {{ adres }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-9 border-left">
                                <div
                                    class="card-body"
                                    [innerHtml]="mail.mail"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="alert alert-danger">
                Mails NOK:<br />
                <ul>
                    <li *ngFor="let mailnok of mails.nok">
                        {{ mailnok }}
                    </li>
                </ul>
            </div>
            <br />
            <button
                class="btn btn-primary"
                (click)="sendMails()"
                [disabled]="mailSending"
                *ngIf="!mailsAllSend">
                <i class="fas fa-spinner fa-pulse" *ngIf="mailSending"></i
                ><i class="far fa-laugh-beam" *ngIf="!mailSending"></i> Send all
                mails @ once
            </button>
            <div class="alert alert-success" *ngIf="mailsAllSend">
                All mails send
            </div>
        </div>
    </div>
</div>
