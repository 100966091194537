import {
    Component,
    OnInit,
    Input,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-techmap',
    template: `<div class="map" id="mapTechMap" class="map-container"></div>`,
    styles: ['.map-container { width: 100%; height: 100%; }'],
})
export class TechmapComponent implements OnInit {
    map: any;
    marker: any;
    style = 'mapbox://styles/mapbox/streets-v11';

    @Input() tech: any;

    constructor() {}

    ngOnDestroy() {
        this.map.remove();
        this.map = null;
    }

    ngOnInit(): void {
        console.log('init map');
        this.map = new mapboxgl.Map({
            container: 'mapTechMap',
            style: this.style,
            maxTileCacheSize: 256,
            zoom: 8,
            center: this.tech.geocode,
            accessToken: environment.mapboxAccessToken,
        });

        this.addMarker(this.tech);

        this.map.addControl(new mapboxgl.NavigationControl());
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentItem: SimpleChange = changes.tech;
        if (!currentItem.firstChange) {
            this.marker.remove();
            this.map.flyTo({ center: currentItem.currentValue.geocode });
            this.addMarker(currentItem.currentValue);
        }
    }

    addMarker(tech: any): void {
        var colorsCode: any = {
            C1DWAA: '#2E86C1',
            C1DEAA: '#2E86C1',
            C3DAAA: '#D68910',
            C3DEAA: '#D68910',
            PROFF: '#229954',
            C6DAAA_AUTO_GEN: '#A93226',
        };
        var geojson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {
                        message: 'Baz',
                        iconSize: [40, 40],
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: tech.geocode,
                    },
                },
            ],
        };
        this.marker = new mapboxgl.Marker({
            color: colorsCode[tech.pxm_calendar],
        })
            .setLngLat(new mapboxgl.LngLat(tech.geocode[0], tech.geocode[1]))
            .addTo(this.map);

        // Add map controls
        //this.map.addControl(new mapboxgl.NavigationControl());
    }
}
