import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FileService } from 'src/app/_services/file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-mm-admin-file-upload',
    templateUrl: './mm-admin-file-upload.component.html',
    styleUrls: ['./mm-admin-file-upload.component.css'],
})
export class MmAdminFileUploadComponent implements OnInit {
    selectedFiles?: FileList;
    progressInfos: any[] = [];
    message: string[] = [];
    errorMessage: string = '';
    uploadStart: boolean = false;

    uploadedFileIDs: string[] = [];
    @Output() uploadedFileIDsChanged: EventEmitter<any> = new EventEmitter();

    allDone: boolean = false;
    @Output() allDoneChanged: EventEmitter<any> = new EventEmitter();

    fileInfos?: Observable<any>;
    constructor(private fileService: FileService) {}

    ngOnInit(): void {}

    selectFiles(event: any): void {
        this.message = [];
        this.progressInfos = [];
        this.selectedFiles = event.target.files;
    }

    uploadFiles(): void {
        this.message = [];
        this.uploadStart = true;

        if (this.selectedFiles) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.upload(i, this.selectedFiles[i]);
            }
        }
    }

    upload(idx: number, file: File): void {
        this.progressInfos[idx] = { value: 0, fileName: file.name };

        if (file) {
            this.fileService.uploadMeetingmap(file).subscribe(
                (event: any) => {
                    this.errorMessage = '';

                    if (event.body !== undefined) {
                        if (event.body.error !== undefined) {
                            this.errorMessage = event.body.message;
                        }
                    }
                    if (!this.errorMessage.length) {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progressInfos[idx].value = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        } else if (event instanceof HttpResponse) {
                            const msg =
                                'Uploaded the file successfully: ' + file.name;
                            this.message.push(msg);
                            this.uploadedFileIDs.push(event.body);
                            this.uploadedFileIDsChanged.emit(
                                this.uploadedFileIDs
                            );

                            // Check if all is done
                            if (
                                this.selectedFiles?.length ==
                                this.uploadedFileIDs.length
                            ) {
                                this.allDone = true;
                                this.allDoneChanged.emit(this.allDone);
                            }
                            //this.fileInfos = this.fileService.getFiles();
                        }
                    }
                },
                (err: any) => {
                    this.progressInfos[idx].value = 0;
                    const msg = 'Could not upload the file: ' + file.name;
                    this.message.push(msg);
                    //this.fileInfos = this.fileService.getFiles();
                }
            );
        }
    }
}
