{{ model | json }}
{{ tech }}
<span *ngIf="!model"
    >Begin hier naast in te vullen om de mail te genereren</span
>
<div class="final_mail" *ngIf="model">
    Beste Dirk,<br />
    <br />
    Op {{ datum }} ben ik, {{ user }}, mee op baan geweest met technieker
    {{ tech.tech_name }} ({{ tech.pxm_per }}).<br />
    <br />
</div>
