import { Component, OnInit } from '@angular/core';
import { TechService } from '../../../_services/tech.service';

@Component({
  selector: 'app-admin-techs-all',
  templateUrl: './admin-techs-all.component.html',
  styleUrls: ['./admin-techs-all.component.css'],
})
export class AdminTechsAllComponent implements OnInit {
  allTechs: any;
  errorMessage = '';
  constructor(private techService: TechService) {}

  ngOnInit() {
    return this.techService
      .getAll()
      .subscribe((response) => (this.allTechs = response));
  }
}
