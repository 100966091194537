import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-automail-type',
    templateUrl: './automail-type.component.html',
    styleUrls: ['./automail-type.component.css'],
})
export class AutomailTypeComponent implements OnInit {
    @Input() user: any;
    @Input() loading: any;
    @Output() automailChanged: EventEmitter<any> = new EventEmitter();

    selectedAutomail: any = false;

    constructor() {}

    ngOnInit(): void {}

    setAutomail(automail: any) {
        console.log(automail);
        if (automail == '') {
            this.selectedAutomail = false;
        } else {
            this.selectedAutomail = automail;
        }
        this.selectedAutomail = automail;
        this.automailChanged.emit(this.selectedAutomail);
    }
}
