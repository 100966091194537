<table class="table table-sm table-bordered" *ngIf="allDone">
    <thead>
        <tr>
            <th>Filename</th>
            <th>Original filename</th>
            <th>Upload date</th>
            <th>Items</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let file of fileInfo">
            <td>{{ file.filename }}</td>
            <td>{{ file.original_filename }}</td>
            <td>{{ file.upload_date }}</td>
            <td>{{ file.items }}</td>
        </tr>
    </tbody>
</table>
