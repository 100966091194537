import { Subco } from './../../../../_models/subco';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SubcoService } from './../../../../_services/subco.service';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
    FormArray,
} from '@angular/forms';

@Component({
    selector: 'app-admin-wiw-allsubco-edit',
    templateUrl: './admin-wiw-allsubco-edit.component.html',
    styleUrls: ['./admin-wiw-allsubco-edit.component.css'],
})
export class AdminWiwAllsubcoEditComponent implements OnInit {
    allSubcos: any;
    errorMessage: any = {};
    successMessage: any = {};
    loading: boolean = false;
    mainSubco: any;

    subcoID: number = -1;

    editForm = new FormGroup({
        firm_name: new FormControl('', [Validators.required]),
        main: new FormControl('', [Validators.required]),
        contacts: new FormArray([]),
    });

    contacts = this.editForm.get('contacts') as FormArray;

    deletedContacts: any = [];

    constructor(
        public formBuilder: FormBuilder,
        private subcoService: SubcoService,
        private actRoute: ActivatedRoute
    ) {
        // Generate the contacts group

        this.subcoService

            .getById(this.actRoute.snapshot.params.id)
            .subscribe((data) => {
                console.log(data);
                if (!data.main) {
                    data.main = 1;
                }

                this.subcoID = data.id;
                this.editForm.patchValue({
                    firm_name: data.firm_name,
                    main: data.main,
                });

                this.mainSubco = data.main.id;

                // Add the contacts
                for (const contact of data.contacts) {
                    this.addContact(contact);
                }

                this.subcoService.getAll().subscribe((data) => {
                    this.allSubcos = data;
                });
            });
    }

    ngOnInit(): void {}

    get firm_name() {
        return this.editForm.get('firm_name');
    }

    get main() {
        return this.editForm.get('main');
    }

    addContact(
        contactVal: any = {
            id: 0,
            name: '',
            firstname: '',
            phone: '',
            mail: '',
        }
    ) {
        const contact = new FormGroup({
            id: new FormControl(contactVal.id),
            name: new FormControl(contactVal.name, Validators.required),
            firstname: new FormControl(
                contactVal.firstname,
                Validators.required
            ),
            phone: new FormControl(contactVal.phone, Validators.required),
            mail: new FormControl(contactVal.mail, Validators.required),
        });

        this.contacts.push(contact);
    }

    deleteContact(iDelete: number) {
        this.deletedContacts.push(
            this.contacts.controls[iDelete].get('id')?.value
        );
        this.contacts.removeAt(iDelete);
    }

    onSubmit() {
        if (this.editForm.valid) {
            var data = {
                id: this.subcoID,
                firm_name: this.firm_name?.value,
                main: this.main?.value,
                contacts: this.contacts?.value,
                deletedContacts: this.deletedContacts,
            };

            this.subcoService.edit(data).subscribe((data) => {
                if (data.success) {
                    this.successMessage.message = data.message;
                } else {
                    this.errorMessage.message = data.message;
                }
                console.log(data);
            });
        }
    }
}
