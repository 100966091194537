<h1><i class="fa fa-map-marked-alt"></i> Techmap</h1>
<hr />
<div class="row">
    <div class="col-md-3">
        <div class="row">
            <div class="col-md-12">
                <h2>By project</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-primary w-100" (click)="addAllTechs()">
                    All techs (by project)
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-4">By project</div>
            <div class="col-md-8">
                <table class="table table-sm table-hover">
                    <tr *ngFor="let skill of skills; let i = index">
                        <td [ngStyle]="{ 'background-color': skill.color }">
                            <input
                                type="checkbox"
                                [(ngModel)]="skills[i].selected"
                                (change)="changeSkillView($event, i)"
                                value="1"
                            />
                        </td>
                        <td>{{ skill.text }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <h2>By subco</h2>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <button
                    class="btn btn-primary w-100"
                    (click)="addPayrollSubco()"
                >
                    All techs
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-info w-100" (click)="addPayroll()">
                    Payrol only
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <button
                    class="btn btn-warning w-100"
                    (click)="addPayroll('Subco')"
                >
                    Subco only
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-sm btn-danger" (click)="clearMap()">
                    Clear all
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-9">
        <div class="map-container">
            <div
                class="map"
                id="mapTechmap"
                class="map-container"
                [style.height.px]="screenHeight"
            ></div>
        </div>
    </div>
</div>
