import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DispatchService } from './../../../_services/dispatch.service';
import { DispatchTechService } from './../../../_services/dispatch-tech.service';
import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'app-dispatch-export-modal',
    templateUrl: './dispatch-export-modal.component.html',
    styleUrls: ['./dispatch-export-modal.component.css'],
})
export class DispatchExportModalComponent implements OnInit {
    orderCounter: any;
    planDate: any;
    allTechs: any;

    cAllOrders: number = 0;
    cAssignedOrders: number = 0;
    cLimboOrders: number = 0;
    limboOrders: any = [];
    curUser: any;
    constructor(
        public activeModal: NgbActiveModal,
        private storage: DispatchStorageService,
        private dispatchService: DispatchService,
        private auth: AuthenticationService
    ) {
        this.allTechs = this.storage.getTechs();
        this.orderCounter = this.storage.getAreaCount();
        this.planDate = localStorage.getItem('dispatch_planDate');

        this.curUser = this.auth.userDetailsValue;
        console.log(this.curUser);

        console.log(this.orderCounter);
        // Loop all the orders from the ordercounter and check if they are with a technician
        // THIS FUNCTION NEEDS TO BE QUICKER
        for (
            var iOrderCounter = 0;
            iOrderCounter < this.orderCounter.length;
            iOrderCounter++
        ) {
            // Only proceed for the "added to map" orders (area visible)
            if (this.orderCounter[iOrderCounter].addedToMap) {
                for (
                    var iOrderAreaCount = 0;
                    iOrderAreaCount <
                    this.orderCounter[iOrderCounter].orders.length;
                    iOrderAreaCount++
                ) {
                    if (
                        Object.keys(
                            this.orderCounter[iOrderCounter].orders[
                                iOrderAreaCount
                            ]._techAssigned
                        ).length
                    ) {
                        this.cAssignedOrders++;
                    } else {
                        this.cLimboOrders++;
                        this.limboOrders.push(
                            this.orderCounter[iOrderCounter].orders[
                                iOrderAreaCount
                            ]
                        );
                    }
                    this.cAllOrders++;
                }
            }
        }
    }

    ngOnInit(): void {}

    send() {
        var techs = [];
        var areaSet: string | any[] = [];
        // Only send techs with orders assigned
        for (var i = 0; i < this.allTechs.length; i++) {
            if (this.allTechs[i]._isAvailable) {
                techs.push(this.allTechs[i]);

                // Check if the area is set
                if (!areaSet.includes('A' + this.allTechs[i].area)) {
                    areaSet.push('A' + this.allTechs[i].area);
                }
            }
        }
        var toSend = {
            allTechs: techs,
            limboOrders: this.limboOrders,
            areaSet: areaSet.sort(),
        };
        console.log(toSend);
        //console.log(JSON.stringify(toSend));

        this.dispatchService
            .generateExcel(toSend)
            .subscribe((response: any) => {
                //when you use stricter type checking
                console.log(response);
                let blob: any = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
                });
                const url = window.URL.createObjectURL(blob);
                //window.open(url);
                //window.location.href = response.url;
                fileSaver.saveAs(
                    blob,
                    'combined_routes_' +
                        this.curUser.id +
                        '_' +
                        this.planDate +
                        '.xlsx'
                );
                //}), error => console.log('Error downloading the file'),
            });
    }

    getBgColor(index: number) {
        if (index % 2 == 0) {
            return 'bg-light';
        } else {
            return '';
        }
    }

    countOrders(tech: any) {
        return Object.keys(this.allTechs[tech._origKey]._orders).length;
    }

    addOneEmpty(tech: any) {
        this.allTechs[tech._origKey]._emptyOrders.push({});
        this.storage.setTechs(this.allTechs);
    }

    setOrdersBgColor(tech: any, color: string) {
        this.allTechs[tech._origKey]._ordersBgColor = color;
        this.storage.setTechs(this.allTechs);
    }

    removeOneEmpty(tech: any) {
        var aantal = Object.keys(
            this.allTechs[tech._origKey]._emptyOrders
        ).length;

        var toReturn = [];

        var i = 0;
        while (i < aantal - 1) {
            toReturn.push({});
            i++;
        }

        this.allTechs[tech._origKey]._emptyOrders = toReturn;
        this.storage.setTechs(this.allTechs);
    }

    fillEmpty(tech: any, total: number) {
        // Get the curent amount of orders
        var orders = Object.keys(this.allTechs[tech._origKey]._orders).length;
        var emptyOrders = Object.keys(
            this.allTechs[tech._origKey]._emptyOrders
        ).length;

        var toAdd = total - orders - emptyOrders;

        if (toAdd > 0) {
            var i = 0;
            while (i < toAdd) {
                this.addOneEmpty(tech);
                i++;
            }
        }
    }

    changeTechArea(tech: any, newArea: any) {
        this.allTechs[tech._origKey].area = newArea;
        this.setOrdersBgColor(tech, 'bg-primary');
    }

    modelChangeFn(event: any, tech: any) {
        this.allTechs[tech._origKey]._remark = event;
        this.storage.setTechs(this.allTechs);
    }

    toggleMixplan(tech: any) {
        this.allTechs[tech._origKey]._mixplan =
            !this.allTechs[tech._origKey]._mixplan;
        this.storage.setTechs(this.allTechs);
    }

    toggleAllMixplan() {
        for (var i = 0; i < this.allTechs.length; i++) {
            // Only proceed for available techs
            if (this.allTechs[i]._isAvailable) {
                // Only proceed for techs with one order
                if (this.allTechs[i]._orders.length == 1) {
                    // switch mixplan
                    this.allTechs[i]._mixplan = !this.allTechs[i]._mixplan;
                    console.log(this.allTechs[i]);
                }
            }
        }
        this.storage.setTechs(this.allTechs);
    }

    toggleSpare(tech: any) {
        this.allTechs[tech._origKey]._spare =
            !this.allTechs[tech._origKey]._spare;
        this.storage.setTechs(this.allTechs);
    }
}
