import { BehaviorSubject } from 'rxjs';
import { FileService } from './../../../_services/file.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-automail-fileupload',
    templateUrl: './automail-fileupload.component.html',
    styleUrls: ['./automail-fileupload.component.css'],
})
export class AutomailFileuploadComponent implements OnInit {
    myForm = new FormGroup({
        customfile: new FormControl('', [Validators.required]),
        fileSource: new FormControl('', [Validators.required]),
    });
    @Input() loading: any;
    file: any = false;

    @Output() fileChanged: EventEmitter<any> = new EventEmitter();

    constructor(private fileservice: FileService) {}

    get f() {
        return this.myForm.controls;
    }

    ngOnInit(): void {}

    onFileChange(event: any) {
        if (event.target != undefined) {
            if (event.target.files.length > 0) {
                const customfile = event.target.files[0];
                this.file = customfile;
            } else {
                this.file = false;
            }
        } else {
            this.file = false;
        }

        this.fileChanged.emit(this.file);
    }
}
