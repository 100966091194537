import {
    filter,
    map,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import {
    Component,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TechService } from '../../../_services/tech.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'app-admin-wiw-alltechs',
    templateUrl: './admin-wiw-alltechs.component.html',
    styleUrls: ['./admin-wiw-alltechs.component.css'],
})
export class AdminWiwAlltechsComponent implements OnInit {
    @ViewChild('techSearchInput', { static: true })
    techSearchInput!: ElementRef;
    user: any;
    allTechs?: any;
    errorMessage: boolean = false;

    pageNumber: number = 1;
    totalPages: number = 0;
    allPages: number = 0;

    searchString: string = 'false';
    stopSearch: boolean = false;
    maxInputLength: number = 2;
    searchText = '';

    techsPerPageStandard: any = 12;
    techsPerPage: any = 12;

    loading: boolean = false;

    modalRef?: BsModalRef;
    message?: string;

    deleteTech: any;

    constructor(
        private techService: TechService,
        private modalService: BsModalService,
        private authenticationService: AuthenticationService
    ) {
        this.loading = true;
        this.authenticationService.userDetails.subscribe((data) => {
            this.user = data;
            this.loading = false;
        });
    }

    openModal(techid: number, template: TemplateRef<any>) {
        this.techService.getTechById(techid).subscribe((data) => {
            this.deleteTech = data;
            this.modalRef = this.modalService.show(template, {
                class: 'modal-dialog-centered',
            });
        });
    }
    confirm(): void {
        this.techService
            .deleteTechByPer(this.deleteTech.pxm_per)
            .subscribe((data) => {
                this.deleteTech = false;
                this.modalRef?.hide();
                this.getTechs();
            });
    }

    decline(): void {
        this.deleteTech = false;
        this.modalRef?.hide();
    }
    ngOnInit(): void {
        this.getTechs();
        fromEvent(this.techSearchInput.nativeElement, 'keyup')
            .pipe(
                // get value
                map((event: any) => {
                    return event.target.value;
                }),
                // if character length greater then 2
                filter((res) => {
                    if (res.length > 0) {
                        return true;
                    } else {
                        this.getTechs();
                        return false;
                    }
                }),

                // Time in milliseconds between key events
                debounceTime(500),

                // If previous query is diffent from current
                distinctUntilChanged()

                // subscription for response
            )
            .subscribe((text: string) => {
                this.loading = true;

                this.techService.getPaginated(1, text).subscribe((res) => {
                    console.log('res', res);
                    this.loading = false;
                    this.processTechs(res);
                });
            });
    }

    getTechs() {
        this.loading = true;
        this.techService
            .getPaginated(this.pageNumber, this.searchString, this.techsPerPage)
            .subscribe((data) => {
                this.processTechs(data);
                this.loading = false;
            });
    }

    processTechs(data: any) {
        this.allTechs = data.techs;
        this.totalPages = data.total_pages;
        this.pageNumber = data.current_page;
        this.allPages = data.total_items;
        if (this.allTechs.length == 0) {
            this.stopSearch = true;
            this.maxInputLength = this.searchString.length;
        } else {
            this.stopSearch = false;
            this.maxInputLength = 100;
        }
    }

    changePage(data: any) {
        this.pageNumber = data;
        this.getTechs();
    }

    showAll() {
        this.techsPerPage = this.allPages;
        this.getTechs();
    }

    showLess() {
        this.techsPerPage = this.techsPerPageStandard;
        this.getTechs();
    }

    changeSearch(data: any) {
        this.searchString = data;

        if (this.searchString.length == 0) {
            this.searchString = 'false';
        }

        this.pageNumber = 1;
        this.getTechs();
    }
}
