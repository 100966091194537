import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models/user';
import { UserService } from './_services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    user: any;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        this.authenticationService.userDetails.subscribe(
            (x) => (this.user = x)
        );
    }

    ngOnInit(): void {}
}
