import { DispatchStorageService } from './../../../_services/dispatch-storage.service';
import { DispatchService } from './../../../_services/dispatch.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dispatch-modal-orders',
    templateUrl: './dispatch-modal-orders.component.html',
    styleUrls: ['./dispatch-modal-orders.component.css'],
})
export class DispatchModalOrdersComponent implements OnInit {
    @Output() passOrderToMap = new EventEmitter();

    loading: boolean = false;
    oldData: boolean = false;
    allOrders: any;
    planDate: string = '';
    uploadDate: string = '';
    areaCount: any = [
        {
            area: 'A1',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            addedToMap: false,
            orders: [],
        },
        {
            area: 'A2',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            addedToMap: false,
            orders: [],
        },
        {
            area: 'A3',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            addedToMap: false,
            orders: [],
        },
        {
            area: 'A4',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            addedToMap: false,
            orders: [],
        },
        {
            area: 'A5',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            addedToMap: false,
            orders: [],
        },
        {
            area: 'other',
            install: 0,
            repair: 0,
            move: 0,
            adddecoder: 0,
            total: 0,
            assigned: 0,
            orders: [],
        },
    ];
    constructor(
        public activeModal: NgbActiveModal,
        private dispatchService: DispatchService,
        private storage: DispatchStorageService
    ) {
        this.loading = true;
        // Check if there is already an order object in database
        var check = localStorage.getItem('dispatch_uploadDate');
        if (check) {
            this.oldData = true;
            this.allOrders = this.storage.getOrders();
            this.planDate = localStorage.getItem('dispatch_planDate') || '';
            this.uploadDate = localStorage.getItem('dispatch_uploadDate') || '';
            this.areaCount = this.storage.getAreaCount();
            this.loading = false;
        } else {
            this.dispatchService.getOrders().subscribe((data) => {
                this.planDate = data.plan_date;
                this.uploadDate = data.uploaded;
                this.allOrders = data.meetings;

                for (var i = 0; i < this.allOrders.length; i++) {
                    // Set some vars
                    this.allOrders[i]._isHidden = false;
                    this.allOrders[i]._techAssigned = {};
                    this.allOrders[i]._origKey = i;
                    this.allOrders[i]._show = false;

                    var areaOrderOrigKey = this.increaseAreaCounter(
                        this.allOrders[i].area,
                        this.allOrders[i].type,
                        this.allOrders[i],
                        i
                    );

                    this.allOrders[i]._origKeyAreaCount =
                        areaOrderOrigKey?.areaCountId;
                    this.allOrders[i]._origKeyAreaCountOrder =
                        areaOrderOrigKey?.origKey;
                }

                this.storage.setAreaCount(this.areaCount);
                localStorage.setItem('dispatch_planDate', this.planDate);
                localStorage.setItem('dispatch_uploadDate', this.uploadDate);
                this.storage.setOrders(this.allOrders);
                this.loading = false;
            });
        }
    }

    increaseAreaCounter(area: string, type: string, order: any, origKey: any) {
        var toReturn = {
            areaCountId: 0,
            origKey: 0,
        };
        for (var i = 0; i < this.areaCount.length; i++) {
            if (this.areaCount[i].area == area) {
                this.areaCount[i][type]++;
                this.areaCount[i]['total']++;
                this.areaCount[i].orders.push(order);
                toReturn.areaCountId = i;
                toReturn.origKey = this.areaCount[i].orders.length;
            }
        }
        return toReturn;
    }

    ngOnInit(): void {}

    addToMap(i: number) {
        this.loading = true;
        this.areaCount[i].addedToMap = true;
        this.storage.setAreaCount(this.areaCount);

        var orders = [];
        for (var i1 = 0; i1 < this.areaCount[i].orders.length; i1++) {
            this.allOrders[this.areaCount[i].orders[i1]._origKey]._show = true;
            orders.push(this.allOrders[this.areaCount[i].orders[i1]._origKey]);
        }
        this.storage.setOrders(this.allOrders);
        this.loading = false;
        this.passOrderToMap.emit(orders);
    }
}
