<div class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }}
</div>
<table
    class="table table-sm table-hover table-bordered"
    *ngIf="allTechs && allTechs.length > 0 && !errorMessage"
>
    <thead>
        <th>Username</th>
        <th>Function</th>
        <th>Total logins</th>
        <th>Last login</th>
        <th>Api calls</th>
    </thead>
    <tbody>
        <tr *ngFor="let userDetail of allTechs">
            <td>{{ userDetail.tech_name }}</td>
            <td>{{ userDetail.function }}</td>

            <td>{{ userDetail.login_attempts }}</td>
            <td>{{ userDetail.last_login }}</td>
            <td>{{ userDetail.api_calls }}</td>
        </tr>
    </tbody>
</table>
