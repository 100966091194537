import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kpiverslag',
    templateUrl: './kpiverslag.component.html',
    styleUrls: ['./kpiverslag.component.css'],
})
export class KpiverslagComponent implements OnInit {
    model: any;

    constructor() {}

    ngOnInit(): void {}

    modelDateRecieve(e: any) {
        this.model = e;
        console.log(e);
    }
}
