import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-admin-users-all',
  templateUrl: './admin-users-all.component.html',
  styleUrls: ['./admin-users-all.component.css'],
})
export class AdminUsersAllComponent implements OnInit {
  allUsers?: any;
  errorMessage: boolean = false;
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService
      .getAll()
      .subscribe((response) => (this.allUsers = response));
  }
}
