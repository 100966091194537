<h2><i class="fas fa-users"></i> Techniekers</h2>
<hr />

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
        <a ngbNavLink>Alle techniekers</a>
        <ng-template ngbNavContent>
            <p>
                <app-g4f-tech-table></app-g4f-tech-table>
            </p>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink>Technieker toevoegen</a>
        <ng-template ngbNavContent>
            <p>
                <app-g4f-tech-add></app-g4f-tech-add>
            </p>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
