<div #elseBlock></div>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark" *ngIf="user.apps">
    <a class="navbar-brand" [routerLink]="['/home']"
        ><i class="far fa-hand-spock"></i> Solutions30 - Coachingtool
        {{ currentRoute }}
        <span class="badge bg-danger" *ngIf="!production"
            ><i class="fas fa-database"></i> Dev</span
        >
        <span class="badge bg-success" *ngIf="production"
            ><i class="fas fa-database"></i> Live</span
        >
        <span *ngIf="currentRoute == '/home'"> - Home</span>
        <span *ngIf="currentRoute == '/profile'"> - Profile</span>
    </a>

    <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a
                    class="nav-link"
                    [routerLink]="['/home']"
                    routerLinkActive="active"
                    ><i class="fa fa-home"></i> Home
                    <span class="sr-only">(current)</span></a
                >
            </li>
            <!-- WIW menu -->
            <li
                class="nav-item dropdown"
                *ngIf="
                    user.apps.includes('full') ||
                    (user.apps.includes('wiw') && user.login_method == 'USER')
                ">
                <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdown_WhoIsWho"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fa fa-address-book"></i> WhoIsWho
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_WhoIsWho">
                    <a
                        class="dropdown-item"
                        [routerLink]="['/admin/wiw/alltechs']"
                        ><i class="fa fa-pray"></i> All techs</a
                    >
                    <a
                        class="dropdown-item"
                        [routerLink]="['admin/wiw/allsubcos']"
                        *ngIf="
                            user.apps.includes('full') ||
                            (user.apps.includes('wiw_admin') &&
                                user.login_method == 'USER')
                        "
                        ><i class="fas fa-building"></i> All subcos</a
                    >
                    <a class="dropdown-item" [routerLink]="['admin/wiw/skills']"
                        ><i class="fas fa-fighter-jet"></i> Skills</a
                    >
                </div>
            </li>

            <!-- Meetingmap Menu -->
            <li
                class="nav-item dropdown"
                *ngIf="
                    user.apps.includes('full') ||
                    (user.apps.includes('meetingmap') &&
                        user.login_method == 'USER')
                ">
                <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdown_Meetingmap"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fas fa-handshake"></i> Meetingmap
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_Meetingmap">
                    <a
                        class="dropdown-item"
                        [routerLink]="['/admin/meetingmap/mm-settings/']"
                        ><i class="fas fa-cogs"></i> Settings</a
                    >
                    <a
                        class="dropdown-item"
                        [routerLink]="['/admin/meetingmap/mm-admin']"
                        *ngIf="
                            user.apps.includes('full') ||
                            (user.apps.includes('meetingmap_admin') &&
                                user.login_method == 'USER')
                        "
                        ><i class="fas fa-upload"></i> Upload data</a
                    >
                </div>
            </li>

            <!-- Dispatch Menu -->
            <li
                class="nav-item dropdown"
                *ngIf="
                    user.apps.includes('full') ||
                    (user.apps.includes('dispatch_admin') &&
                        user.login_method == 'USER')
                ">
                <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdown_Dispatch"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fas fa-route"></i> Dispatch
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_Dispatch">
                    <a
                        class="dropdown-item"
                        *ngIf="
                            user.apps.includes('full') ||
                            (user.apps.includes('dispatch_admin') &&
                                user.login_method == 'USER')
                        "
                        [routerLink]="['/admin/dispatch-upload']"
                        ><i class="fas fa-upload"></i> Upload data</a
                    >
                </div>
            </li>

            <!-- Automail Menu -->
            <li
                class="nav-item dropdown"
                *ngIf="
                    user.apps.includes('full') || user.apps.includes('automail')
                ">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown_Automail"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fa fa-mail-bulk"></i> Automail
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_Automail">
                    <a class="dropdown-item" [routerLink]="['/admin/automail']"
                        ><i class="fab fa-mailchimp"></i> Generate</a
                    >
                </div>
            </li>

            <!-- GEO -->
            <li
                class="nav-item dropdown"
                *ngIf="user.apps.includes('full') || user.apps.includes('geo')">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown_WhoIsWho"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fa fa-globe-europe"></i> Geotools
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_WhoIsWho">
                    <a
                        class="dropdown-item"
                        ui-sref="geo/inter_area_support"
                        [class.disabled]="1"
                        ><i class="fas fa-car"></i> Inter Area Support</a
                    >
                    <a
                        class="dropdown-item"
                        [routerLink]="['/admin/techroutes']"
                        ><i class="fas fa-route"></i> PXM Routes</a
                    >
                    <a
                        class="dropdown-item"
                        ui-sref="geo/tpt_upload"
                        ng-show="hasAccess('full')"
                        [class.disabled]="1"
                        ><i class="fas fa-upload"></i> TPT Upload</a
                    >
                </div>
            </li>

            <!-- Admin -->
            <li
                class="nav-item dropdown"
                *ngIf="
                    user.apps.includes('full') ||
                    user.apps.includes('user_admin')
                ">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown_Admin"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fa fa-users"></i> Admin
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_Admin">
                    <a
                        class="dropdown-item"
                        [routerLink]="['/admin/user-rights']"
                        ><i class="fas fa-lock"></i> User rights</a
                    >
                    <ng-container *ngIf="user.apps.includes('full')">
                        <a
                            class="dropdown-item"
                            [routerLink]="['/admin/users/all']"
                            ><i class="fa fa-users"></i> Users</a
                        >
                        <a
                            class="dropdown-item"
                            [routerLink]="['/admin/techs/all']"
                            ><i class="fa fa-baby"></i> Techs</a
                        >
                        <a
                            class="dropdown-item"
                            [routerLink]="['/admin/techs/grid']"
                            ><i class="fas fa-th"></i> Grid</a
                        >
                    </ng-container>
                </div>
            </li>
            <!-- Profile -->
            <li
                class="nav-item dropdown"
                ng-class="{ active: isActive('/profile') }">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown_Profile"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    routerLinkActive="active">
                    <i class="fa fa-users"></i> {{ user.username }}
                </a>
                <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown_Profile">
                    <a class="dropdown-item" [routerLink]="['/profile']"
                        ><i class="fa fa-id-card"></i> Profile</a
                    >
                    <a
                        class="dropdown-item bg-danger"
                        href="#"
                        (click)="logout()"
                        ><i class="fa fa-sign-out-alt"></i>Logout</a
                    >
                </div>
            </li>
        </ul>
    </div>
</nav>
