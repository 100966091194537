import { UserService } from './../../../_services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-user-rights-add-modal',
    templateUrl: './user-rights-add-modal.component.html',
    styleUrls: ['./user-rights-add-modal.component.css'],
})
export class UserRightsAddModalComponent implements OnInit {
    @Output() saveUser = new EventEmitter();

    allRights: any = [];
    allBranches: any = [];
    allAreas: any = ['A1', 'A2', 'A3', 'A4', 'A5'];

    username: string;
    branch: string;
    function: string;
    erp_id: number;
    rights: any = [];
    areas: any = [];

    constructor(
        private userService: UserService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.userService.getAllRights().subscribe((response) => {
            this.allRights = response.rights;
            this.allBranches = response.branches;
        });
    }

    saveUserF() {
        var data = {
            add_new_user: true,
            _username: this.username,
            _function: this.function,
            _branch: this.branch,
            erp_id: this.erp_id,
            _rights: this.rights,
            _areas: this.areas,
        };
        this.saveUser.emit(data);
        this.activeModal.close();
    }
}
