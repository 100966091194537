import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchobject',
})
export class SearchobjectPipe implements PipeTransform {
    transform(items: any, toSearch: string): any {
        if (!items || !toSearch) {
            return items;
        }

        return Object.values(items).filter((item: any) =>
            item.tech_name
                .toString()
                .toLocaleLowerCase()
                .includes(toSearch.toLocaleLowerCase())
        );
    }
}
