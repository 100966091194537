<div class="row" *ngIf="!sending">
    <div class="col-md-2">Message (max 160 chars):</div>
    <div class="col-md-5">
        <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            maxlength="160"
            [(ngModel)]="message"
            (ngModelChange)="valueChange()"
        ></textarea>
    </div>
    <div class="col-md-3">
        <div class="row">
            <div class="col-md-5">Charcount:</div>
            <div class="col-md-7">{{ charCount }}/160</div>
        </div>
        <div class="row">
            <div class="col-md-5">Selected techs:</div>
            <div class="col-md-7">
                {{ selectedTechs }}
            </div>
        </div>
    </div>
    <div class="col-md-2">
        Templates:<br />
        <ul>
            <li *ngFor="let template of templates">
                <button
                    class="btn btn-outline-warning btn-sm"
                    (click)="setMessageValue(template.text)"
                >
                    {{ template.title }}
                </button>
            </li>
        </ul>
    </div>
</div>
<br />
<div class="row" *ngIf="!sending">
    <div class="col-md-2">Send action</div>
    <div class="col-md-3">
        <button
            class="btn btn-danger"
            [disabled]="selectedTechs == 0 && charCount == 0"
            (click)="sendSms()"
        >
            <i class="far fa-paper-plane"></i> Send SMS
        </button>
    </div>
</div>
<div class="row" *ngIf="sending">
    <div class="col-md-2">Status</div>
    <div class="col-md-10">
        <div
            class="progress-bar progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ progressValue }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: progressValue + '%' }"
        >
            {{ progressValue }}%
        </div>
    </div>
</div>
<hr />

<div class="row">
    <div class="col-md-2">Send to:</div>
    <div class="col-md-8">
        <div class="alert alert-info" *ngIf="loadingTechs">
            <i class="fas fa-spinner fa-pulse"></i> Techs are being loaded
        </div>
        <div class="row" *ngIf="!loadingTechs">
            <div class="col-md-3">
                <button class="btn btn-success" (click)="selectTechs('today')">
                    Working today
                </button>
            </div>
            <div class="col-md-2">
                <button
                    class="btn btn-outline-primary"
                    (click)="selectTechs('all')"
                >
                    Select all
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-3">
                <button
                    class="btn btn-outline-primary"
                    (click)="selectTechs('pxm')"
                >
                    PXM
                </button>
            </div>
            <div class="col-md-3">
                <button
                    class="btn btn-outline-primary"
                    (click)="selectTechs('sct')"
                >
                    SCT
                </button>
            </div>
            <div class="col-md-3">
                <button
                    class="btn btn-outline-primary"
                    (click)="selectTechs('prf')"
                >
                    PRF
                </button>
            </div>
            <div class="col-md-3">
                <button
                    class="btn btn-outline-primary"
                    (click)="selectTechs('fis')"
                >
                    FIS
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <table
                    class="table table-sm table-bordered table-hover"
                    *ngIf="!loadingTechs"
                >
                    <thead>
                        <tr>
                            <th>Selected</th>
                            <th>Tech name</th>
                            <th>Phone number</th>
                            <th>PXM</th>
                            <th>SCT</th>
                            <th>PRF</th>
                            <th>FIS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let user of allTechs"
                            [ngClass]="{
                                'bg-success':
                                    user.selected_for_sms &&
                                    user.phone_work_clean
                            }"
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    *ngIf="user.phone_work_clean"
                                    [checked]="user.selected_for_sms"
                                    (change)="
                                        user.selected_for_sms =
                                            !user.selected_for_sms
                                    "
                                />
                            </td>
                            <td>{{ user.tech_name }}</td>
                            <td
                                [ngClass]="{
                                    'bg-danger': !user.phone_work_clean
                                }"
                            >
                                {{ user.phone_work_clean }}
                            </td>
                            <td>{{ user.skill_pxm }}</td>
                            <td>
                                {{ user.skill_sct_full }} /
                                {{ user.skill_sct_light }} (light)
                            </td>
                            <td>{{ user.skill_proff }}</td>
                            <td>{{ user.skill_fiber_fis }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
