<div class="map-container">
    <div class="quakeInfo">
        <div><strong>Type:</strong> {{ type }}</div>
        <div><strong>Name:</strong> {{ name }}</div>
        <div><strong>Info:</strong> {{ info }}</div>
    </div>
    <div
        class="map"
        id="map"
        class="map-container"
        [style.height.px]="screenHeight"
    ></div>
</div>
