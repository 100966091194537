<h2><i class="fas fa-mail-bulk"></i> Module to run</h2>
<div class="form-check" *ngIf="!selectedAutomail">
    <div class="row" *ngFor="let automail of user.automails">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="setAutomail(automail)"
        >
            <i class="fas fa-scroll"></i> {{ automail }}
        </button>
    </div>
</div>
<div *ngIf="selectedAutomail">
    <div class="alert alert-success">
        <button
            type="button"
            class="btn btn-sm btn-outline-danger btn-warning"
            (click)="setAutomail('')"
            *ngIf="!loading"
        >
            <i class="fas fa-undo"></i> Pick another script
        </button>
        <table class="table table-sm table-borderless">
            <tbody>
                <tr>
                    <td>Username:</td>
                    <td>{{ user.username }}</td>
                </tr>
                <tr>
                    <td>Script:</td>
                    <td>{{ selectedAutomail }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
