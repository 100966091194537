import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MeetginmapService {
    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getUploadedFileInfoById(id: any): Observable<any> {
        return this.http.get(this.API_URL + 'meetingmap/uploadFileInfo/' + id, {
            responseType: 'json',
        });
    }

    getNetworkPolygon(): Observable<any> {
        return this.http.get(this.API_URL + 'geo/networkPolygon', {
            responseType: 'json',
        });
    }

    insert(body: any): any {
        return this.http
            .post(this.API_URL + 'meetingmap/insert', body)
            .toPromise()
            .then((r) => {
                return r;
            });
    }

    getOpenOrders(): Observable<any> {
        return this.http.get(this.API_URL + 'meetingmap/orders', {
            responseType: 'json',
        });
    }

    techchange(status: any, id: any): Observable<any> {
        return this.http.get(
            this.API_URL + 'meetingmap/techchange/' + status + '/' + id,
            {
                responseType: 'json',
            }
        );
    }

    changeStatus(networks: any): Observable<any> {
        return this.http.post(
            this.API_URL + 'meetingmap/changeStatus',
            networks,
            {
                responseType: 'json',
            }
        );
    }
}
