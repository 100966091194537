import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Pipe, PipeTransform } from '@angular/core';
import { isTemplateExpression } from 'typescript';

@Pipe({
    name: 'scttechs',
    pure: false,
})
export class ScttechsPipe implements PipeTransform {
    transform(items: any): any {
        if (!items) {
            return items;
        }
        return Object.values(items).filter(
            (item: any) => item.skill_sct_full == 1 || item.skill_sct_light == 1
        );
    }
}
