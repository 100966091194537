<h2><i class="far fa-file-excel"></i> File upload</h2>
<form [formGroup]="myForm" *ngIf="!file">
    <div class="custom-file form-group">
        <input
            class="custom-file-input"
            formControlName="customfile"
            id="customfile"
            type="file"
            class="form-control"
            (change)="onFileChange($event)"
        />
        <label class="custom-file-label" for="customfile">Choose file</label>
        <div
            *ngIf="f.customfile.touched && f.customfile.invalid"
            class="alert alert-danger"
        >
            <div *ngIf="f.customfile.errors?.required">File is required.</div>
        </div>
    </div>
</form>
<div class="alert alert-success" *ngIf="file">
    <button
        class="btn btn-sm btn-warning btn-outline-danger"
        (click)="onFileChange('')"
        *ngIf="!loading"
    >
        <i class="fas fa-undo"></i> Pick another file
    </button>
    <table class="table table-sm table-borderless">
        <tbody>
            <tr>
                <td>Name:</td>
                <td>{{ file.name }}</td>
            </tr>
            <tr>
                <td>Size:</td>
                <td>{{ file.size / 1000 }}kb</td>
            </tr>
            <tr>
                <td>Type:</td>
                <td>{{ file.type }}</td>
            </tr>
        </tbody>
    </table>
</div>
