import { Component, Input, OnInit } from '@angular/core';
import { SmsService } from 'src/app/_services/sms.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sms-component',
    templateUrl: './sms-component.component.html',
    styleUrls: ['./sms-component.component.css'],
})
export class SmsComponentComponent implements OnInit {
    @Input() selectedTechs: number = 0;
    @Input() filteredTechs: any[] = [];

    sending: boolean = false;
    message: string = '';
    charCount: number = 0;
    templates: any[] = [];
    progressValue: number = 0;

    loadingTemplates: boolean = true;

    constructor(private smsService: SmsService) {}

    ngOnInit(): void {
        this.smsService.getTemplates().subscribe((data) => {
            this.templates = data;
            this.loadingTemplates = false;
        });
    }

    valueChange() {
        this.charCount = this.message.length;
    }

    setMessageValue(val: any) {
        this.message = val;
        this.valueChange();
    }

    async sendSms() {
        this.sending = true;

        const isProduction = environment.production;
        let alertShowOnce = false;

        for (let i = 0; i < this.filteredTechs.length; i++) {
            // Set the main progress on 0

            if (this.filteredTechs[i].setForSMS) {
                var temp = {
                    message: this.message,
                    erp_id: this.filteredTechs[i].ERP_ID,
                    phone: this.filteredTechs[i].phone_work_clean,
                    username: this.filteredTechs[i].tech_name,
                };
                // Do the call to api
                if (isProduction) {
                    var response = await this.smsService.insert(temp);
                } else {
                    if (!alertShowOnce) {
                        alert(
                            'This is the staging environment, no SMS will be send. In production it will be send to ' +
                                this.filteredTechs.length +
                                ' technicans with the message "' +
                                this.message +
                                '".'
                        );
                        alertShowOnce = true;
                    }
                    await this.delay(100);
                }

                this.progressValue = Math.round(
                    ((i + 1) / this.filteredTechs.length) * 100
                );
            }
        }

        this.sending = false;
        this.message = '';
        this.progressValue = 0;
        this.charCount = 0;
    }

    delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
