<div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-users-cog"></i> 1. Technicians</h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-info">
        <i class="fas fa-info-circle"></i> Tip: type a part of a name, press
        "Tab" untill you get to the technician on the left side, press enter to
        add it, repeat.
    </div>
    <div class="row">
        <div class="col-md-3">Search:</div>
        <div class="col-md-9">
            <input
                type="text"
                class="form-control"
                #searchInput
                [(ngModel)]="search"
            />
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('')"
                [ngClass]="showArea == '' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                All
            </button>
        </div>
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('1')"
                [ngClass]="showArea == '1' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                A1
            </button>
        </div>
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('2')"
                [ngClass]="showArea == '2' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                A2
            </button>
        </div>
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('3')"
                [ngClass]="showArea == '3' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                A3
            </button>
        </div>
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('4')"
                [ngClass]="showArea == '4' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                A4
            </button>
        </div>
        <div class="col-md-2">
            <button
                class="btn btn-sm w-100"
                (click)="setArea('5')"
                [ngClass]="showArea == '5' ? 'btn-info' : 'btn-outline-info'"
                tabindex="-1"
            >
                A5
            </button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-5">
            Available
            <div class="scroller">
                <span
                    *ngFor="
                        let tech of allTechs
                            | searchobject: search
                            | hiddentechs
                            | areatechs: showArea;
                        let i = index
                    "
                    ><button
                        class="btn btn-sm w-100 text-left"
                        style="margin-bottom: 5px"
                        (click)="addTech(tech)"
                        [ngClass]="{
                            'btn-outline-primary':
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 0,
                            'btn-outline-warning':
                                tech.skill_sct_light == 1 &&
                                tech.skill_sct_full == 0,
                            'btn-outline-info':
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 1
                        }"
                    >
                        A{{ tech.area }} -
                        <span
                            *ngIf="
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 0
                            "
                            >(full)</span
                        ><span
                            *ngIf="
                                tech.skill_sct_light == 1 &&
                                tech.skill_sct_full == 0
                            "
                            >(mix )</span
                        ><span
                            *ngIf="
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 1
                            "
                            >(both)</span
                        >
                        - {{ tech.tech_name }}</button
                    ><br
                /></span>
            </div>
        </div>
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-5">
            Enabled
            <div class="scroller">
                <span
                    *ngFor="
                        let tech of allTechs | availabletechspipe;
                        index as key
                    "
                >
                    <button
                        class="btn btn-sm w-100 text-left"
                        style="margin-bottom: 5px"
                        (click)="removeTech(tech)"
                        [ngClass]="{
                            'btn-outline-primary':
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 0,
                            'btn-outline-warning':
                                tech.skill_sct_light == 1 &&
                                tech.skill_sct_full == 0,
                            'btn-outline-info':
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 1
                        }"
                    >
                        A{{ tech.area }} -
                        <span
                            *ngIf="
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 0
                            "
                            >(full)</span
                        ><span
                            *ngIf="
                                tech.skill_sct_light == 1 &&
                                tech.skill_sct_full == 0
                            "
                            >(mix )</span
                        ><span
                            *ngIf="
                                tech.skill_sct_full == 1 &&
                                tech.skill_sct_light == 1
                            "
                            >(both)</span
                        >
                        - {{ tech.tech_name }}
                    </button>
                </span>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-2">ERP id's:</div>
        <div class="col-md-9">
            <input
                type="text"
                class="form-control w-100"
                [(ngModel)]="erpIDS"
            />
        </div>
        <div class="col-md-1">
            <button class="btn btn-outline-primary btn-sm" (click)="addByErp()">
                S
            </button>
        </div>
    </div>
    <br />
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close('Close click')"
        >
            Close
        </button>
    </div>
</div>
