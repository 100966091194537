import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hiddentechs',
    pure: false,
})
export class HiddentechsPipe implements PipeTransform {
    transform(items: any): any {
        if (!items) {
            return items;
        }
        return Object.values(items).filter(
            (item: any) =>
                !item._isHidden &&
                (item.skill_sct_full == 1 || item.skill_sct_light == 1)
        );
    }
}
