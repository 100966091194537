<div class="row">
    <div class="col-md-1">
        <div class="row">
            <div class="col-md-12">
                <button
                    class="btn btn-sm btn-info w-100"
                    (click)="showAll()"
                    *ngIf="techsPerPage != allPages"
                    [disabled]="loading">
                    <i class="fas fa-plus-circle"></i> Show all
                </button>
                <button
                    class="btn btn-sm btn-info w-100"
                    (click)="showLess()"
                    *ngIf="techsPerPage == allPages"
                    [disabled]="loading">
                    <i class="fas fa-minus-circle"></i> Show less
                </button>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <app-button-download-techtable
                    [loading]="loading"></app-button-download-techtable>
            </div>
        </div>
        <br />
        <div
            class="row"
            *ngIf="
                user.apps.includes('full') || user.apps.includes('wiw_admin')
            ">
            <div class="col-md-12">
                <a
                    class="btn btn-success btn-sm w-100"
                    [routerLink]="['/admin/wiw/alltechs/add']"
                    [class.disabled]="loading">
                    <i class="fas fa-user-plus"></i> Add new
                </a>
            </div>
        </div>
    </div>
    <div class="col-md-10">
        <div class="row">
            <div class="col-md-12">
                <div class="input-group">
                    <div class="input-group-text">
                        <i class="fa fa-search"></i>
                    </div>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search (anything)"
                        #techSearchInput
                        [ngModel]="searchText"
                        [disabled]="loading"
                        [attr.maxlength]="maxInputLength" />
                </div>
            </div>
        </div>

        <br />
        <div class="alert alert-danger" *ngIf="stopSearch">
            There are no techs found with this search criteria.
        </div>
        <table
            class="table table-sm table-bordered table-hover"
            *ngIf="!stopSearch && !loading">
            <thead>
                <th
                    *ngIf="
                        user.apps.includes('full') ||
                        user.apps.includes('wiw_admin')
                    ">
                    &nbsp;
                </th>
                <th><i class="fa fa-street-view"></i> Area</th>
                <th><i class="fa fa-id-card-alt"></i> Per</th>
                <th><i class="fa fa-user"></i> Name</th>
                <th><i class="fa fa-file"></i> Status</th>
                <th><i class="fa fa-map-marker-alt"></i> Homelocation</th>
                <th><i class="fa fa-envelope"></i> Mail</th>
                <th><i class="fa fa-mobile-alt"></i> Phone work</th>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let tech of allTechs
                            | paginate
                                : {
                                      id: 'admin_wiw_alltechs_pagination',
                                      itemsPerPage: 8,
                                      currentPage: pageNumber,
                                      totalItems: allPages
                                  }
                    ">
                    <td
                        *ngIf="
                            user.apps.includes('full') ||
                            user.apps.includes('wiw_admin')
                        ">
                        <table
                            class="table table-borderless table-sm text-center">
                            <tr>
                                <td style="width: 33%">
                                    <a
                                        ngbTooltip="User details"
                                        class="btn bg-info btn-sm w-100"
                                        [routerLink]="[
                                            '/admin/wiw/alltechs/details',
                                            tech.id
                                        ]">
                                        <i class="far fa-id-card"></i>
                                    </a>
                                </td>
                                <td
                                    style="width: 33%"
                                    ng-show="hasAccess('wiw_admin')">
                                    <a
                                        ngbTooltip="Edit user"
                                        class="btn bg-primary btn-sm w-100"
                                        [routerLink]="[
                                            '/admin/wiw/alltechs/edit',
                                            tech.id
                                        ]">
                                        <i class="fas fa-user-edit"></i>
                                    </a>
                                </td>
                                <td
                                    style="width: 33%"
                                    ng-show="hasAccess('wiw_admin')">
                                    <a
                                        ngbTooltip="Delete user"
                                        class="btn bg-danger btn-sm w-100"
                                        (click)="openModal(tech.id, template)">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td>{{ tech.area }}</td>
                    <td>{{ tech.pxm_per }}</td>
                    <td>{{ tech.tech_name | titlecase }}</td>
                    <td
                        [ngClass]="{
                            'bg-success': tech.firm_status == 'Payroll',
                            'bg-warning': tech.firm_status == 'Subco',
                            'bg-danger': !tech.firm_status
                        }">
                        {{ tech.firm_status }}
                    </td>
                    <td>{{ tech.tech_zip_city }}</td>
                    <td [ngClass]="{ 'bg-danger': !tech.tech_mail }">
                        {{ tech.tech_mail }}
                    </td>
                    <td [ngClass]="{ 'bg-danger': !tech.tech_tel_work }">
                        {{ tech.tech_tel_work }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-info" *ngIf="loading">
            <i class="fas fa-spinner fa-pulse"></i> Loading
        </div>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
<div class="row">
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-10">
        <pagination-controls
            id="admin_wiw_alltechs_pagination"
            (pageChange)="changePage($event)"
            *ngIf="totalPages > 1 && !loading"></pagination-controls>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>
<ng-template #template>
    <div class="modal-body text-center bg-warning">
        <p>
            Are you sure you want to delete this tech?<br />
            <strong
                >{{ deleteTech.pxm_per }} - {{ deleteTech.tech_name }}</strong
            >
        </p>
        <button type="button" class="btn btn-danger" (click)="confirm()">
            Yes</button
        >&nbsp;
        <button type="button" class="btn btn-primary" (click)="decline()">
            No
        </button>
    </div>
</ng-template>
