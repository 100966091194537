import { MeetginmapService } from './../_services/meetginmap.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { forEachLeadingCommentRange } from 'typescript';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    loading: boolean = false;
    user: any = false;
    networkPolygon: any;

    userCoachingAppCount: number = 0;

    tilesPerLine: number = 6;
    currentLine: number = 1;

    appsCoaching: any = [
        {
            main: 'coaching',
            name: 'WhoIsWho',
            fa: 'fa-user',
            rights: ['full', 'wiw'],
            link: '/wiw',
        },
        {
            main: 'coaching',
            name: 'Meetingmap',
            fa: 'fa-handshake',
            rights: ['full', 'meetingmap'],
            link: '/meetingmap',
            userSettingsLink: '/admin/meetingmap/mm-settings',
        },
        {
            main: 'coaching',
            name: 'KVD/LDC Lookup',
            fa: 'fa-igloo',
            rights: ['full', 'meetingmap'],
            link: '/kvdmap',
        },
        {
            main: 'coaching',
            name: 'Techmap',
            fa: 'fa-map-marked-alt',
            rights: ['full', 'wiw'],
            link: '/techmap',
            user: true,
        },
        {
            main: 'coaching',
            name: 'SMS Gateway (V2)',
            fa: 'fa-sms',
            rights: ['full', 'wiw_sms'],
            link: '/sms-gateway-v2',
            user: true,
        },
        {
            main: 'coaching',
            name: 'Dispatch',
            fa: 'fa-route',
            rights: ['full', 'dispatch'],
            link: '/dispatch',
            user: true,
        },
    ];

    constructor(
        private authenticationService: AuthenticationService,
        private meetginmapService: MeetginmapService,
        private modalService: NgbModal
    ) {
        this.authenticationService.userDetails.subscribe((data) => {
            this.user = data;
            this.loading = true;
            // Count the rights a user has
            this.appsCoaching.forEach((element: any) => {
                if (this.checkRight(element)) {
                    this.userCoachingAppCount++;
                }
            });

            this.loading = false;
        });
    }
    ngOnInit() {}

    tileWidth() {
        return 12 / this.tilesPerLine;
    }

    checkRight(app: any) {
        var visible = false;

        app.rights.forEach((element: any) => {
            if (this.user?.apps?.includes(element)) {
                visible = true;

                if (element.user) {
                    if (this.isUser()) {
                        visible = true;
                    } else {
                        visible = false;
                    }
                }
            }
        });

        return visible;
    }

    isUser() {
        if (this.user?.login_method == 'USER') {
            return true;
        } else {
            return false;
        }
    }
}
