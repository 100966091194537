import { SmsService } from './../../_services/sms.service';
import { Component, OnInit } from '@angular/core';
import { TechService } from '../../_services/tech.service';

@Component({
    selector: 'app-sms-gateway',
    templateUrl: './sms-gateway.component.html',
    styleUrls: ['./sms-gateway.component.css'],
})
export class SmsGatewayComponent implements OnInit {
    charCount: number = 0;
    message: string = '';
    allTechs: any;
    loadingTechs: boolean = true;

    selectall: boolean = false;
    selectedTechs: number = 0;

    sending: boolean = false;

    progressInfo: any;

    progressValue: number = 0;

    workingToday: any;

    templates: any = {};

    constructor(
        private techService: TechService,
        private smsService: SmsService
    ) {
        this.smsService.getTemplates().subscribe((data) => {
            this.templates = data;
            console.log(data);
        });
    }

    ngOnInit() {
        return this.techService.getAll().subscribe((response) => {
            this.allTechs = response;
            this.loadingTechs = false;

            this.techService.workingToday().subscribe((response) => {
                this.workingToday = response;
            });
        });
    }

    valueChange() {
        this.charCount = this.message.length;
    }

    selectTechs(action: string) {
        this.selectedTechs = 0;

        if (action == 'today') {
            for (var i = 0; i < this.allTechs.length; i++) {
                if (this.workingToday.includes(this.allTechs[i].pxm_per)) {
                    this.allTechs[i].selected_for_sms = true;
                    this.selectedTechs++;
                } else {
                    this.allTechs[i].selected_for_sms = false;
                }
            }
        }

        if (action == 'all') {
            for (var i = 0; i < this.allTechs.length; i++) {
                this.allTechs[i].selected_for_sms = !this.selectall;
                this.selectedTechs++;
            }
            this.selectall = !this.selectall;
        }
        if (action == 'pxm') {
            for (var i = 0; i < this.allTechs.length; i++) {
                this.allTechs[i].selected_for_sms = false;

                if (
                    this.allTechs[i].skill_pxm == 1 ||
                    this.allTechs[i].skill_proff == 1 ||
                    this.allTechs[i].skill_fiber_fis == 1
                ) {
                    this.allTechs[i].selected_for_sms = true;
                    this.selectedTechs++;
                }
            }
            this.selectall = false;
        }

        if (action == 'sct') {
            for (var i = 0; i < this.allTechs.length; i++) {
                this.allTechs[i].selected_for_sms = false;

                if (
                    this.allTechs[i].skill_sct_full == 1 ||
                    this.allTechs[i].skill_sct_light == 1
                ) {
                    this.allTechs[i].selected_for_sms = true;
                    this.selectedTechs++;
                }
            }
            this.selectall = false;
        }

        if (action == 'prf') {
            for (var i = 0; i < this.allTechs.length; i++) {
                this.allTechs[i].selected_for_sms = false;

                if (this.allTechs[i].skill_proff == 1) {
                    this.allTechs[i].selected_for_sms = true;
                    this.selectedTechs++;
                }
            }
            this.selectall = false;
        }

        if (action == 'fis') {
            for (var i = 0; i < this.allTechs.length; i++) {
                this.allTechs[i].selected_for_sms = false;

                if (this.allTechs[i].skill_fiber_fis == 1) {
                    this.allTechs[i].selected_for_sms = true;
                    this.selectedTechs++;
                }
            }
            this.selectall = false;
        }
    }

    async sendSms() {
        this.sending = true;

        for (let i = 0; i < this.allTechs.length; i++) {
            // Set the main progress on 0

            if (this.allTechs[i].selected_for_sms) {
                var temp = {
                    message: this.message,
                    erp_id: this.allTechs[i].ERP_ID,
                    phone: this.allTechs[i].phone_work_clean,
                    username: this.allTechs[i].tech_name,
                };
                // Do the call to api
                var response = await this.smsService.insert(temp);

                this.progressValue = Math.round(
                    ((i + 1) / this.allTechs.length) * 100
                );
            }
        }
    }

    setMessageValue(val: any) {
        this.message = val;
        this.charCount = val.length;
    }
}
